import '../App.scss';
import {Link,useNavigate,useParams} from 'react-router-dom';

import React, { useEffect, useState,useContext } from 'react';
import axios from 'axios';
import {checkaccountnameavailability, getaccountdetails, getall, getmyprofile, getpendingitin, getpublicprofile, sethandle} from '../api/itin';
import Authservice from './Authservice';
import ItinView from './ItinView';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';
import ico_share from '../icons/share.png';
import SharerModal from './SharerModal';
import PublicItinView from './PublicItinView';
import AppContext from './globalcontext';
import BottomCTAI from './BottomCTAI';

function PublicProfile(props) {
  const globalContext = useContext(AppContext);

  const params = useParams();
  const id = props && props.id? props.id:params.id;
  const navigate = useNavigate();

  const [itins,SetItins] = useState([]);
  const [userdata,Setuserdata] = useState(null);
  const [Loading,SetLoading] = useState(true);
  const [NotFound,SetNotFound] = useState(false);
  const baseurl = "https://triptopper.com/";
  const [SharerUrl,SetSharerUrl] = useState(baseurl);
  const [SharerText,SetSharerText] = useState("Check this out!");
  const [SharermodalShow,SetSharermodalShow] = useState(false);
  const [HandlemodalShow,SetHandlemodalShow] = useState(false);
  var ViewClass = 'view'; 

  const handlesetDone =(handle)=>{
    Setuserdata({...userdata,handle:handle});
    SetHandlemodalShow(false);
    SetSharermodalShow(true);
  }

  useEffect(()=>{
    var headertoken = Authservice.getheadertoken();
    axios.defaults.headers.common['Authorization'] =    
          'Bearer '+ headertoken;
    //getprofiledata
    const url = getpublicprofile+id;
    axios.get(url).then(res=>{
      if (res.data.success) {
        Setuserdata(res.data.data.userdata);
        SetItins(res.data.data.itins);
        SetLoading(false);
      }else{
        SetNotFound(true);
        SetLoading(false);

        //navigate('/app/notfound')
      }
      
    
    }).catch(err=>{
    console.log(err);
    })
    
  },[])

  const handleShareProfileBtnClick = (url,text)=>{
      SetSharerUrl(url);
      SetSharerText(text);
      SetSharermodalShow(true)
    
    
  }

  const OpenSharerModal= (url,text)=>{
      SetSharerUrl(url);
      SetSharerText(text);
      SetSharermodalShow(true)
  }

  const getname = ()=>{
    const given_name = userdata && userdata.given_name?userdata.given_name:'';
    const family_name = userdata && userdata.family_name?userdata.family_name:'';
    return  given_name+' '+family_name;
  }

  if(NotFound && !Loading){
    return (
      <div className=''>
      <div className='container'>
        <div className='row' >
          <div className='col-sm-12 mt-4'>
            <br></br>
              <center>
                <p className='hero4'>[404]</p>
                <br></br>
                <p>The page you are looking does not exist!</p>
              </center>
          </div>
        </div>
        <div className='row'>
            <BottomCTAI></BottomCTAI>
            </div>
      </div>
    </div>
    )

  }

  else if(!NotFound && !Loading)
  {
    if(globalContext.currentuser){
      ViewClass = ''
    }
    return(
      <>
      <div className=''>

        <div className={' p-4 '+ViewClass}>
          
          <SharerModal SharerUrl={SharerUrl} text={SharerText} show={SharermodalShow}
            onHide={() => SetSharermodalShow(false)}/>
        
          <div className='row'>

            <div className='col-sm-12 col-md-8 offset-md-2 rounded p-1'>
              <p className='fs-6 fw-bold'>{'@'+userdata.handle}
              <button className='btn border-bold fw-bold float-end'  onClick={() => handleShareProfileBtnClick(baseurl+userdata.handle,"Check this out !")}> Share <img src={ico_share} className='i-sm'></img> </button>
              </p>
              <div className='row'>
                  <div className='col-sm-12 col-md-4 order-md-2'>
                  </div>
                  <div className='col-sm-12 col-md-12 text-center mt-4'>
                      <p className='hero3'>{getname()}</p>
                      <p>{userdata.bio}</p>
                  </div>
            
              </div>
            


              <div className='row mt-2 p-2'>
                <GetItinView OpenSharerModal={OpenSharerModal} itins={itins}></GetItinView>
              </div>
            
            </div>
            
          </div>
          
          <BottomCTAI></BottomCTAI>
        </div>
      </div>
      </>
    ) 

    
  }

  else
  {
    return(<div className='content-50'><br></br><center><p className='display-5 gray'>Loading....</p></center></div>)
  }
}


const GetItinView =(props)=>{
  const navigate = useNavigate();

  const [itins,SetItins] = useState(props.itins);
  if(itins && itins.length >0)
  {
    return(
      <PublicItinView ispublic="true" OpenSharerModal={props.OpenSharerModal} itins={props.itins}></PublicItinView>

      
    )
  }else{
    
  }
  
}




export default PublicProfile;
