import '../App.scss';
 
import React, { useEffect, useState } from 'react';
import CallToAction from './CallToAction';


import {Link} from 'react-router-dom';

import image1  from '../image1.png'
import image2 from '../image2.png'
import image3 from '../image3.png'
import img_travelstrip from '../TravelStrip.png';
import ico_traveller from '../icons/traveler.png';
import AnalyticsEventTrackerCreator from './AnalyticsEventTracker';
import ReactGA from "react-ga4";
import Faq from './Faq';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Leads from './Leads';
import PublicProfile from './PublicProfile';

import travelgif from '../theme/TT-GIF-1.gif';
import svg1 from '../svg1.svg'
import svg2 from '../svg2.svg'
import svg3 from '../svg3.svg'
import svg4 from '../svg4.svg'
import svg5 from '../svg5.svg'
import hero1 from '../hero1.png'

// ..
AOS.init();

//const TRACKING_ID = "G-9GSNK889ZV"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);
function Index() {

  useEffect(()=>{
    

  },[])

  const gaEventTracker = (label)=>{
    ReactGA.event('claim_name_index',{
      category: 'lan_page',
      action: 'click',
      label: label
    });
  }

  return (

    <div className='bg-white'>
        <div className="container mb-3  mt-5">
          <div className='d-sm-block d-md-none mt-4'>
                <br></br>
          </div>
          <div className='row align-items-center'>
          <div className='mt-3 d-none d-md-block'><br></br></div>

            <div className='col-md-5 offset-md-1 col-sm-12 text-md-start p-3 mt-2'>

              {/*<p className='hero1 mt-5'>Create interactive <span className=''>travel itineraries,</span></p>*/}
              <p className='hero2 mt-5 text-center text-md-start '>Create shareable travel itineraries seamlessly</p>

              {/*
             
              
              
              <p className='hero1 mt-5 text-center text-md-start '>Create beautiful travel itineraries <span className='underline-theme'>that converts.</span></p>
               */}
              {/*<p className='fs-3'>without complexity.</p>*/}
              <p className='fs-4 text-center text-md-start'>Share your travel experience <span className=''>with just one link.</span></p>

              
              <div className="mt-5"></div>
              <div className='col-sm-12'>
                <ClainYourHandle></ClainYourHandle>
              </div>
              <div className="mt-5 mt-md-0"></div>

            </div>

            <div className='col-md-5'>
              <div className='mt-5 d-none d-md-block'></div>

              <center>
               
                <img alt="" src={hero1} className="rounded shadow img-fluid d-none d-sm-block"></img>
                 {/*
                <div class="ratio ratio-16x9">
                  <iframe class="" src="https://www.youtube.com/embed/K6AOWV3pjFU?rel=0" allowfullscreen></iframe>
                </div>
                */}
              </center>
            </div>
          </div>
        </div>
        
        <div className="mt-sm-0 mt-md-5">
          <div className="d-sm-block d-none">
            <br></br>
          </div>
        </div>

      {/* scroll animation starts */}
     
      {/* scroll animation ends */}

      {/* <Leads/>  */}
      

      {/* Whats in for you! */}
        <div className='sticky-top pt-5 '>
          
          <div className='container'>
              <br></br>
              <div className='row'>
                  <div className='col-md-6 offset-md-1'>

                    <br></br>
                    <br></br>
                        <p className='hero3 text-center'>Create</p>
                        <center>
                        <p className='fs-5'>Easily create beautiful shareable itineraries faster.</p>
                        <p className='fs-5'> Add images, tag places directly from Google.</p>
                        <p className='fs-5'>Generate a travel page for yourself.</p>
                        <p className='fs-5 underline'>[your name].triptopper.com</p>
                        </center>
                   
                  </div>
                  <div className='col-md-3 offset-md-1 col-sm-3'>
                    <br></br>
                    <div className=' p-3'>
                      <img alt='' src={image1} className='img-fluid'></img>
                      {/*<CallToAction/>*/}

                    </div>

                  </div>
              </div>
          

          </div>
        </div>


        <div className=' sticky-top pt-5'>
          <div className='container bg-white content-40 sticky-top'>
              <br></br>
              <div className='row align-items-center'>
              <div className='col-md-6 offset-md-1 order-md-2'>

                <br></br>
                <br></br>

               <p className='hero3 text-center'>Share</p>
                  <center>
                  <p className='fs-5'> Get your personalised @handle and use it as your link in bio.</p>
                  <p className='fs-5'> Freedom to share your travel experience across web. </p>
                  <p className='fs-5'> One link to broadcast your travel experience.</p>
                  </center>
                  <br></br>
                  <p></p>
        


              </div>
              <div className='col-md-3 offset-md-1 order-md-1'>
                    <br></br>
                    <div className='p-3'>
                      <img alt='' src={image2} className='img-fluid'></img>
                    {/*<CallToAction/>*/}

                    </div>

                  </div>
            
              </div>
          </div>
        </div>


        <div className=' sticky-top container mt-5 pb-5 pt-5 bg-gray'>
              <br></br>
              <div className='row align-items-center'>
                  <div className='col-md-9'>

                    <br></br>
                    
                    <div className='row align-items-center'>
                      <div className='col-md-8 offset-md-2 mt-5'><p className='hero3 text-center'>Claim your name before it's gone!</p>
                      <br></br>
                      <ClainYourHandle></ClainYourHandle>
                      
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <br></br>
                    <div className=' p-3'>
                      <img alt='' src={image3} className='img-fluid'></img>
                    {/*<CallToAction/>*/}

                  </div>
                 

              </div>
          </div>
          



      
      

    
        </div>
      {/** TOP Features */}
        
      {/** FAQ */}
      <div className='bg-white'>

        <div  className='bg-white sticky-top container mt-5 pb-5 pt-5'>
          <br></br>
          <TopFeatures></TopFeatures>

          <div className='bg-gray border row align-items-center'>
              <br></br>
              <div className='col-md-10 offset-md-1 mt-10'>                        
                <Faq></Faq>
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3">
                    <ClainYourHandle></ClainYourHandle>
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

    </div>
     
  );
}

const ClainYourHandle = (props)=>{
  return(
    <div>
    <div className='mb-4 border-bold p-3 rounded-pill shadow d-flex align-items-baseline justify-content-center'>
    <span>
      <input className='borderless-input' name="test" id="test" type="text" placeholder="your name"/>
    </span>
    <label className='fw-bold' htmlFor="test">.triptopper.com</label>
    
    </div>
    <Link className='btn btn-primary button-63' to="/app/Signin"><h4> <span className='m-2 fw-bold'>Get Started</span></h4></Link>
    </div>
  )
}

const TopFeatures = ()=>{
  return(
    <div className='bg-white'>
      <p className='hero2 text-center divider'>Top features</p>
      <div className='container p-5 pb-5'>
            
        <div className='row mt-10' >
            <div className='col-md-7 offset-md-1'>
              <br></br>
              <br></br>
              <div className=''>
                <p className='hero4'>Add places directly from Google</p>
                <p className='fs-5'>Easy to use tool to create beautiful shareable itineraries faster.</p>
                <p className='fs-5'> Add pictures, tag places directly from Google.</p>
                <br></br>
                <p></p>
              </div>
            </div>
            <div className='col-md-3 col-sm-3'>
              <br></br>
              <div className=''>
                <img alt='' src={svg4} className='img-fluid'></img>
              </div>
            </div>
        </div>
        <br></br>
        <br></br>
        

        <div className='row mt-10'>
                <div className='col-md-7 order-md-2'>
                  <br></br>
                  <br></br>
                  <div className=''>
                    <p className='hero4'>Add images to your itinerary</p>
                    <p className='fs-5'>An image added to an itinerary,  That's a snapshot of the journey's promise.</p>
                  </div>
                </div>
                <div className='col-md-3 offset-md-1 col-sm-3 order-md-1'>
                  <br></br>
                  <div className=''>
                    <img alt='' src={svg3} className='img-fluid'></img>
                  </div>
                </div>
        </div>

        <br></br>
        <br></br>

        <div className='row mt-10'>
          <div className='col-md-7 offset-md-1'>
              <br></br>
              <br></br>
              <div className=''>
                <p className='hero4'>Download your itinerary as PDF doc</p>
                <p className='fs-5'>From Itinerary to PDF: Share the Adventure Ahead.</p>
              </div>
          </div>
          <div className='col-md-3 col-sm-3'>
            <br></br>
            <div className=''>
              <img alt='' src={svg1} className='img-fluid'></img>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>

        {/* <div className='row mt-10'>
          <div className='col-md-7  order-md-2'>
            <br></br>
            <br></br>
            <div className=''>
              <p className='hero4'>Inbox</p>
              <p className='fs-5'>Effortless Engagement: Customer Leads, Direct to Your Inbox</p>
              <br></br>
              <p></p>
            </div>
            </div>
            <div className='col-md-3 offset-md-1  order-md-1'>
            <br></br>
            <div className=''>
              <img alt='' src={svg5} className='img-fluid'></img>
            </div>
          </div>
        </div> */}
        <br></br>
        <p id='faq' ></p>

        <br></br>
      </div>
    </div>
  )
}



export default Index;
