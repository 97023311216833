import '../../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef} from 'react';
import GoogleSearchAutocomplete from '../GoogleSearchAutocomplete';
import ViewActivity from '../User/ViewActivity';
import ico_add from '../../icons/add.png';
import ico_ty_1 from '../../icons/restaurant.png';
import ico_ty_2 from '../../icons/pointofinterest.png';



function ViewActivityBlock(props) {
    const [showGoogleSearchBar,SetshowGoogleSearchBar] = useState(false);
    
    const [gtype,Setgtype] = useState([]);
    const [gcountry,Setgcountry] = useState("");
    const [gplaceholder,Setgplaceholder] = useState("Search");
    const [timeofday,Settimeofday] = useState(props.timeofday);
    const [activitytype,Setactivitytype] = useState(null);

    const cancelGoogleSearch = ()=>{
        SetshowGoogleSearchBar(false);
    }
    
    const  addEatbak =(selectedcity)=>{
        if(selectedcity!= null && selectedcity.geometry!=null)
        {
         
          /*
          [IMPORTANT: Get the city name, state etc  from google reponse using below code]
          [Test Case:] This will break if new delhi is selected
          for (var i = 0; i < place.address_components.length; i++) {
            for (var j = 0; j < place.address_components[i].types.length; j++) {                 
                  if (place.address_components[i].types[j] == "locality") {$("#city").val(place.address_components[i].long_name);
            }
            */
          const city = selectedcity.address_components[0]['short_name'];
          const state = selectedcity.address_components[2]['short_name'];
          const country = selectedcity.address_components[3]['short_name'];
          const placeid = selectedcity.place_id;
          const fulladdress = selectedcity.formatted_address;
          const locdata = selectedcity.geometry.location;
          const latitude = locdata.lat();
          const longitude = locdata.lng();
          //var _places = placestoeat.data;
          //_places.push({add:fulladdress,id:12});
          //Setplacestoeat({...placestoeat,data:_places});

          var _loc =  { 
            "type": "Point",
            "coordinates": [longitude,latitude]
          };
          var _location = {
            loc:_loc,city:city,state:state,
            country:country,gid:placeid
            ,fulladdress:fulladdress};
        
        
            //Setitin({...itin, location:_location});
          
    
        }
    }

    const showgoogleSearch=(gtype,gcountry,activitytype)=>{

        if(showGoogleSearchBar){
            SetshowGoogleSearchBar(false);
        }
        else{
            var gtypearray = gtype.split('|');
            Setgtype(gtypearray);
            Setgcountry(gcountry);
            if(activitytype){if(activitytype === 1){ Setgplaceholder("Search for places to eat")}
            else if(activitytype===2){Setgplaceholder("Search for places to visit or activities")}}
            Setactivitytype(activitytype)
            SetshowGoogleSearchBar(true);
        }
    }
    
    const addActivity = (item)=>{
       const result = props.addActivity(item,timeofday,activitytype);
       SetshowGoogleSearchBar(false)
    }
    
    const deleteActivity = (id,td,ty)=>{
        props.deleteActivity(id,td,ty);
      
       
    }
        if(props.day.activities)
        {
        return (
            <div className={'pb-1 '+ props.cname}>
                <div className='p-2'> 
                    <img src={props.icon} className='i-md'></img>
                    <span className='fs-6 ps-2 fw-bold color-black'>{props.label}</span>

                </div>
                <div className='col-sm-12 col-md-10 offset-md-1'>
                    {
                        props.day.activities?.map((item,index)=>{
                            return (<ViewActivity key={item._id.toString()}  index={index} item={item} ></ViewActivity>);
                        })
                        
                    }
                </div>
                
            </div>
        )
        }
    
}
        
      


export default ViewActivityBlock;
