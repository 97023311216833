import '../../App.scss';
import axios from 'axios';
import ViewNote from '../User/ViewNote';
import React,{useState,useEffect,useRef} from 'react';
import {MdOutlineRestaurant} from 'react-icons/md';
import ico_delete from '../../icons/delete.png';

import {GetGoogleTypeIcons,GetIconPrediction} from '../GetGoogleTypeIcons';


function ViewActivity(props) {
    const [activity,Setactivity] = useState(props.item);
    switch(props.item.label){

        case 'heading':
            return (
                <div className='row ms-2 mb-2'>
                    <div className='col-sm-11'>
                        <div className='p-2 rounded-3'> 
                        <h5 className='fw-bold fs-4'>{activity.heading}</h5>
                        <p className=''><span className='small'>{activity.note}</span></p>
                        {/* 
                        <a className=' nodecor fw-bold float-end' target='_blank' href={"https://www.google.com/maps/search/?api=1&query="+activity.fulladdress+"&query_place_id="+activity.gid}>
                                Open</a>
                                */}
                        </div>
                    </div>
                </div>
            )
        case 'place':

                return (
                    <div className='row ms-2 mb-2'>
                        <div className='col-sm-11'>
                            <div className='p-2 bg-light rounded-3 selectable-div-light' onClick={()=>{window.open("https://www.google.com/maps/search/?api=1&query="+activity.activity.fulladdress+"&query_place_id="+activity.activity.gid)}}> 
                                <GetIconPrediction name={activity.activity.name} ></GetIconPrediction>
                                <GetGoogleTypeIcons item={activity.activity.placetypes} name={activity.activity.name} ></GetGoogleTypeIcons>
                                <a className='nodecor fs-6 fw-bold btn-sm color-black' target='_blank' href={"https://www.google.com/maps/search/?api=1&query="+activity.activity.fulladdress+"&query_place_id="+activity.activity.gid}>&nbsp;{activity.activity.name}</a>
                                <p className='mt-2'><span className='small'>{activity.note}</span></p>
                                {/* 
                                <a className=' nodecor fw-bold float-end' target='_blank' href={"https://www.google.com/maps/search/?api=1&query="+activity.fulladdress+"&query_place_id="+activity.gid}>
                                        Open</a>
                                        */}
                            </div>
                        </div>
                    </div>
                        
                )

        case 'image':

        return (
            <div className='row ms-2 mb-2'>
                <div className='col-sm-11'>
                    <div className='p-2 rounded-3'> 
                    <div className=''>
                        <div className='itinImg-container shadow-sm'>
                            <img className='itinImg' src={activity.img}/>
                        </div>
                            {activity.imgBy && 
                            <div className='unsplash-text'>Photo by <a className='unsplash-link' href={activity.imgBy.unsplash} target="_blank">{activity.imgBy.name}</a> on <a className='unsplash-link' href="https://unsplash.com/?utm_source=your_app_name&utm_medium=referral" target="_blank">Unsplash</a>
                        </div>}
                        <p className=''><span className='small'>{activity.note}</span></p>
                    </div>
                    
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewActivity;