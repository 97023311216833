import React,{useEffect, useState} from 'react';
import food from '../icons/restaurant.png';
import i_restaurant from '../icons/restaurant.png'
import i_point_of_interest from '../icons/pointofinterest.png';
import i_airport from '../icons/airplane.png';
import i_beach from '../icons/beach.png';
import i_place from '../icons/place.png';



export const GetGoogleTypeIcons = (props)=>{
    const [types,Settypes] = useState(props.item);
    var gotfood = false;
    var gotairport= false;
    var gotpointofinterest = false;
    var gotplace = false;
    return (
        <>
            {
                
                types.map((item,index)=>{
                    
                    switch(item)
                    {
                        case 'restaurant':
                            if(!gotfood){
                                gotfood = true;
                                return <img key={index.toString()} src={i_restaurant} className='i-sm'></img>;
                                } else {break;}
                        case 'cafe':
                            if(!gotfood){
                                gotfood= true;
                            return <img key={index.toString()} src={i_restaurant} className='i-sm'></img>;
                            
                            }else {break;}

                        case 'point_of_interest':return <img key={index.toString()}  src={i_point_of_interest} className='i-sm'></img>;
                        
                        case 'food': 
                            if(!gotfood){
                                gotfood= true;
                                return <img key={index.toString()} src={i_restaurant} className='i-sm'></img>;
                            } else {break;}
                        case 'airport': { gotairport = true;
                            return <img key={index.toString()}  src={i_airport} className='i-sm'></img>;}
                        default: if(!gotairport && !gotairport && !gotpointofinterest && !gotplace){
                            gotplace = true;
                            return <img key={index.toString()}  src={i_place} className='i-sm'></img>
                        }else{break;}
                    }
                })
            }
        </>
    )
}

export const GetIconPrediction = (props)=>{
    const [name,Setname] = useState(props.name);
    if(name.includes('beach')){
        return(
            <img src={i_beach} className='i-sm'></img>
        )
    }
    else if(name.includes('Beach'))
    {
        return(
            <img src={i_beach} className='i-sm'></img>
        )
    }
}


