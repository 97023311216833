import '../App.scss';
import {Link,useNavigate} from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {getall, getpendingitin} from '../api/itin';
import Authservice from './Authservice';
import Loading from './Loading';
import PendingItinNotification from './Creater/PendingItinNotification';
import ItinView from './ItinView';

function Home() {

  const navigate = useNavigate();
  const [itins,SetItins] = useState([]);
  const [Loading,SetLoading] = useState(true);
  useEffect(()=>{
    var headertoken = Authservice.getheadertoken();
    axios.defaults.headers.common['Authorization'] =    
          'Bearer '+ headertoken;
    axios.get(getall).then(res=>{
      if (res.data.success && res.data.itins) {
        console.log(res.data)
        SetItins(res.data.itins);
        SetLoading(false);
      }
      else{console.log("No pending itins found");}
  }).catch(err=>{
    console.log(err);
  })
},[])

function onNextClicked()
{
  console.log("Fresh Itinerary button clicked.")
  var tempitin  = {title:"",_id:null,location:{fulladdress:""}}
  navigate('/trip-title',{state:{itin:tempitin}});

}
if(!Loading)
{
    if(itins  && itins.length >0)
    {  return (
        <div className='content'>
          <div className='container'>
            <div className='row'>
            <center>
              <div className='border-bottom p-2 mt-4 mb-2'>
              <h1 className=''><span className='hero3'>Travellers Home</span></h1>
              <p><span className='small ms-4'>[All itineraries live here]</span></p>
              </div>
            </center>

            <ItinView itins={itins}></ItinView>
              
             </div>
            
          
          </div>
        </div>
      )
    }
    else
    {
      return(
        <div className='content'>
          <div className='container'>
            <div className='row'>
                <center>
                  <br></br>
                  {/* 
                    <PendingItinNotification itins={itins}></PendingItinNotification>
                  */}
                
                  <br></br>
                  <br></br>
                  <p className='display-5'>We all love to tell our travel stories.</p>
                  <p> Share your engaging journey with fellow travellers.</p>
                  <br></br>
                  <Link to='/Create-new-itin' className='col-sm-4 btn button-63 p-4 rounded-pill'>
                    <h4>Create Itinerary</h4>
                  </Link>
                </center>
              

            </div>
          </div>
        </div>
      )
    }

  
}

else
{
  return(  <div className='content-50'><br></br><center><p className='display-5 gray'>Loading....</p></center></div>
  )
}
}
export default Home;
