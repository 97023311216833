import { useContext, useState } from "react"
import { setuserdetails, signinwithemail, verifyemail } from "../api/itin";
import axios from "axios";
import { useNavigate } from "react-router";
import AppContext from "./globalcontext";
import Authservice from "./Authservice";
import UsertypeSelector from "./UsertypeSelector";
import hero1 from '../hero1.png'
import { Link } from "react-router-dom";
import logo from '../TT3.png';

const EmailLogin = () => {
    const [showScreen, setShowScreen] = useState('email');
    const [useremail, setUseremail] = useState();
    const navigate = useNavigate();
    const globalContext = useContext(AppContext);
    globalContext.setcurrentuser(Authservice.getCurrentUser());

    const EmailDetails = () => { 
        const [email, setEmail] = useState();
        const [showEmailError, setShowEmailError] = useState(false);

        const onEmailChangeText = (text) => {
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
            if (reg.test(text) === false) {
                setShowEmailError(true);
                setEmail(text)
            }
            else {
                setEmail(text)
                setShowEmailError(false);
            }
        };

        const onEmailSubmit = () => {
            if(email && !showEmailError){
                axios.post(signinwithemail, {email: email})
                .then(res => {
                    if(res.data.success){
                        setShowEmailError(false);
                        setUseremail(email);
                        res.data.newuser ? setShowScreen('userdetail') : setShowScreen('otp');
                    } else{
                        setShowEmailError(true);
                    }
                })
                .catch(e => console.log("signinwithemail", e));
            } else{
                setShowEmailError(true);
            }
        };

        return (
            <div className='d-flex flex-column col-md-5 col-10'>
              <p className='hero3 text-center'>Login or sign up</p>
              <br></br>
              <div className=''>
              <form onSubmit={(e) => {e.preventDefault(); onEmailSubmit();}}>
                <div class="mb-4 form-group">
                    <label htmlFor="exampleInputEmail1" className="text-muted m-1 small">Enter email</label>
                    <input type="email" class="form-control" id="inputemail1" aria-describedby="emailHelp" placeholder="name@example.com" 
                    value={email} onChange={(e) => onEmailChangeText(e.target.value)}/>
                    {showEmailError && <small class="form-text text-danger">Must be a valid email</small>}
                </div> 
                <button type="button" class="mb-4 mt-4 w-100 btn btn-primary fw-bold" onClick={onEmailSubmit}>Continue with email</button> 
                </form>
              </div>
            </div>
    )}

    const UserDetails = () => {
        const [firstname, setFirstname] = useState();
        const [lastname, setLastName] = useState();
        const [usertype, setUsertype] = useState();

        const onSubmit = () => {
            if(firstname && lastname && usertype){
                axios.post(setuserdetails, {
                    email: useremail,
                    firstname: firstname,
                    lastname: lastname,
                    usertype: usertype
                })
                .then(res => {
                if(res.data.success){
                    setShowScreen('otp');
                }
                })
                .catch(e => console.log("setuserdetails", e));
            }
        }

        return (
            <div className='d-flex flex-column col-md-5 col-10'>
              <p className='hero3 text-center mb-0'>Welcome</p>
              <p className="text-muted text-center small">You're new here! Tell us a little about yourself...</p>
              <div className=''>
              <form>
                <div className="mb-2 form-group">
                    <label htmlFor="exampleInputEmail1" className="text-muted m-1 small">First name</label>
                    <input type="email" className="form-control" id="inputemail1" aria-describedby="emailHelp" placeholder="Enter first name" 
                    value={firstname} onChange={(e) => setFirstname(e.target.value)}/>
                </div>

                <div className="mb-2 form-group">
                    <label htmlFor="exampleInputEmail1" className="text-muted m-1 small">Last name</label>
                    <input type="email" className="form-control" id="inputemail1" aria-describedby="emailHelp" placeholder="Enter last name" 
                    value={lastname} onChange={(e) => setLastName(e.target.value)}/>
                </div>
                
                <UsertypeSelector usertype={usertype} setUsertype={setUsertype} />

                <button type="button" className="mb-4 mt-4 w-100 btn btn-primary" onClick={onSubmit} 
                disabled={!(firstname && lastname && usertype)}>Get Started</button>
                </form>
              </div>
            </div>
        )}

    const OtpComponent = () => {
        const [otp, setOtp] = useState();
        const [showOtpError, setShowOtpError] = useState(false);

        const onOtpChangeText = (text) => {
            setShowOtpError(false);
            setOtp(text);
        };

        const onOtpVerify = () => {
            if(otp){
                axios.post(verifyemail, {email: useremail, otp: otp})
                .then(res => {
                    if(res.data.success){
                        Authservice.setfacebooktoken(res.data.token);
                        globalContext.setcurrentuser(Authservice.getCurrentUser());
                        var {usertype} = Authservice.getCurrentUser();
                        usertype ? navigate('/') : navigate('/app/knowyou');
                    } else{
                        setShowOtpError(true);
                    }
                })
                .catch(e => console.log("verifyemail", e));axios.post(verifyemail, {email: useremail, otp: otp})
                .then(res => {
                    if(res.data.success){
                        Authservice.setfacebooktoken(res.data.token);
                        globalContext.setcurrentuser(Authservice.getCurrentUser());
                        var {usertype} = Authservice.getCurrentUser();
                        usertype ? navigate('/') : navigate('/app/knowyou');
                    } else{
                        setShowOtpError(true);
                    }
                })
                .catch(e => console.log("verifyemail", e));
            } else{
                setShowOtpError(true);
            }
            
        }

        return (
            <div className='d-flex flex-column col-md-5 col-10'>
                  <p className='hero3 text-center'>Login or sign up</p>
                  <p className="text-muted text-center small">We just sent you a temporary code via email, please check your inbox!</p>
                  <br></br>
                  <div className=''>
                  <form onSubmit={(e) => {e.preventDefault(); onOtpVerify();}}>
                   <div class="mb-2 form-group">
                        <label htmlFor="exampleInputPassword1" className="text-muted m-1">Verification code</label>
                        <input type="number" class="form-control" id="inputotp" 
                        value={otp} onChange={(e) => onOtpChangeText(e.target.value)} maxLength={1}/>
                        {showOtpError && <small class="form-text text-danger">Invalid code</small>}
                    </div>
    
                    <button type="button" class="mb-4 mt-4 w-100 btn btn-primary fw-bold" onClick={onOtpVerify}>Continue</button> 
                    </form>
                  </div> 
                </div>
        )
    }    

    const Screen = () => {
        switch(showScreen){
            case 'email': return <EmailDetails/> 
            case 'userdetail': return <UserDetails/> 
            case 'otp': return <OtpComponent/> 
       }
    }

    return (
        <>
        <div className='bg-light position-absolute top-0 bottom-0 start-0 end-0'>
            <div className="d-flex h-100">
                <div className='col-6 d-none d-md-block'>
                    <Link to="/" className='position-absolute mt-4 ms-5 start-0 top-0'><img src={logo} className='App-logo'></img></Link>
                    <img alt="" src={hero1} className="float-start mh-100 right-rounded"></img>
                </div>
                <div className='h-100 bg-light col-md-6 col-12 d-flex justify-content-center align-items-center'>
                    <Screen />
                </div>
            </div>
        </div>
        </>      
    )
};

export default EmailLogin;