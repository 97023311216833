import '../App.scss';
import {Link,useNavigate} from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {checkaccountnameavailability, getaccountdetails, getall, getmyprofile, getpendingitin, sethandle,saveprofilebio} from '../api/itin';
import Authservice from './Authservice';
import ItinView from './ItinView';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';
import ico_share from '../icons/share.png';
import ico_edit from '../icons/edit.png';
import ico_preview from '../icons/eye.png';
import PreviewModal from './Creater/PreviewModal';
import SharerModal from './SharerModal';
import CopyItinModal from './Creater/CopyItinModal';

function Myprofile() {
  
  const [itins,SetItins] = useState([]);
  const [userdata,Setuserdata] = useState(null);
  const [Loading,SetLoading] = useState(true);
  const  baseurl = "https://triptopper.com/";
  const [SharerUrl,SetSharerUrl] = useState(baseurl);
  const [SharerText,SetSharerText] = useState("Check this out!");
  const [SharermodalShow,SetSharermodalShow] = useState(false);
  const [HandlemodalShow,SetHandlemodalShow] = useState(false);
  const [ShoweditBio,SetShoweditBio] = useState(false);
  const [OpenPreviewModal,SetOpenPreviewModal] = useState(false);
  const [OpenCopyModal,SetOpenCopyModal] = useState(false);
  const [copyItin,SetCopyItin] = useState(null);


  const handlesetDone =(handle)=>{
    const newhandle = handle.toLowerCase();
    Setuserdata({...userdata,handle:newhandle});
    SetHandlemodalShow(false); 
  }

  useEffect(()=>{
    
    var headertoken = Authservice.getheadertoken();
    axios.defaults.headers.common['Authorization'] =    
          'Bearer '+ headertoken;
    //getprofiledata
    axios.get(getaccountdetails).then(res=>{
      if (res.data.success) {
        Setuserdata(res.data.userdata);
        if(res.data.userdata && res.data.userdata.handle){
        SetSharerUrl(baseurl+res.data.userdata.handle);
        }
        
      }
    }).then(()=>{
      axios.get(getall).then(res=>{
        if (res.data.success && res.data.itins) {
          SetItins(res.data.itins);
          SetLoading(false);
          
        }
    })
    }).catch(err=>{
    })
    
  },[])

  const updateItins = (i) => {
      SetItins([...itins, i]);
  }

  const handleShareProfileBtnClick = (url,text)=>{
    if(userdata.handle && userdata.handle!= ''){
      SetSharerUrl(url);
      SetSharerText(text);
      SetSharermodalShow(true)
    }
    else
    {
      SetHandlemodalShow(true);
    }
    
  }

  const handlePreviewBtnClick = ()=>{
    if(userdata.handle && userdata.handle!= ''){
      SetOpenPreviewModal(true);
      //window.open('https://www.triptopper.com/'+userdata.handle, '_blank', 'noreferrer')
    }
    else
    {
      SetHandlemodalShow(true);
    }
  }
  const OpenSharerModal= (url,text)=>{
      SetSharerUrl(url);
      SetSharerText(text);
      SetSharermodalShow(true)
  }

  const OpenCopyModalClick= (itin)=>{
    SetOpenCopyModal(true)
    SetCopyItin(itin);
}

  const getname = ()=>{
    const given_name = userdata && userdata.given_name?userdata.given_name:'';
    const family_name = userdata && userdata.family_name?userdata.family_name:'';
    return  given_name+' '+family_name;
  }

  const HandleView =()=>{
    //<button onClick={()=>SetHandlemodalShow(true)} className='btn border fw-bold me-3 btn-sm'><img src={ico_edit} className='i-sm'></img></button>
    if(userdata.handle && userdata.handle.length>3){
      return(<p className='fs-6'>{'@'+userdata.handle}</p>);
    }
    else{
      return(<button className='btn border fw-bold' onClick={()=>SetHandlemodalShow(true)}>Set @handle</button>)
    }
  }



  const BioView =(props)=>{

    const [bio,Setbio]= useState(props.bio);
    const [WordsLeftforBio,SetWordsLeftforBio] = useState(150);

    const handleEditBio=()=>{
      Setbio(userdata.bio);
      SetShoweditBio(true);
    }
  
    const handleSaveBioBtn=()=>{
      console.log("Lets save bio");
        var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] = 'Bearer '+ headertoken;
        axios.post(saveprofilebio,{bio:bio})
          .then((response) => {
              const res = response.data.success;
              if(res){
                Setuserdata({...userdata,bio:bio});
                SetShoweditBio(false);
              }
            }).catch(err => {
            console.error(err);
          });
      
    
    }

    const handleBioChange =(e)=>{
      SetWordsLeftforBio(150-bio.length);
      if(e.target.value.length<=150)
      {
        Setbio(e.target.value);
      }
    }

    if(ShoweditBio){
     return(
        <div className='bg-light p-3'>
          <p className='fs-5 fw-bold'>Bio 
            <button onClick={()=>SetShoweditBio(false)} className='btn border border float-end'>X</button>
          </p>
          <textarea className='form-control' value={bio} onChange={handleBioChange}></textarea>
          <p className='text-center'>{WordsLeftforBio}</p>
          <div className='text-center mt-3'>
            <button onClick={()=>handleSaveBioBtn()} className='btn btn-primary btn-sm fs-6 fw-bold' >Save</button>
          </div>
        </div>
     )

    }
    else{
      if(userdata.bio && userdata.bio != ''){
        return(<p><button onClick={()=>handleEditBio(true)} className='btn btn-sm fw-bold border me-3'><img src={ico_edit} className='i-sm'></img></button>{userdata.bio}</p>)
      }
      else{
        return(<button className='btn border btn-sm fw-bold' onClick={()=>handleEditBio(true)}>+ Add bio</button>)
      }
    }

  }


  

  if(!Loading)
  {
    return(
      <div className='container mt-4 mb-5'>
        <SharerModal SharerUrl={SharerUrl} text={SharerText} show={SharermodalShow}
          onHide={() => SetSharermodalShow(false)}/>
        <GetSetHandleModal currentvalue={userdata.handle} finish={handlesetDone} show={HandlemodalShow}
          onHide={() => SetHandlemodalShow(false)}/>
        <PreviewModal type="PROF" handle={userdata.handle} show={OpenPreviewModal} onHide={() => SetOpenPreviewModal(false)}/>
        <CopyItinModal itin={copyItin} updateItins={updateItins} show={OpenCopyModal} onHide={() => SetOpenCopyModal(false)}/>

        <div className='row'>
          
          <div className='col-sm-12 col-md-10 offset-md-1'>
            <div className='p-1'>
              <div className='row'>
              <div className='col-6'>
              <HandleView></HandleView>

              </div>
            
              <div className='col-6'>
                <button className='shadow-sm btn border-bold float-end '  onClick={() => handleShareProfileBtnClick(baseurl+userdata.handle,"Check out my profile !")}>
                      <span className='d-none d-md-inline fw-bold fs-6'>Share </span>
                      <img src={ico_share} className='i-sm ms-2'></img>
                    </button>
              <button className='shadow-sm btn border-bold float-end me-2' onClick={handlePreviewBtnClick} target="_blank" >
              <span className='d-none d-md-inline fw-bold fs-6'>Preview </span>

                  <img className='i-sm' src={ico_preview}></img>
                </button>
              </div>
         
              </div>
              <div className='mt-4 text-center'>
                <div className='row '>
                  <div className='col-sm-12'>
                    <p className='hero3'>{getname()}</p>
                    <BioView bio={userdata.bio?userdata.bio:''}></BioView>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
           
          <GetItinView OpenSharerModal={OpenSharerModal} OpenCopyModalClick={OpenCopyModalClick} itins={itins}></GetItinView>
        </div>
        </div>
        </div>
        <div className='mt-5'><br></br><br></br></div>
      </div>
        

    ) 

    
  }
  else{
    return(<div className='content-50'><br></br><center><p className='display-5 gray'>Loading....</p></center></div>)
  }

}


const GetItinView =(props)=>{
  const navigate = useNavigate();

  const [itins,SetItins] = useState(props.itins);
  if(itins && itins.length >0)
  {
    return(
      <div className='mt-5'>
        <p className='mb-4 h2 fw-bold ms-0'>
          My Itineraries
          <Link className='ms-4 float-end btn border-bold rounded-pill fw-bold' to="/app/Create-new-itin"> + New itinerary</Link>
        </p>
        <hr></hr>
        <div className='row'>
        <ItinView OpenSharerModal={props.OpenSharerModal} OpenCopyModalClick={props.OpenCopyModalClick} itins={props.itins}></ItinView>

        </div>
      </div>

      
    )
  }else{
    return(
        
     
        <div className='col-sm-12'>
            <center>
              <br></br>
              {/* 
                <PendingItinNotification itins={itins}></PendingItinNotification>
              */}
            
              <br></br>
              <br></br>
              <p className='fs-4'>We all love to tell our travel stories</p>
              <br></br>
              <br></br>
              <Link to='/app/Create-new-itin' className='col-sm-4 btn border p-4 rounded-pill'>
                <h4>Get Started</h4>
              </Link>
            </center>
          

        </div>
   
    )
  }
  
}

const GetSetHandleModal = (props)=>{

  const [handle,Sethandle] = useState(props.currentvalue?props.currentvalue:'');
  const [available,SetAvailable]= useState(props.currentvalue?'Available':'Please do not use any special characters or spaces');
  const [availablecolor,SetAvailablecolor] = useState('gray');
  
  const [isvalidhandle,Setisvalidhandle] = useState(false);


  const userHandleOnChange =(e)=>{
    ///[^\w\s/\s/g]/gi

    var name = e.target.value.replace(/[^A-Z0-9]/gi, "");
    if(name.length<=20)
    {
      Sethandle(name);
    }
    if(name.length < 4 ){
      Setisvalidhandle(false);
      SetAvailable('User handle must be atleast 4 character long.')
      SetAvailablecolor('gray') 
    }else{
      SetAvailable('')
    }
  }
      
  function handleCheckAvailability()
  {
    if(handle ){
      if(handle.length >= 4){
        var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        axios.post(checkaccountnameavailability,{handle:handle})
          .then((response) => {
              const res = response.data.success;
              if(res){
                if(res == 'ERR'){
                  SetAvailable('Unable to check, please try again!')
                  SetAvailablecolor('red')
                }else{
                  Setisvalidhandle(true);
                SetAvailable('Available')
                SetAvailablecolor('green')
                }
              }else{
                SetAvailable('Unavailable')
                SetAvailablecolor('red')

              }
            }).catch(err => {
            console.error(err);
          });
      }
      else{
        SetAvailable('User handle must be atleast 4 character long!')
        SetAvailablecolor('orange') }
    }else{
      SetAvailable('is this a joke?')
      SetAvailablecolor('gray')
    }
    
  }

  function savehandle()
  {
    axios.post(sethandle,{handle:handle})
          .then((response) => {
              const res = response.data.success;
              if(res){
                Setisvalidhandle(false);
                props.finish(handle);
              }
            }).catch(err => {
          });
  }

  return(
    <Modal
    {...props}
    keyboard={false}
    backdrop="static"
    >
    <Modal.Header closeButton>
      <center>
      <p className='hero4 mt-3'>Claim your handle</p>
      </center>

    </Modal.Header>
    <Modal.Body className="show-grid p-4">
        <div>
          <center>
          <p name='userhandlestatus' style={{color: availablecolor}} className='small'>{available}</p>

          <div className='border pt-4 pb-4 p-1 rounded-pill'>
          <label className='p fw-bold' htmlFor="box">triptopper.com/</label>
          <span>
            <input className='borderless-input-sm' name="box" id="box" type="text" 
            placeholder="Claim your name" value={handle} 
            onChange={userHandleOnChange}/>

          </span>
          </div>

          <div className='mb-4'>
          <button onClick={handleCheckAvailability} className='btn btn-link' > Check availability</button>

          </div>

          <Button style={{ visibility: isvalidhandle ? "visible" : "hidden" }}
          onClick={()=>savehandle()} 
          className='btn btn-primary'><h4> <span className='m-2 fw-bold'>Save</span></h4></Button>
          </center>
          </div>
    
        <br></br>
        <br></br>
    
      
      
    
    
    </Modal.Body>
  
    </Modal>
  )
}


export default Myprofile;
