import '../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';


function Faq(props) {
      
        return (
            <div className='mb-5 mt-4'>
              <div className='container'>
                <div className='row' >
                  <div className='col-sm-12 col-md-10 offset-md-1'>
                        <center>
                          <p className='hero3 mt-2 mb-2'>Frequently asked questions (FAQ)</p>

                        </center>

                        <div className="accordion " id="FaqAccordian">
                          
                          <div className="accordion-item border-bold mb-4">
                            <h2 className="accordion-header" id="panelsStayOpen-heading1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse1" aria-expanded="false" aria-controls="panelsStayOpen-collapse1">
                                Who should use triptopper?
                              </button>
                            </h2>
                            <div data-bs-parent="#FaqAccordian" id="panelsStayOpen-collapse1"  className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading1">
                              <div className="accordion-body">
                                <strong>If you are a traveler/traveller, travel influencer, travel advisor or someone who want to share his/her travel journey </strong> you must use tiptopper to document the exact details of your experience.
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item border-bold mb-4">
                            <h2 className="accordion-header" id="panelsStayOpen-heading2">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse2" aria-expanded="false" aria-controls="panelsStayOpen-collapse2">
                                Can I use triptopper as link in bio tool?
                              </button>
                            </h2>
                            <div data-bs-parent="#FaqAccordian" id="panelsStayOpen-collapse2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading2">
                              <div className="accordion-body">
                                <strong>Yes you can.</strong> You can set your handle like <strong>[your name].triptopper.com</strong> and it will act as a public link for all your itineraries.
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item border-bold mb-4">
                            <h2 className="accordion-header" id="panelsStayOpen-heading3">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse3" aria-expanded="false" aria-controls="panelsStayOpen-collapse3">
                                Is triptopper only suitable to travel influencers?
                              </button>
                            </h2>
                            <div data-bs-parent="#FaqAccordian" id="panelsStayOpen-collapse3" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading3">
                              <div className="accordion-body">
                                <p>Althought Triptopper is very helpful for travel influencers/travel advisors to draft and share travel itineraries via public link. But it can also used by everyone and anyone who want to share their indepth travel experience. Anyone can use Triptopper's profile link as a link in bio or share specific itinerary link on social media with friends and family.</p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item border-bold mb-4">
                            <h2 className="accordion-header" id="panelsStayOpen-heading4">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                                How can I effectively use triptopper?
                              </button>
                            </h2>
                            <div data-bs-parent="#FaqAccordian" id="panelsStayOpen-collapse4" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading4">
                              <div className="accordion-body">
                                <p>Triptopper is an easy to use tool to create an itinerary for future or to create an itinerary while travelling or to create a travel itinerary from the trip you have already taken and you are keen to share the details with friends, family or anyone. It also helps you to revisit your memorable time spent and share it with the world.<strong>Triptopper helps you to live the moment again!</strong>  </p>
                              </div>
                            </div>
                          </div>
                          
                          <div className="accordion-item border-bold mb-4">
                            <h2 className="accordion-header" id="panelsStayOpen-heading5">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                                How can travel advisors use triptopper?
                              </button>
                            </h2>
                            <div data-bs-parent="#FaqAccordian" id="panelsStayOpen-collapse5" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading5">
                              <div className="accordion-body">
                                <p>Travel advisors can create simple & beautiful itineraries for their clients and share with one link instead of numerous documents sent over email and whatsapp. You can change itinerary and it will be available realtime.</p>
                              </div>
                            </div>
                          </div>


                        
                        </div>
                  </div>

                </div>
          
                <br></br>

              </div>
          </div>
        ) 
      
}

export default Faq;
