

import '../App.scss';
import { React,useRef, useEffect,useState } from "react";
const GoogleSearchAutocomplete = (props) => {
 const autoCompleteRef = useRef();
 const inputRef = useRef();
 const options = {
  fields: ["place_id","formatted_address","geometry", "icon", "name","types","reviews","user_ratings_total","rating","price_level"]
  
 };

 
 // This function converts object returned from google
 //place to system model.
 const getGooglePlacedetails = (place)=>
 {

    const placeid = place.place_id;
    const fulladdress = place.formatted_address;
    const locdata = place.geometry.location;
    const latitude = locdata.lat();
    const longitude = locdata.lng();
    const name = place.name;
    const types = place.types

    var _loc =  { 
      "type": "Point",
      "coordinates": [longitude,latitude]
    };
    var _location = {
      name:name,
      loc:_loc,gid:placeid
      ,placetypes:types
      ,fulladdress:fulladdress};
    return _location;

 }
 useEffect(() => {
  //componentRestrictions 
 if(props.gtype){
  //var sample = ['restaurant'];
  options.types = props.gtype;
}



 if(props.gcountry){options.componentRestrictions = { country: props.gcountry }}
 if(!props.stopAutoFocus){inputRef.current.focus()}

  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
   inputRef.current,
   options
  );
  autoCompleteRef.current.addListener("place_changed", async function () {
    const place =  await autoCompleteRef.current.getPlace();
    if(place){
        console.log(place);
        var data = getGooglePlacedetails(place);
        props.add(data,'place');
        inputRef.current.value = '';
    };

 }, []);
 })
 return (
    <div className='container pb-4'>
      <div className='row'>
          <div className='col-sm-12'>
          <div className="input-group">
          <input ref={inputRef} className={props.cname} placeholder={props.placeholder} />
            <div className="input-group-append">
              {/* 
            <button className='input-group-text btn border border-start-0 fw-bold text-center' onClick={props.cancel}> X </button>
 */}
            </div>
          </div>

          </div>
          <div className='col-sm-3'>
          </div> 
      </div>
    </div>
 );
};
export default GoogleSearchAutocomplete;
