import React,{useContext, useEffect,useState} from 'react';
import {Link,Router, Routes, Route, Navigate,Outlet,useNavigate, useLocation } from 'react-router-dom';
import logo from '../TT3.png';
import icon_itineraries from '../icons/folder.png';
import icon_plus from '../icons/plus.png'
import icon_user from '../icons/user.png'
import icon_feedback from '../icons/feedback.png'
import ico_settings from '../icons/cog.png'

import Home from './Home';
import GetStartedItin from './Creater/GetStartedItin';
import Myitin from './Creater/Myitin';
import Itin from './User/Itin';
import Inbox from './Inbox';

import Myitins from './Creater/Myitins';
import Settings from './Settings';
import AppContext from './globalcontext';

import Authservice from './Authservice';
import SignIn from './SignIn';
import ReactGA from 'react-ga4';
import Page from './Page';
import Myprofile from './Myprofile';
import PublicProfile from './PublicProfile';
import Button from 'react-bootstrap/esm/Button';
import Aboutus from './PublicProfile';
import Termsofuse from './Termsofuse';

import Privacypolicy from './Privacypolicy';
import Feedback from './Feedback';
import ico_add from '../icons/add.png';
import ico_home from '../icons/home.png';
import ico_menu from '../icons/menu.png';
import ico_inbox from '../icons/inbox.png'; 
import ico_hotel from '../icons/hotel.png'; 
import CreateBlog from './Creater/CreateBlog';
import Subscription from './Subscription';
import Knowyou from './Knowyou';
import Hotels from './Hotels';

const PrivateRoute = () => {
  const navigate = useNavigate();

  const globalContext = useContext(AppContext);
  useEffect(()=>{

    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home Page - Private" });

  },[])

  function logout(){
    Authservice.logout();

    globalContext.setcurrentuser(null);
    navigate('/app/signin');

  }
  return (
    <>
        <Navbar user={globalContext.currentuser} logout={logout} />
        <div className="d-md-none d-sm-block"><br></br> <br></br></div>
        <div className='content d-flex flex-column min-vh-100'>

        <Routes>
          <Route path='/' element={<Myprofile/>}></Route>
          <Route exact path='/app/home' element={<Navigate to="/" replace />}></Route>
          <Route path='/app/knowyou' element={<Knowyou />}></Route>
          <Route exact path='/app/Create-new-itin' element={<GetStartedItin/>}></Route>
          <Route path='/app/myitin/:id' element={<Myitin />}></Route>
          <Route path='/app/myitin' element={<Myitin />}></Route>
          <Route path='/alpha-t1/admin-b/k' element={<CreateBlog/>}></Route>
          
          <Route path='/i/:id' element={<Itin/>}></Route>
          <Route path='/app/myprofile' element={<Myprofile/>}></Route>
          
          <Route exact path='/app/myitins' element={<Myitins />}></Route>
          <Route path='/app/inbox' element={<Inbox/>}></Route>
          <Route path='/app/hotels' element={<Hotels/>}></Route>
          <Route exact path='/app/settings' element={<Settings/>}></Route>
          <Route path='/:id' element={<PublicProfile/>}></Route>
          <Route exact path='/app/aboutus' element={<Aboutus/>}></Route>
          <Route exact path='/app/termsofuse' element={<Termsofuse/>}></Route>
          <Route exact path='/app/privacypolicy' element={<Privacypolicy/>}></Route>
          <Route path='/app/Feedback' element={<Feedback/>}></Route>
          <Route path='/app/subscription' element={<Subscription/>}></Route>
          <Route element={<WithNav/>}>
            <Route exact path='/app/notfound' element={<Notfound/>}></Route>
          </Route>
          <Route path='*' element={<Navigate to="/app/home" replace />} />
        </Routes>
        <Footer/>

        </div>

    </>

  )
}

const Navbar = (props)=>{
  const {pathname} = useLocation();
  const [user,Setuser] = useState(props.user);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [activelink,Setactivelink] = useState('');
  const [activeClass,SetactiveClass] = useState('bg-yellow');

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handlesidenavlinkclick = (selectedlink)=>{
    Setactivelink(selectedlink)
  }
  const AgentMenu = (props)=>{
        
    // show inbox to user with pro plan
    // if(user.plan === 'ITR' || user.plan === 'ADV'){
    if(user.usertype === 'AGNT' || user.usertype === 'IFNR'){
      if(props.navitem){
        return( <>
        <li className="nav-item">
            <Link onClick={handleNavCollapse} className='nav-link p-3 ' to="/app/inbox"><img className='i-sm me-2' src={ico_inbox}></img>Inbox</Link>
        </li>

        {/* Hotels tab */}
        {/* <li className="nav-item">
            <Link onClick={handleNavCollapse} className='nav-link p-3' to="/app/hotels"><img className='i-sm me-2' src={ico_hotel}></img>Hotels</Link>
        </li> */}
        </>
      )} else{
      return(<>
        <Link data-bs-dismiss="offcanvas" className={(pathname ==="/app/inbox"?activeClass+' btn fw-bold ':' btn fw-bold ') + ' stepsixteen w-100 text-start fs-5 mt-1 shadow-none'} to="/app/inbox"><img className='i-sm mx-2' src={ico_inbox}></img>Inbox</Link>

        {/* Hotels tab */}
        {/* <Link onClick={()=>handlesidenavlinkclick('hotels')} className={activelink ==="hotels"?activeClass+' btn fw-bold ':'btn fw-bold'} to="/app/hotels"><img className='i-sm me-2' src={ico_hotel}></img>Hotels</Link> */}
        </>
      )}
    }
  }

  useEffect(()=>{
    var windowurl = window.location.href;
    if((windowurl.indexOf('/myitin/')>-1)||(windowurl.indexOf('/myitins')>-1)){
      Setactivelink('myitin');
    }else if(windowurl.indexOf('/feedback')>-1){
      Setactivelink('feedback');
    }else if(windowurl.indexOf('/Create-new-itin')>-1){
      Setactivelink('newitin');
    }else if(windowurl.indexOf('/myprofile')>-1){
      Setactivelink('profile');
    }else{
      Setactivelink('profile');

    }
  },[]);

  const NavBody = () => {

    const matchPathHome = () => {
     if(pathname === "/" || pathname === "/app/myprofile" || pathname.includes('/app/myitin/'))  
      return activeClass;
    }

    const matchPathSetting = () => {
      if(pathname === "/app/settings" || pathname === "/app/feedback"){
         return activeClass;
       }
     }
    
    return(<div className='sidebar position-fixed d-flex flex-column'>
    <div className="ps-3 mt-4">

      <Link className="navbar-brand btn" to="/">
            <img src={logo} className=' img-fluid'></img>
      </Link>
    </div>

    <div className='mt-4 p-4'>
      <Link data-bs-dismiss="offcanvas" className={( matchPathHome() +' btn fw-bold ') + ' stepfourteen w-100 text-start fs-5 shadow-none'} to="/app/myprofile"><img className='i-sm mx-2' src={ico_home}></img>Home</Link>

      <Link data-bs-dismiss="offcanvas" className={(pathname ==="/app/Create-new-itin"?activeClass+' btn fw-bold ':'btn fw-bold') + ' stepfifteen w-100 text-start fs-5 mt-2 shadow-none'} to="/app/Create-new-itin"><img className='i-sm mx-2' src={ico_add}></img>New Itinerary</Link>

      <AgentMenu></AgentMenu>
      
      {/* 
      <Link onClick={()=>handlesidenavlinkclick('myitin')} className={activelink ==="myitin"?activeClass+' btn fw-bold ':'btn fw-bold'} to="/app/myitins"><img className='i-sm me-2' src={icon_itineraries}></img>My Itineraries</Link>
      */}

      <div className="w-100 btn-group">
        
        <span className={ matchPathSetting() +" dropdown-toggle fw-bold btn w-100 text-start fs-5 mt-2 shadow-none"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span className=''><img className='i-sm mx-2' src={ico_menu}></img>More</span>
        </span>
        
        <ul className="dropdown-menu p-3" aria-labelledby="navbarDropdown">
          <li className='mb-3 fw-bold dropdown-item'>{props.user.given_name}</li>
          
          <li><Link data-bs-dismiss="offcanvas" className="dropdown-item" to="/app/settings"><img className='i-sm me-2' src={ico_settings}></img>Settings</Link></li>
          <li><Link data-bs-dismiss="offcanvas" className='dropdown-item' to="/app/feedback"><img className='i-sm me-2' src={icon_feedback}></img>Feedback</Link></li>

          {/* Subscription tab */}
          {/* <li><Link className='dropdown-item' to="/app/subscription"><img className='i-sm me-2' src={icon_feedback}></img>Subscription</Link></li> */}

          <li><hr className="dropdown-divider"/></li>
          <li><Link data-bs-dismiss="offcanvas" className="dropdown-item" to='/' onClick={props.logout}>Sign out</Link></li>
        </ul>
        
      </div>
      <br></br>

    </div>
  </div>)
  }

  /*
  var bool = (window.location.href.indexOf("/i/") > -1);
  if(!bool)
  {
    */
    return(<>
        <div className='d-flex flex-column d-none d-md-block'>
          <NavBody/>
        </div>

        <div className="d-md-none d-sm-block">
          <div className='border navbar py-1 fixed-top bg-white'>
            <button class="btn shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop">
            <img className='i-lg border rounded' src={ico_menu}></img>
            </button>
          </div>
          

          <div class="offcanvas drawer offcanvas-start " tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
            {/* <div class="offcanvas-header">
              <h5 id="offcanvasTopLabel">Offcanvas top</h5>
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div> */}
            <div class="offcanvas-body bg-light">
              <NavBody/>
            </div>
          </div>
        </div>
          </>)
  /*
  }
  else
  {

  }
  */
}


const Notfound =()=>{
  return(
    <div className='content'>
    <div className='container'>
      <div className='row' >
        <div className='col-sm-12 mt-4'>
          <br></br>
            <center>
              <p className='hero4'>[404]</p>
              <br></br>
              <p>The page you are looking does not exist!</p>
            </center>
        </div>
      </div>
    </div>
  </div>
  )
  }
const WithNav = ()=>{
    return (
      <>
        <Navbar/>
        <Outlet/>
        <Footer/>
      </>
    )
  }
  
const WithoutNav =()=>{
    return(
      <Outlet/>
      
    )
  }



const Footer =()=>{



  return (
    <footer className="border-top bg-white mt-auto mt-4"  role="contentinfo">
    <div className="container">
      <div className="row p-1">
   
      <div className="col-sm-12">
          <div className="text-center">
             <Link className='me-4 custom-small-10px' to="/app/termsofuse">Terms of use</Link>
              <Link className='me-4 custom-small-10px' to="/app/privacypolicy">Privacy policy</Link>
             
          </div>
        </div>
     
       </div>

    <div className="row">
      <div className="col-md-12 copy">
        <p className="text-center">&copy; Copyright 2023-2024 | TripTopper.  All rights reserved.</p>
      </div>
    </div>
    </div>
  
  
  </footer>
  );



  }
export default PrivateRoute;