import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import ModalDialog from 'react-bootstrap/ModalDialog';

const LoadingOverlay = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal
      show={true} 
    //   onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      contentClassName='bg-transparent border-0'>
        <ModalDialog contentClassName='bg-transparent border-0'>
            <center>
            <Spinner animation="border" style={{height: 50, width: 50}}/>
            <h3 className='m-4'>Generating PDF...</h3>
            </center>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default LoadingOverlay;