import '../../App.scss';
import React,{useState,useRef,useEffect} from 'react';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Authservice from '../Authservice';
import EditLocation from './EditLocation';
import { createitin } from '../../api/itin';

function GetStartedItin() {

  const Showlocationhtml = ()=>
  {
    if(showlocation)
    {
    return (
    <EditLocation visibility={isvalidtitle} customclass="rounded-pill custom-large-box" setlocation={handleLocationSet} selectedlocation={selectedlocation}></EditLocation>
    );
    }else{
      if(selectedlocation){
      return(
        <>
          <p className='fw-bold ms-4'>Destination</p>
          <p onClick={()=>{Setshowlocation(true)}} className={"rounded-pill custom-large-box bg-light form-control form-control-lg p-4"}>{selectedlocation?selectedlocation.fulladdress:''}</p>
        </>
        )
      }
    }
  }
  const location = useLocation();
  const navigate = useNavigate();

  const [title,Settitle] = useState("");
  const [selectedlocation,Setselectedlocation] = useState(null);
  const [isvalidtitle,SetValidTitle] =  useState(false);
  const [titlemsg,Settitlemsg] = useState('');
  const [isvalidLocation,SetisvalidLocation] = useState(false);
  const [nextbtntext,Setnextbtntext]= useState("Set destination");
  const [hinttext,Sethinttext] = useState("Examples: Summer in california via pacific coast highway, Golden triangle - India, or Spritual journey across south east asia")
  const [hinttextclass,Sethinttextclass] = useState('fs-5');
  const [labeltext,Setlabeltext] = useState("Title")

  const titletextboxref = useRef(null);
  const nextbtnref = useRef(null);
  const [btnselected,Setbtnselected] = useState("");
  //const [isitinCreated,SetisitinCreated] = useState(false);
  const [showlocation,Setshowlocation] = useState(false);
  const [shownextbutton,Setshownextbutton] = useState(false);

  useEffect(() => {
    //titletextboxref.current.focus();
    //titletextboxref.current.onkeyup = handleChange;
    //titletextboxref.current.placeholder = title;
  },[]);

  function handleChange(e) {
    e.preventDefault();
    Settitle(e.target.value);
    var title = e.target.value
    if(title.length>1 && title.length<10)
    {
      Settitlemsg('Title should be atleast 10 character longs')
      
      Setshownextbutton(false);

    }
    else if(title.length >= 10){
      Settitlemsg("");
      SetValidTitle(true);

      if(isvalidLocation){
        Setbtnselected('bg-yellow')
        Setnextbtntext("Create")
        Setshownextbutton(true);

      }else{
        Setbtnselected('')
        Setnextbtntext("Set destination")
        Setshownextbutton(true);

      }
    }
    else{
      
      Setshownextbutton(false);
      SetValidTitle(false)
    }
  }

  function onNextClicked(){
  
    if(isvalidLocation && isvalidtitle)
    {
      var headertoken = Authservice.getheadertoken();
          axios.defaults.headers.common['Authorization'] =    
                'Bearer '+ headertoken;
      var itin = {title:title,location:selectedlocation}

      axios.post(createitin,{itin:itin})
      .then((response) => {
          if(response.data.success){
            navigate('/app/myitin/'+response.data._id);
            //SetisitinCreated(true);
          }
          //todo MSG
        })
      .catch(err => {
        
      });
    }
    else if(isvalidtitle)
    {
      Setshowlocation(true);
      Setshownextbutton(false);
    }
  }

  const handleLocationSet=(Selectedlocation)=>
  {
    console.log(Selectedlocation);
    Setselectedlocation(Selectedlocation);
    if(!Selectedlocation){
      SetisvalidLocation(false);
      Setshownextbutton(false);

    }else{
      Setshowlocation(false);
      SetisvalidLocation(true);
      Setshownextbutton(true);
      nextbtnref.current.focus();
      Setbtnselected('bg-yellow')
      Setnextbtntext("Create")
    }
  }

  const handleTitleFocus =()=>{
    Sethinttextclass("custom-small-10px");
    Setshowlocation(false);
  }

  const handleKeyDown_Title = (event) => {
    if (event.key === 'Enter' && isvalidtitle) {

      if(isvalidLocation){
        Setshowlocation(true)
        Setshownextbutton(true);
        Setbtnselected('bg-yellow')
        Setnextbtntext("Create")
      }else{
        Setshowlocation(true)

        Setshownextbutton(false);

      }
    }
  }

  return (
    <div className='mb-5' >
      
      <div className="container mb-4">
        <div className='row'>
        
          <div className='col-sm-12 col-md-8 offset-md-2'>
            <br></br>
            <center>
              <br></br>
              <p className='hero3 mb-4'>Add an impressive title to your itinerary</p>
              <p className={'mb-5 '+hinttextclass}>{hinttext}</p>
            </center>
              <p className='fw-bold ms-4'>{labeltext}</p>
              <input 
              autoComplete="off"
              onFocus={handleTitleFocus}
              onKeyDown={handleKeyDown_Title}
              ref={titletextboxref} 
              onChange={handleChange}
              value={title} 
              name='triptitle' placeholder='Enter the title of your itinerary'
              className='form-control rounded-pill custom-large-box p-4'></input>
              <p className='small gray mt-1'>{titlemsg}</p>

              <br></br>

              <Showlocationhtml></Showlocationhtml>
              <div className='row mt-4'>
                <br></br>
                <br></br>
                <div className='col-sm-12 col-md-6 offset-md-3'>
                  <button  ref={nextbtnref} style={{ visibility: shownextbutton ? "visible" : "hidden" }} className={'btn border-bold rounded-pill fs-4 fw-bold p-2 form-control p-4 mt-4 '+btnselected} onClick={onNextClicked}>{nextbtntext}</button>
                </div>
              </div>
          
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
 
}

export default GetStartedItin;
