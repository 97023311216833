import '../App.scss';
 
import React,{useState,useEffect} from 'react';

function CreateItinerarydayform(data) 
{
  const [DayInfo, setDayInfo] = useState(data.details);

  useEffect(() => {
    console.log(data.details)
    
  })

  function handleInputChange(e){
    this.setState({[e.target.name]: e.target.value,},
      () => {this.validateField(e.target.name,e.target.value)});
  };

  return(
            <form className=''>
              <label className='display-6'>{DayInfo.name}</label>
              <br></br>
              <br></br>
              <textarea name='details' placeholder={DayInfo.details} onChange={handleInputChange}    className='form-control h1' rows='6' />
              <br></br>
           
              <br></br>
              <br></br>
              <button className='btn bg-theme p-4 rounded-pill' type="submit"><h2>Save</h2></button>
            </form>
  );
  
}

export default CreateItinerarydayform;
