import React, { useState } from 'react';

import './App.scss';
import AppContext from './components/globalcontext';

import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import authservice from './components/Authservice';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-9GSNK889ZV"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App(){
  const [currentuser, setcurrentuser] = useState(authservice.getCurrentUser());


  const userSettings = {
    currentuser: currentuser,
    setcurrentuser : setcurrentuser
  };

  return (
    <ScrollToTop>
    <AppContext.Provider value={userSettings}>
    <div className="App">
    {!currentuser ?<PublicRoute />: <PrivateRoute/> }

          
    </div>
   
    </AppContext.Provider>
    </ScrollToTop>

  );



}



export default App;
