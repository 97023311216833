import React,{useState,useEffect,useRef} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Itin from '../User/Itin';
import { copyitin, getbynanoid } from '../../api/itin';
import axios from 'axios';
import Authservice from '../Authservice';
import TopBlock from '../User/TopBlock';
import ViewDay from '../User/ViewDay';
import ico_preview from './../../icons/copy.png';
import ico_calender from './../../icons/calender.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function CopyItinModal(props) {
    const [startDate, setStartDate] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));

    const onClickCopy = () => {
            axios.post(copyitin,{itinid:props.itin?._id,startdate:startDate})
            .then((response) => {
                if(response.data.success){
                    console.log(response.data.itin)
                    props.updateItins(response.data.itin)
                    props.onHide();
                }
            }).catch(err => {
                console.log("Error setting theme!");
            });
    }

    return (
      <Modal
        // {...props}
        show={props.show}
        onHide={props.onHide}
        size='lg'
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
            <span className='fw-bold me-2 2'>New Itinerary</span>

        </Modal.Header>
        <Modal.Body className="show-grid">
        {props.itin && <div className='container'>
        <p className=' my-3'>"{ props.itin.title }"</p>
            {/* <div className='row d-flex flex-column justify-content-between align-items-center mx-md-4 mb-4'> */}
                <div className='my-5 d-flex mt-4 align-items-baseline'>
                    <p className='fw-bold fs-6 me-3'>Start date:</p>
                    <span className='d-flex align-items-center'>
                    <DatePicker 
                    className='form-control border fs-5 py-1'
                    selected={startDate} onChange={date => setStartDate(date)}
                    // inline
                    withPortal
                    minDate={new Date()}
                    dateFormat="MMMM d, yyyy"
                    />
                    <img className=' i-sm mx-2' src={ico_calender}></img>
                    </span>
               {/* </div> */}
                {/* <DatePicker1 label='Start date' date={startDate} setDate={setStartDate}/> */}
               
            </div>
          {/* <ItinView id={props.itin.nanoid}></ItinView>           */}
          </div>}
          <div className=''>
            <button className='my-5 border shadow-sm btn px-4 btn btn-success rounded-pill  float-end' onClick={onClickCopy} target="_blank" >
                    <span className='fw-bold fs-4'>Create </span>
                    {/* <img className='i-sm ms-1' src={ico_preview}></img> */}
            </button>
          
          </div>
        
          
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant='secondary' onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    );
  
}

// const DatePicker1 = (props) => {
//     const {label, readOnly, date, setDate} = props;
   
//     const validateDate = (date) => {
//        const today = new Date();
//        today.setHours(0, 0, 0, 0);
//        return date > today;
//     };
   
//     const handleChange = (event) => {
//        const dateString = event.target.value;
//        const selectedDate = new Date(dateString);
//        if (validateDate(selectedDate)) {
//         setDate(selectedDate);
//        } else {
//          alert('You cannot select a day in the past!');
//        }
//     };
   
//     return (
//        <div className=''>
//          <label className='fw-bold fs-6 m-1'>{label}:</label>
//          <input
//            type="date"
//            readOnly={readOnly}
//            className='form-control fs-5 fw-bold' 
//            value={date.toISOString().split('T')[0]}
//            onChange={handleChange}
//            min={date.toISOString().split('T')[0]}
//          />
//        </div>
//     );
//    };

   const ItinView = ({id}) => {
    const [itin,SetItin] = useState(null);

    useEffect(()=>{
            
        if(id){var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        const url = getbynanoid+id;
        axios.get(url).then(res=>{
          if (res.data.success && res.data.itin) {
            var _days = res.data.itin.days;
            _days.sort((a,b) => a.order - b.order);
            SetItin({...res.data.itin, days: _days});
          }
      }).catch(err=>{
        console.log(err);
      })}else{}
    },[]);

    if(!itin){
        return (<></>)
    }

    return (<div className='container'>
          <TopBlock 
          theme={itin.themename?itin.themename:"theme-gray"} bgimage={itin.bgimage}
          itin={itin} ></TopBlock>

            <div className='bg-white shadow border'>
                {
                  itin.days.map((item,index)=>{
                    return(<div key={item.day._id.toString()} >
                      <ViewDay  item={item.day} index={index}></ViewDay>
                      </div>)
                  })
                }
            </div>
            <br></br>
            <br></br>
          </div>)
   }


export default CopyItinModal;
