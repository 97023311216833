export const localapiaddress ="http://localhost:3003";

export const APIserverAddress = "https://api.triptopper.com";
export const appaddress = "https://triptopper.com";
export const localappaddress = "";

export const signinwithemail = APIserverAddress+'/auth/signinwithemail';
export const verifyemail = APIserverAddress+'/auth/verifyemail';
export const setuserdetails = APIserverAddress+'/auth/setuserdetails';
export const googleauthentication = APIserverAddress+'/auth/googlelogin';
export const facebookauthentication = APIserverAddress+'/auth/facebooklogin';
export const createitin = APIserverAddress+'/itin/create-itin-post';
export const getpendingitin = APIserverAddress+"/itin/get-pending";
export const getall = APIserverAddress+"/itin/get-all";
export const presignedurl = APIserverAddress+'/account/presignedurl';

export const getbynanoid = APIserverAddress+"/it/getbynanoid/";
export const getmyitin = APIserverAddress+"/itin/getbyid/";
export const getitinsearch = APIserverAddress+"/it/find";
export const updateviewscount = APIserverAddress+"/it/update-viewscount/";

export const getactivitysearch = APIserverAddress+"/activity/find";
export const publishitin = APIserverAddress+"/itin/publish";
export const deleteitin = APIserverAddress+"/itin/delete";
export const setitintheme = APIserverAddress+"/itin/set-theme";
export const updateitintitle = APIserverAddress+"/itin/settitle";
export const setbgimage = APIserverAddress+"/itin/setbgimage"; 
export const setleadson = APIserverAddress+"/itin/setleadson"; 

export const addaday = APIserverAddress+"/itin/add-day";
export const updatedaylocation = APIserverAddress+"/itin/updatedaylocation";
export const setwheretostay = APIserverAddress+"/itin/setwheretostay";
export const deleteaday = APIserverAddress+"/itin/delete-day";
export const addevent = APIserverAddress+"/itin/add-event";
export const deleteevent=APIserverAddress+"/itin/delete-event";
export const saveactivityorder = APIserverAddress+"/itin/saveactivityorder";
export const savedayorder = APIserverAddress+"/itin/savedayorder";
export const addnote = APIserverAddress+"/itin/add-note";
export const deletenote = APIserverAddress+"/itin/delete-note";

export const copyitin = APIserverAddress+"/itin/copy-itin";

export const checkaccountnameavailability = APIserverAddress+"/account/checkavailablity";
export const sethandle = APIserverAddress+"/account/sethandle";
export const setplan = APIserverAddress+"/account/setplan";
export const saveprofilebio = APIserverAddress+"/account/saveprofilebio";
export const getaccountdetails = APIserverAddress+"/account/getaccountdetails";
export const updateusertype = APIserverAddress+"/account/updateusertype";
export const saveaccountdetails = APIserverAddress+"/account/saveaccountdetails";
export const recorditinview = APIserverAddress+"/itin/record-itinview";
export const recorditinshare = APIserverAddress+"/itin/record-itinshare";
export const getmyprofile = APIserverAddress+"/account/get-profile";
export const getpublicprofile = APIserverAddress+"/getpublicprofile/";

export const createorder = APIserverAddress+"/payment/orders";
export const paymentsuccess = APIserverAddress+"/payment/paymentsuccess";

export const addQuery = APIserverAddress+"/query/create";
export const getQuery = APIserverAddress+"/inbox/get";
export const setopened = APIserverAddress+"/inbox/setopened";

export const addhotel = APIserverAddress+"/hotel/addhotel"; 
export const getmyhotels = APIserverAddress+"/hotel/getmyhotels"; 
export const createlead = APIserverAddress+"/leads/createlead";
