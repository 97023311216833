import React,{useContext} from 'react';
import { useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import authservice  from './Authservice';
import AppContext from './globalcontext';


  const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })

 
  function handleCredentialResponse(response) {
    /*
    console.log("ID: " + responsePayload.sub);
    console.log('Full Name: ' + responsePayload.name);
    console.log('Given Name: ' + responsePayload.given_name);
    console.log('Family Name: ' + responsePayload.family_name);
    console.log("Image URL: " + responsePayload.picture);
    console.log("Email: " + responsePayload.email);
    */
  }
const GoogleAuth = () => {
  const navigate = useNavigate();
  const globalContext = useContext(AppContext);

  globalContext.setcurrentuser(authservice.getCurrentUser());
  const googleButton = useRef(null);

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    const id = "971548546925-2n9oj588m4eonstgdc5o19r6lu95p23a.apps.googleusercontent.com"

    loadScript(src)
      .then(() => {
        /*global google*/
        //console.log(google)
        if(google.accounts)
        {
        google.accounts.id.initialize({
          client_id: id,
          callback: (response)=>{
            authservice.googlelogin(response)
            .then((res)=>{ if(res){
              globalContext.setcurrentuser(authservice.getCurrentUser());
              var {usertype} = authservice.getCurrentUser();
              usertype ? navigate('/') : navigate('/app/knowyou');
            }
            else{navigate('/')}})
            .catch(err=>{ navigate('/')})    
          }
        })
        google.accounts.id.renderButton(
          googleButton.current, 
          {'scope': 'profile email',
          'ux_mode': 'redirect',
          'longtitle': true,
          'theme': 'dark'
           } 
        )
      }
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])



  return (
    <>
    <div ref={googleButton}></div>


    </>
  )
}

export default GoogleAuth