import '../App.scss';
import {Link} from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authservice from './Authservice';
import axios from 'axios';
import { checkaccountnameavailability, getaccountdetails, saveaccountdetails } from '../api/itin';
import Loading from './Loading';
import Authservice from './Authservice';
import UsertypeSelector from './UsertypeSelector';

function Settings() {
  const navigate = useNavigate();
  const [userdata,Setuserdata] = useState(null)
  const [editit,Seteditit]= useState(false);
  
  let axiosInstance = axios.create({
    headers: {
      Authorization : `Bearer ${authservice.getheadertoken()}`
      }
  })
  
  const getname = ()=>{
     const given_name = userdata && userdata.given_name?userdata.given_name:'';
     const family_name = userdata && userdata.family_name?userdata.family_name:'';
     return  given_name+' '+family_name;
  }

  const saveuserdata =(updateduserdata)=>{
    if(userdata){Setuserdata(updateduserdata);}
    Seteditit(false);
  }

  useEffect(()=>{
      var headertoken = Authservice.getheadertoken();
      axios.defaults.headers.common['Authorization'] =    
            'Bearer '+ headertoken;
      axios.get(getaccountdetails).then(res=>{
        if (res.data.success) {
          Setuserdata(res.data.userdata);
        }
      }).catch(err=>{
      console.log(err);
      })
  },[])
  
  if(userdata){
    if(!editit){
      return(<Viewsettings userdata={userdata} getname={getname} seteditit={Seteditit} ></Viewsettings>)
    }
    else{
     return(<Editsettings cancel={Seteditit} axios={axiosInstance} getname={getname} userdata={userdata} save={saveuserdata} ></Editsettings>)
    }
  }
  else{
    return(
    <div className='content-50'><br></br><center><p className='display-5 gray'>Loading....</p></center></div>
    )
  }
}

const Viewsettings = (props)=>{ 
  const getUsertype = (value) => {
    if(value === 'IFNR') return 'Travel Influencer';
    else if(value === 'AGNT') return 'Travel Agent';
    else if(value === 'TVLR') return 'Traveller';
    else return '';
  }

  return (
    <div className=''>
      <div className='container'>
        <div className='row p-4'>
          <div className='col-sm-12'>
            <p className='hero2'> Settings
            <button className='btn btn-light float-end' onClick={()=>props.seteditit(true)}> Edit </button></p>
            <hr></hr>
            {/** Handle */}
            <div className='row p-3'>
              <div className='col-sm-4 '>
                <p className='float-md-end fs-5 fw-bold'>User handle</p>
              </div>
              <div className='col-sm-4'>
                <p>{props.userdata.handle}</p>
              </div>
            </div>

            {/** Name */}
            <div className='row p-3'>
              <div className='col-sm-4 '>
                <p className='float-md-end fs-5 fw-bold'>Name</p>
              </div>
              <div className='col-sm-4'>
                <p className=''>{props.getname()}</p>
              </div>
            </div>

            {/** Email */}
            <div className='row p-3'>
              <div className='col-sm-4 '>
                <p className='float-md-end fs-5 fw-bold'>Email</p>
              </div>
              <div className='col-sm-4'>
              <p className=''>{props.userdata.email}</p>
              </div>
            </div>

            {/** User type */}
            <div className='row p-3'>
              <div className='col-sm-4 '>
                <p className='float-md-end fs-5 fw-bold'>User type</p>
              </div>
              <div className='col-sm-4'>
              <p className=''>{getUsertype(props.userdata.usertype)}</p>
              </div>
            </div>

            {/** Bio */}
            <div className='row p-3'>
              <div className='col-sm-4 '>
                <p className='float-md-end fs-5 fw-bold'>Bio</p>
              </div>
              <div className='col-sm-4'>
                <p className=''>{props.userdata.bio}</p>
              </div>
            </div>
            {/** ighandle */}

            <div className='row p-3'>
              <div className='col-sm-4 '>
                <p className='float-md-end fs-5 fw-bold'>Instagram handle</p>
              </div>
              <div className='col-sm-4'>
                <p className=''>{props.userdata.instagram}</p>
              </div>
            </div>
            

          </div>
        </div>
      </div>
  </div>
  )
}

const Editsettings = (props)=>{

    const navigate = useNavigate();
    const [userdata,Setuserdata] = useState(props.userdata)
    const [available,SetAvailable]= useState('Please do not use any special characters or spaces');
    const [availablecolor,SetAvailablecolor] = useState('gray');
    const [isvalidhandle,Setisvalidhandle] = useState(false);

    function handleCheckAvailability()
    {
      if(userdata.handle ){
        if(userdata.handle.length >= 4){
          props.axios.post(checkaccountnameavailability,{handle:userdata.handle})
            .then((response) => {
                const res = response.data.success;
                if(res){
                  if(res == 'ERR'){
                    SetAvailable('Unable to check, please try again!')
                    SetAvailablecolor('red')
                  }else{
                  SetAvailable('Available')
                  SetAvailablecolor('green')
                  }
                }else{
                  SetAvailable('Unavailable')
                  SetAvailablecolor('red')
  
                }
              }).catch(err => {
              console.error(err);
            });
        }
        else{
          SetAvailable('User handle must be atleast 4 character long.')
          SetAvailablecolor('orange') }
      }else{
        SetAvailable('is this a joke?')
        SetAvailablecolor('orange')
      }
      
    }
    
    const saveuserdata=()=>{
      
      props.axios.post(saveaccountdetails,{details:userdata})
        .then((response) => {
            const res = response.data.success;
            const token = response.data.token;
            if(token){
                Authservice.setfacebooktoken(token);
                //refresh the page
                window.location.reload(); 
            }
            if(res){
              props.save(userdata);
            }
          }).catch(err => {
          console.error(err);
        });
    
    }

    const givenNameChange = (e) => {
        Setuserdata({...userdata, given_name: e.target.value})
    }

    const familyNameChange = (e) => {
        Setuserdata({...userdata, family_name: e.target.value})
    }

    const SetUsertype = (value) => {
      Setuserdata({...userdata, usertype: value});
    }

    const userHandleOnChange =(e)=>{
      ///[^\w\s/\s/g]/gi
      var name = e.target.value.replace(/[^A-Z0-9]/gi, "");
      if(name.length<=20)
      {
      Setuserdata({...userdata, handle: name})
      }
      if(name.length < 4 ){
        Setisvalidhandle(false);
        SetAvailable('User handle must be between 4 to 20 characters.')
        SetAvailablecolor('gray') 
      }
      else{
        SetAvailable('')
      }
    
    }
        
    return (
      <div className=''>
        <div className='container'>
          <div className='row p-4'>
            <div className='col-sm-12'>
              <p className='hero2'> Settings</p>
              <hr></hr>
              {/** Handle */}
              <div className='row p-3'>
                <div className='col-sm-4 '>
                  <p className='float-md-end fs-5'>User handle</p>
                </div>
                <div className='col-sm-4'>
                  <input className='form-control' placeholder='Claim your userhandle' value={userdata.handle} onChange={userHandleOnChange} name='userhandle'></input>
                  <p name='userhandlestatus' style={{color: availablecolor}} className='small'>{available}</p>
                </div>
                <a className='col-sm-4 btn btn-link' onClick={handleCheckAvailability}> Check availability</a>
              </div>
    
              {/** Name */}
              <div className='row p-3'>
                <div className='col-sm-4 '>
                  <p className='float-md-end fs-5'>Name</p>
                </div>
                <div className='col-sm-4'>
                {/* <p className=''>{props.getname()}</p> */}
                <div className='d-flex'>
                  <input className='form-control me-2' placeholder='First name' value={userdata.given_name} onChange={givenNameChange} name='firstname'></input>
                <input className='form-control' placeholder='Last name' value={userdata.family_name} onChange={familyNameChange} name='lastname'></input>
                </div>
                
                </div>
              </div>
              {/** Email */}
              <div className='row p-3'>
                <div className='col-sm-4 '>
                  <p className='float-md-end fs-5'>Email</p>
                </div>
                <div className='col-sm-4'>
                <p className=''>{userdata.email}</p>
                </div>
              </div>

              {/** User type */}    
              <div className='row p-3'>
                <div className='col-sm-4 '>
                  <p className='float-md-end fs-5'>User type</p>
                </div>
                <div className='col-sm-5'>
                <UsertypeSelector usertype={userdata.usertype} setUsertype={SetUsertype}/>
                </div>
              </div>

              {/** Bio */}
              <div className='row p-3'>
                <div className='col-sm-4 '>
                  <p className='float-md-end fs-5'>Bio</p>
                </div>
                <div className='col-sm-4'>
                  <textarea className='form-control' value={userdata.bio} onChange={(e) => Setuserdata({...userdata, bio: e.target.value})}></textarea>
                </div>
              </div>
              {/** ighandle */}
    
              <div className='row p-3'>
                <div className='col-sm-4 '>
                  <p className='float-md-end fs-5'>Instagram handle</p>
                </div>
                <div className='col-sm-4'>
                    <label className='p' htmlFor="test">https://www.instagram.com/</label>
                    <span>
                      <input className='form-control' onChange={(e) => Setuserdata({...userdata, instagram: e.target.value})} value={userdata.instagram?userdata.instagram:''} placeholder="Your instagram handle"/>
                    </span>
                    
                </div>
              </div>
              <center>
                <button className='btn btn-light' onClick={()=> props.cancel(false)}>Cancel</button>

                <button className='btn btn-primary' onClick={()=> saveuserdata()}>Save</button>
              </center>
    
            </div>
          </div>
        </div>
      </div>
         
  );
    
  
}

export default Settings;
