import React,{useContext, useEffect,useState} from 'react';
import {Link,Router, Routes, Route, Navigate,Outlet, useNavigate,useLocation } from 'react-router-dom';
import logo from '../TT3.png';

import Index from './Index';
import ProIndex from './Pro/Index';
import Signin from './SignIn';
import Itin from './User/Itin';
import Footer from './Footer';
import AppContext from './globalcontext';

import authservice from './Authservice';
import ReactGA from 'react-ga4';
import AnalyticsEventTrackerCreator from './AnalyticsEventTracker';
import PublicProfile from './PublicProfile';
import Plans from './Plans';

import Aboutus from './PublicProfile';
import Termsofuse from './Termsofuse';

import Privacypolicy from './Privacypolicy';
import Feedback from './Feedback';
import Story from './Story';
import { Nav } from 'react-bootstrap';

import Ctr from './User/Ctr';
import EmailLogin from './EmailLogin';
import Leads from './Leads';


const PublicRoute = () => {
  const gaEventTracker = AnalyticsEventTrackerCreator('Landing Page');
  const [subdomain, setSubdomain] = useState(null);

  const globalContext = useContext(AppContext);
  useEffect(()=>{
    globalContext.setcurrentuser(authservice.getCurrentUser());
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Landing Page - PUBLIC" });
    // <Navbar gaEventTracker={gaEventTracker} ></Navbar>


    const parsedData = window.location.host.split(".");

    if(parsedData.length >= 3 && parsedData[0] !== 'www'){
        setSubdomain(parsedData[0]);
    }


  },[]);

  if(subdomain){
    return (<Routes>
        <Route element={<WithoutNav/>}>
          <Route path='/i/:id' element={<Itin/>}></Route>
        </Route>

        <Route element={<WithoutNav/>}>
          <Route path='/i/:id/contact' element={<Ctr/>}></Route>
        </Route>

        <Route element={<WithPublicNav />}>
          <Route path='/' element={<PublicProfile id={subdomain}/>}></Route>
        </Route>

        <Route element={<WithPublicNav/>}>
          <Route exact path='/app/termsofuse' element={<Termsofuse/>}></Route>
        </Route>

        <Route element={<WithPublicNav/>}>
          <Route exact path='/app/privacypolicy' element={<Privacypolicy/>}></Route>
        </Route>

    </Routes>
)
  }
    
  return (
    <div>
      <Routes>
     
        <Route element={<WithoutNav/>}>
          
          <Route path='/i/:id' element={<Itin/>}></Route>

          
        </Route>

        <Route element={<WithoutNav/>}>
          <Route path='/i/:id/contact' element={<Ctr/>}></Route>

        </Route>
        <Route element={<WithoutNav/>}>
          <Route path='/:id' element={<PublicProfile/>}></Route>

        </Route>

        <Route element={<WithNav/>}>

          <Route path='/' element={<Index/>}></Route>
        </Route>
        {/** 
         * 
         * <Route element={<WithNav/>}>

          <Route path='/plans' element={<Plans/>}></Route>
        </Route>
        */}
        

        <Route element={<WithoutNav/>}>

          <Route exact path='/app/Signin' element={<Signin/>}></Route>
        </Route>
        <Route element={<WithoutNav/>}>

          <Route exact path='/app/Signin/EmailLogin' element={<EmailLogin/>}></Route>
        </Route>
        <Route element={<WithNav/>}>

          <Route exact path='/app/notfound' element={<Notfound/>}></Route>
        </Route>

        <Route element={<WithNav/>}>
          <Route exact path='/app/aboutus' element={<Aboutus/>}></Route>
        </Route>

        <Route element={<WithNav/>}>
          <Route exact path='/app/termsofuse' element={<Termsofuse/>}></Route>
        </Route>

        <Route element={<WithNav/>}>

          <Route exact path='/app/privacypolicy' element={<Privacypolicy/>}></Route>
        </Route>
        <Route element={<WithNav/>}>
          <Route path='/app/feedback' element={<Feedback/>}></Route>
        </Route>

        <Route element={<WithNav/>}>
          <Route path='/app/data/whoareyou' element={<Leads/>}></Route>
        </Route>
        
        <Route element={<WithNav/>}>

          <Route path='*' element={<Notfound/>}></Route>
        </Route>
        <Route element={<WithNav/>}>
          <Route path='/app/inappbrowsersign' element={<SignInwithGoogleNotAllowed/>}></Route>
        </Route>
        <Route element={<WithNav/>}>
          <Route path='/app/story' element={<Story/>}></Route>
        </Route>

        /** PRO Routes */
        <Route element={<WithAgentNav/>}>

          <Route path='/pro' element={<ProIndex/>}></Route>
        </Route>
    </Routes>
    </div>
   
  )
}



const SignInwithGoogleNotAllowed =()=>{
  const navigate = useNavigate();

  useEffect(()=>{
    var ua = navigator.userAgent || navigator.vendor || window.opera;  

    var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
    if(!isInstagram){
      navigate('/app/signin');
    }
  },[])


  return(
    <div className='content-80'>
    <div className='container'>
      <div className='row' >
        <div className='col-sm-12 mt-4'>
          <br></br>
            <center>
              <p>Google login not supported in in-app browser</p>
              <h4>Click on ... icon on top of your screen and select "Open in System browser"</h4>
            </center>
        </div>
      </div>
    </div>
  </div>
  )
}
const GetFooter = ()=>{
  var bool = (window.location.href.indexOf("/i/") > -1);
  if(!bool)
  {
 
  }else
  {
    
  }
}

const Navbar =(props)=>{
   var bool = (window.location.href.indexOf("/i/") > -1);
   const [isNavCollapsed, setIsNavCollapsed] = useState(true);
   const { pathname, hash, key } = useLocation();

   useEffect(() => {
    // if not a hash link, scroll to top
    
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      
    }
  }, [pathname, hash, key]);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
   if(!bool)
   {
    return (
      <div className=''>
      <Nav className=" border navbar py-3 fixed-top navbar-light bg-white navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <center>
              <img src={logo} className='App-logo'></img>
            </center>
          </Link> 
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" 
          data-bs-target="#somepagenavbarSupportedContent" 
          onClick={handleNavCollapse}
          aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>         
          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="somepagenavbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/*
                
                 <li className="nav-item">
                <Link className='nav-link btn p-3' to="/plans">Pricing</Link>
                </li>
                */}
                {/* 
                <li className='nav-item'>
                <div className="fb-like" data-href="https://www.triptopper.com" data-width="" data-layout="" data-action="" data-size="" data-share="true"></div>
                </li>
                
                <li className='nav-item'>
                  <Link onClick={handleNavCollapse} className=' nav-link fw-bold p-3 btn fs-4' to='/app/story'><span className=''>Story</span></Link>
                </li>*/}
                <li className='nav-item'>
                  <Link onClick={handleNavCollapse}  className=' nav-link pe-3 ps-3 fs-5   m-2' to='/#faq'><span className=''>FAQ</span></Link>
                </li>
                
  
                <li className='nav-item'>
                  <Link onClick={handleNavCollapse}  className=' nav-link pe-3 ps-3 fs-5 border-bold rouded  m-2' to='/app/Signin'><span className=''>Log in / Sign Up</span></Link>
                </li>

                <li className='nav-item d-none'>
                  <Link onClick={handleNavCollapse}  className=' nav-link fs-4 mt-1 button-63 color-white' to='/app/Signin'><span className=''>Get Started</span></Link>
                </li>
  
              
              </ul>
        </div>

      
          </div>
        </Nav>
     
    </div>
    )
   }else
   {

   }
}

const AgentNavbar =(props)=>{
  var bool = (window.location.href.indexOf("/i/") > -1);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
   // if not a hash link, scroll to top
   
   if (hash === '') {
     window.scrollTo(0, 0);
   }
   // else scroll to id
   else {
     
       setTimeout(() => {
         const id = hash.replace('#', '');
         const element = document.getElementById(id);
         if (element) {
           element.scrollIntoView();
         }
       }, 0);
     
   }
 }, [pathname, hash, key]);

 const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  if(!bool)
  {
   return (
     <div>
     <Nav className="navbar py-3 navbar-light bg-light navbar-expand-lg">
       <div className="container">
         <Link className="navbar-brand" to="/pro">
           <center>
             <img src={logo} className='App-logo'></img>
           </center>
         </Link> 
         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" 
         data-bs-target="#somepagenavbarSupportedContent" 
         onClick={handleNavCollapse}
         aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation">
             <span className="navbar-toggler-icon"></span>
         </button>         
         <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="somepagenavbarSupportedContent">
             <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
               {/*
               
                <li className="nav-item">
               <Link className='nav-link btn p-3' to="/plans">Pricing</Link>
               </li>
               */}
               {/* 
               <li className='nav-item'>
               <div className="fb-like" data-href="https://www.triptopper.com" data-width="" data-layout="" data-action="" data-size="" data-share="true"></div>
               </li>
               
               <li className='nav-item'>
                 <Link onClick={handleNavCollapse} className=' nav-link fw-bold p-3 btn fs-4' to='/app/story'><span className=''>Story</span></Link>
               </li>*/}
               <li className='nav-item'>
                 <Link onClick={handleNavCollapse}  className=' nav-link pe-3 ps-3 fs-5 btn m-2' to='/#faq'><span className=''>FAQ</span></Link>
               </li>
               
 
               <li className='nav-item'>
                 <Link onClick={handleNavCollapse}  className=' nav-link pe-3 ps-3 fs-5 border-bold  m-2' to='/app/Signin'><span className=''>Log in / Sign Up</span></Link>
               </li>
 
             
             </ul>
       </div>

     
         </div>
       </Nav>
    
   </div>
   )
  }else
  {

  }
}

const Notfound =()=>{
return(
  <div className='content-80'>
    <div className='container'>
      <div className='row' >
        <div className='col-sm-12 mt-4'>
          <br></br>
            <center>
              <p>[404]</p>
              <br></br>
              <p>The page you are looking does not exist!</p>
            </center>
        </div>
      </div>
    </div>
  </div>
)
}

const PublicNav =()=>{
  return (
    <div className=''>
    <Nav className=" border navbar py-2 fixed-top navbar-light bg-white navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <center>
            <img src={logo} className='App-logo'></img>
          </center>
        </Link> 
       </div>
      </Nav>
   
  </div>
  )
}

const WithPublicNav = ()=>{
  return (
    <>
      <PublicNav/>
      <br></br>
      <br></br>
      <br></br>
      <Outlet/>
      <Footer/>
    </>
  )
}

const WithNav = ()=>{
  return (
    <>
      <Navbar/>
      <br></br>
      <Outlet/>
      <Footer/>
    </>
  )
}

const WithoutNav =()=>{
  return(
    <Outlet/>
  )
}

const WithAgentNav=()=>{
  return(
    <>
    <AgentNavbar/>
  
    <Outlet/>
    <Footer/>
    </>
  )
}

export default PublicRoute;