import React, { useState,useContext,useEffect } from 'react';
import FacebookLogin from 'react-facebook-login';
import { Card, Image } from 'react-bootstrap';
import axios from 'axios';
import { facebookauthentication } from '../api/itin';
import { useNavigate } from "react-router-dom";
import authservice  from './Authservice';
import AppContext from './globalcontext';



function GetFacebookLogin() {

  const fbtestid = "1492084884654178";
  const fblive = "987541782656171";
  const [showloading,Setshowloading] = useState(false);
  const navigate = useNavigate();
  const globalContext = useContext(AppContext);
  globalContext.setcurrentuser(authservice.getCurrentUser());


    

  
  const responseFacebook = (response) => {
    console.log(response);
    if (response.accessToken) {
      Setshowloading(true);
      axios.post(facebookauthentication,{name:response.name
        ,email:response.email
        ,pic:response.picture && response.picture.data?response.picture.data.url:null
        ,id:response.id
        ,accesstoken:response.accessToken
      })
      .then(res=>{
        console.log(res);
        if (res.data.success) {
          console.log("Do token thing");
       
          authservice.setfacebooktoken(res.data.token);
          globalContext.setcurrentuser(authservice.getCurrentUser());
          Setshowloading(false);
          var {usertype} = authservice.getCurrentUser();
          usertype ? navigate('/') : navigate('/app/knowyou');
        }
        else{
          Setshowloading(false);

          //navigate('/')
        }
      }).catch(err=>{ console.log(err); navigate('/')})    
      
        } else {
          Setshowloading(false);
      //Show failed login
    }

  }
if(showloading){
  return(
  <div className='mt-4'>

   <p className='text-center'> Please wait...</p>
  </div>
  )
}
else{
  var ua = navigator.userAgent || navigator.vendor || window.opera;  
  var isInstagram = (ua.indexOf('Instagram') > -1)|| (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV")>-1)? true : false;
  if(isInstagram){
    return (

      <div className='mt-4'>
  
      <FacebookLogin
      appId={fblive}
      autoLoad={true}
      cssClass='facebookloginbtnholder'
      fields="name,email,picture"
      callback={responseFacebook}
      onClick={()=>Setshowloading(true)}
      icon="fa-facebook"
      isMobile={false}
      render={renderProps => (
        <button onClick={e => {
            
            console.trace();
            }
        }>Login via FB</button>
    )}
    />
     </div>
    );
  }
  else{
  return (

    <div className='mt-4'>

    <FacebookLogin
    appId={fblive}
    autoLoad={false}
    cssClass='facebookloginbtnholder btn text-truncate text-light'
    fields="name,email,picture"
    callback={responseFacebook}
    onClick={()=>Setshowloading(true)}
    icon="fa-facebook"
    isMobile={false}
    render={renderProps => (
      <button onClick={e => {
          
          console.trace();
          }
      }>Login via FB</button>
  )}
  />
   </div>
  );}
}
}


export default GetFacebookLogin;