import '../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';


function Story(props) {
      
        return (
                <div className='content-80 mb-5'>
                <div className='container'>
                  <div className='row' >
                    <div className='col-sm-12 mt-4'>
                        <center>
                          <p className='hero2'>Story</p>

                        </center>

                    </div>
                  </div>
                  <div className='row'>
                  <div className='col-sm-12 col-md-4'>
                      <p className='d-md-none small gray'> Story is after image</p>
                      <img className='img-fluid' src='https://api.triptopper.com/images/buzo.jpeg'></img>

                      </div>
                      <div className='col-sm-12 col-md-8 p-5'>
                        <p className='fs-5'><span className='fs-4 fw-bold'>So [BuŽo]</span> is a very special person and loves to travel and covers most of his bucket list. </p>
                        <p> - He is pissed! See sometimes he struggles to find accomodation because of rules and regulation around his kind of folks.</p>
                        <p className='fs-4'> Why can't we just get a simple travel plan instead of researching so long on COM-BHOW-TER? - said [BuŽo]</p>
                        <p className='fs-4'> Okay - I Said</p>
                        <p className='fs-4'>We started doing usual - google, paper writing, excel, visiting numerous travel sites and packages, asking friends blah blah !!</p>
                        <p className='fs-4'>It costed us good amount of time and we missed our regular park walk too,[BuŽo] is not happy of this trouble.</p>
                        <p className='fs-4'>I don't got too much time like you human, I need to save time on planning and rather enjoy travelling - said [BuŽo] </p>
                        <p className='fs-4 fw-bold'>Well he is right! Let's save time on planning and enjoy your precious time on experiences - Message to humans!</p>
                        <p className='fs-4'> Join the movement, share your travel experience like never before - <Link to="/app/signin">Let's liberalize travel experiences</Link></p>

                      </div>
   
                  </div>


                  <div className='row mb-5'>
                    
                      <p className='fs-5'>If you have a similar planning stuggle story with your loved ones [Friends,Family,Furr friends, etc.] write to us at <span className='fw-bold underline'>founder@triptopper.com</span>,  we would love to publish it ! </p>
                  </div>
                  <br></br>

                </div>
              </div>
        ) 
      
}

export default Story;
