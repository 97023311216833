import '../App.scss';
import React, {useState } from 'react';
import {Link,useNavigate} from 'react-router-dom';
import ico_edit from '../icons/edit.png';
import ico_share from '../icons/share.png';

const PublicItinView = (props)=>{
    const navigate = useNavigate();
    const [itins,SetItins] = useState(props.itins);

      return(

        itins.map((itin,index)=>{
            return (
              <div className={'col-sm-12 mb-4 shadow border rounded '+itin.themename} key={itin._id.toString()}>
                <div className='mt-2' >
                  <div className='selectable-div' onClick={()=>{navigate("/i/"+itin.nanoid)}}>
                    <div className='mt-2 p-2'>
                      <p className='fs-5 fw-bold nodecor' to={'/i/'+itin.nanoid}> {itin.title} </p>
                      <p className='h6 mt-2'> {itin.days.length} day</p>
                      <p className='small'>{itin.location.fulladdress}</p>
                      {/*<span className=''>435 visits</span> <span className=' p-3 m-2'> 127 shares</span>*/}
                    </div>
                    {/* 
                    <p className={'height20 mb-0 border-bottom rounded-bottom-3 '+(itin.themename?itin.themename:'theme-gray')}></p>
                    */}
                  </div>
                </div>
              </div>
            )
        }))
   
    
}
export default PublicItinView;
