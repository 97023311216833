import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';

import ico_fb from '../icons/facebook.png';
import ico_linkedin from '../icons/linkedin.png';
import ico_whatsapp from '../icons/whatsapp.png';
import ico_twitter from '../icons/twitter.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';


function ModalSetLocation(props) {
   //backdrop="static"
  
    return (
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        >
        <Modal.Header closeButton>
          
                <p className='hero4 mt-3'>Set Location</p>
                
                
        </Modal.Header>
        <Modal.Body className="show-grid p-3 text-center">
           
        
         
           
          
         
        
        </Modal.Body>
       
      </Modal>
    );
  }
  export default ModalSetLocation;
