import '../../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef} from 'react';
import Autocomplete from 'react-google-autocomplete'
import {Link,useLocation,useNavigate} from 'react-router-dom';
import CreateItinerarydayform from '../CreateItinerarydayform';
import { useParams } from 'react-router-dom';
import Authservice from '../Authservice';
import { addaday,appaddress,deleteaday, getmyitin, localappaddress, setitintheme, updateitintitle, deleteitin, setbgimage, setleadson, saveactivityorder, savedayorder, addevent } from '../../api/itin';
import EditDay from './EditDay';
import EditTitle from '../Creater/EditTitle';
import ico_add from '../../icons/add.png';
import ico_share from '../../icons/share.png';
import ico_dots from '../../icons/dots.png';
import ico_theme from '../../icons/theme.png';
import ico_preview from '../../icons/eye.png';
import ico_pdf from '../../icons/pdf.png'; 
import ico_tutorial from '../../icons/bookmarked.png'; 
import SetThemeModal from './SetThemeModal';
import PreviewModal from './PreviewModal';
import Button from 'react-bootstrap/Button';
import SharerModal from '../SharerModal';
import SelectImageModal from './SelectImageModal';
import { toPng, toJpeg, toCanvas } from 'html-to-image';
import jsPDF from 'jspdf';
import { DownloadPdf } from '../PdfService';
import LoadingOverlay from '../LoadingOverlay';
import SubscriptionModal from './SubscriptionModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DayDragModal from './DayDragModal';
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import ico_drag from '../../icons/drag.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const steps = [
  {
    content: <h2>Let's begin our journey!</h2>,
    // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'center',
    target: 'body',
  },
  {
    target: ".stepfourteen",
    content: "See all your itinerary on Home",
    placement: "right"
  },
  {
    target: ".stepfifteen",
    content: "Create new itinerary",
    placement: "right"
  },
  {
    target: ".stepsixteen",
    content: "See all your leads in Inbox",
    placement: "right"
  },
  {
    target: ".stepone",
    content: "Add a day to the itinerary",
  },
  {
    target: ".steptwo",
    content: "Set the theme for the itinerary",
  },
  {
    target: ".stepthree",
    content: "Preview how your itinerary will look to customers",
  },
  {
    target: ".stepfour",
    content: "Download your itinerary as a PDF",
  },
  {
    target: ".stepfive",
    content: "You can turn on or off leads for your itinerary",
  },
  {
    target: ".stepsix",
    content: "Share the itinerary",
  },
  {
    target: ".stepseven",
    content: "Change title of the itinerary",
  },
  {
    target: ".stepeight",
    content: "Drag and rearrange day using this",
    placement: "top"
  },
  {
    target: ".stepnine",
    content: "Add a place from google to your day",
    placement: "top"
  },
  {
    target: ".stepten",
    content: "Add a heading to your day",
    placement: "top"
  },
  {
    target: ".stepeleven",
    content: "Add a image to your day",
    placement: "top"
  },
  {
    target: ".steptwelve",
    content: "Add a new day to your itinerary",
    placement: "top"
  },
  {
    target: ".stepthirteen",
    content: "Add a new day at last to your itinerary",
  },
];

function Myitin() {
  const location = useLocation();
  const navigate = useNavigate();
  const newdayRef = useRef(null);
  const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyDRQlEEA7ToMtEdj3l-BJSGJUfYQTdwl28';
  const [itin,Setitin] = useState(null)
  const params = useParams();
  const id = params.id;
  const [thememodalShow,SetthememodalShow] = React.useState(false);
  const baseurl = "https://triptopper.com/";
  const [SharerUrl,SetSharerUrl] = useState(baseurl);
  const [SharerText,SetSharerText] = useState("Check this out!");
  const [pdfDownloading, setpdfDownloading] = useState(false);
  
  const [SharermodalShow,SetSharermodalShow] = useState(false);
  const [UnauthorizedAccess,SetUnauthorizedAccess] = useState(false);
  const [UnauthorizedMsg,SetUnauthorizedMsg] = useState("");
  const [imageModalShow,SetimageModalShow] = React.useState(false);

  const [OpenPreviewModal,SetOpenPreviewModal] = useState(false);
  const [OpenSubscriptionModal, setOpenSubscriptionModal] = useState(false); 
  const [OpenDayDragModal, setOpenDayDragModal] = useState(false); 
  const [dayIndex, setDayIndex] = useState(); 
  const [showTutorial, SetShowTutorial] = useState(false);

  const imageRef = useRef([]);

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    // console.log(action, index, status, type);
 
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour

    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      SetShowTutorial(false);
    } else if([ACTIONS.CLOSE].includes(action)){
      
    }
  }

  //not in use
  const onClickDownload = () => {
    itin.days.map((item,index)=>{
      downloadImage(imageRef.current[index], index)
     })
  }
  const downloadImage = (ref, index) => { 
    if (ref === null) {
      return
    }

    toJpeg(ref, { cacheBust: true, backgroundColor: '#FFFFFF' })
      .then((dataUrl) => {
        const link = document.createElement('a');
        var day = index+1;
        link.download = 'Day'+day+'.jpeg'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const pdfDownload = () => { 
    setpdfDownloading(true);
    DownloadPdf(itin._id);
    
    setTimeout(() => {
    setpdfDownloading(false);
    }, 1500);
  }

  const handleThemeChange=(themename)=>
  {
    axios.post(setitintheme,{itinid:itin._id,themename:themename})
    .then((response) => {
        if(response.data.success){
            Setitin({...itin, themename:themename });
        }
      }).catch(err => {
        console.log("Error setting theme!");
    });
  }
  
  const OpenSharerModal= (url,text)=>{
    SetSharerUrl(url);
    SetSharerText(text);
    SetSharermodalShow(true)
}
  useEffect(()=>{
          {/*
          window.gtag('event', 'page_view', {
              page_title: "MYITIN",
              page_path: location.pathname + location.search,
              page_location: window.location.href
          })
        */}

          if(id){var headertoken = Authservice.getheadertoken();
          axios.defaults.headers.common['Authorization'] =    
                'Bearer '+ headertoken;
          const url = getmyitin+id;
          axios.get(url).then(res=>{
            if (res.data.success && res.data.itin) {
              var _days = res.data.itin.days;
              _days.sort((a,b) => a.order - b.order);
              Setitin({...res.data.itin, days: _days});
              console.log(itin)
            }
            else{
              SetUnauthorizedAccess(true);
              SetUnauthorizedMsg(res.data.msg);

            }
        }).catch(err=>{
        })}else{}
      },[])

  const [SelectedDay,SetSelectedDay] = useState(null);

  
  //const newdayemptyobj = {details:''};
  //const loc_restriction = 'in';
  const  handlelocationselected =(selectedcity)=>{
    if(selectedcity!= null && selectedcity.geometry!=null)
    {
      console.log(selectedcity)
      /*
      [IMPORTANT: Get the city name, state etc  from google reponse using below code]
      [Test Case:] This will break if new delhi is selected
      for (var i = 0; i < place.address_components.length; i++) {
        for (var j = 0; j < place.address_components[i].types.length; j++) {                 
              if (place.address_components[i].types[j] == "locality") {$("#city").val(place.address_components[i].long_name);
        }
        */
      const city = selectedcity.address_components[0]['short_name'];
      const state = selectedcity.address_components[2]['short_name'];
      const country = selectedcity.address_components[3]['short_name'];
      const placeid = selectedcity.place_id;
      const fulladdress = selectedcity.formatted_address;
      const locdata = selectedcity.geometry.location;
      const latitude = locdata.lat();
      const longitude = locdata.lng();

        
      var _loc =  { 
        "type": "Point",
        "coordinates": [longitude,latitude]
      };
      var _location = {
        loc:_loc,city:city,state:state,
        country:country,gid:placeid
        ,fulladdress:fulladdress};

        
    
        Setitin({...itin, location:_location});
      

    }
  }

  
  function handleChange(e)
  {
    e.preventDefault();
  }

  function handleInputChange(e){
    SetSelectedDay({...SelectedDay, details:e.target.value});
  };

  const updateDayLocation = (id,location)=>{
    console.log('update day location');
    const index = itin.days.findIndex(d=>d.day._id === id);
    var upd_days = itin.days;
    upd_days[index].day.location = location;
    //const updatedDays = itin.days.map(d=>d._id ===id?{...d,location:location}:d);
    Setitin({...itin,days:upd_days});
    if(index === 0){
    Setitin({...itin,location:location})}
  }

  const updateWTS = (id,location)=>{
    console.log('Update WTS ');
    const index = itin.days.findIndex(d=>d.day._id === id);
    var upd_days = itin.days;
    upd_days[index].day.wts = location;
    //const updatedDays = itin.days.map(d=>d._id ===id?{...d,location:location}:d);
    Setitin({...itin,days:upd_days});
  }

  const setitinlocation = (location)=>{
    Setitin({...itin,location:location});
  }
  // Days related code

  const addDay = (prevdayid)=>
  {
    const cls = document.querySelectorAll('.flash');
    cls.forEach(c => {
      c.classList.remove('flash');
    });

    var lastdayid, _toorder;
    var _fromorder = itin.days.length>0 ? (itin.days[itin.days.length-1].order + 1) : 1;

    if(prevdayid){
      lastdayid = prevdayid;
      var d = itin.days.find(d => d.day._id === prevdayid);
      _toorder = d.order+1;
    } else{
      lastdayid = itin.days.length>0?itin.days[itin.days.length-1].day._id:null;
      _toorder = itin.days.length>0 ? (itin.days[itin.days.length-1].order + 1) : 1;
    }
  
    axios.post(addaday,{itinid:itin._id,lastdayid:lastdayid,toorder:_toorder, fromorder: _fromorder})
    .then((response) => {
        if(response.data.success){
            var newday =  response.data.day;
            var _days = itin.days.map(d => {
              if(d.order >= newday.order){
                  d.order += 1;
              };
              return d;
            })
            _days.push(newday);
            _days.sort((a,b) => a.order - b.order);
            Setitin({...itin, days:_days });

            setTimeout(() => {
              var index = _days.findIndex(d => d.day._id === newday.day._id);

              newdayRef.current.scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'});
              const span = imageRef.current[index];
              span.classList.add("flash");
            },100)
        }
      }).catch(err => {
    });
   
  }

  const dayCancel = (index) =>
  {
      var dayname = index+1
      console.log("Day cancel is called from day - "+ dayname)
      var _days = itin.days;
      
      _days[index].open = false;
      
      Setitin({...itin, days:_days });
  }

  const daySave = (item,index)=>
  {
    axios.post('/itin/add-day',{itinid:itin._id,selectedday:item})
      .then((response) => {
          const updatedDay = response.data.day;
          if(updatedDay){
            if(item._id){
              const _days = itin.days.map(x => (x._id === item._id ? { ...x, details:updatedDay.details,isedit:false} : x));

              Setitin({...itin, days:_days});
            }
            else{
              var _days = itin.days;
              
              if(_days[index].isnew){_days.splice(index, 1)}
              _days.push(updatedDay);
              Setitin({...itin, days:_days});
            }
          }
        }).catch(err => {
        console.error(err);
      });
  }

  const dayEdit = (index)=>{
    var _days = itin.days;
    _days[index].open = true;
    Setitin({...itin, days:_days });

  }

  const dayDelete = (id)=>{
    const confirmBox = window.confirm("Do you really want to delete this day?")
    if(confirmBox === true) {
    var _toorder = itin.days.length>0 ? (itin.days[itin.days.length-1].order) : 1;

    var d = itin.days.find(d => d.day._id === id);
    var _fromorder = d.order;

    axios.post(deleteaday,{itinid:itin._id,_id:id, fromorder: _fromorder, toorder: _toorder})
    .then((response) => {
        if(response.data.success){
          /*
            var _days = itin.days;
            const index = _days.findIndex(a => a._id === id);
            _days.splice(index,1);
            Setitin({...itin, days:itin.da });
          */
            var _days = itin.days.filter((d) => d.day._id !== id)
            .map(d => {
               if(d.order > _fromorder){
                  d.order -= 1;
                }
                return d;
            })
            Setitin({...itin, days: _days });
        }
      }).catch(err => {
        console.log(err);
    });
  }
  }

  const titleSave=(title)=>{
  axios.post(updateitintitle,{_id:itin._id,title:title})
  .then((response) => {
      console.log(response.data);
      if(response.data.success){
       Setitin({...itin,title:title});
      }
    }).catch(err => {
    console.error(err);
  });

  }

  const setBgImage=(imagedata)=>{
      if(imagedata){
        var bgimage = imagedata.img;
        axios.post(setbgimage,{_id:itin._id,bgimage:bgimage, oldbgimage:itin.bgimage})
        .then((response) => {
            if(response.data.success){
            Setitin({...itin,bgimage:bgimage});
            }
          }).catch(err => {
          console.error(err);
        });
      }
    }

  const handleDeleteConfirmTrue = (itinid) =>{
    const confirmBox = window.confirm("Do you really want to delete this itinerary?")
    if(confirmBox === true) {
      console.log(itinid);
      axios.post(deleteitin,{_id:itinid}).then(res=>{
        if (res.data.success) {
          navigate('/');
        }
        else{alert("Unable to delete Itinerary, please try again!")}
      }).catch(err=>{
      alert("[Error deleting itinerary]")
      console.log(err);
      })
    }
  }

  const updateLeadson = (success) => {
      if(success){
        onSwitchAction();
      }
  }

  const onSwitchAction = () => {
    // check for users for pro accounts
    var currentUser = Authservice.getCurrentUser();
    if(currentUser.usertype === 'AGNT' || currentUser.usertype === 'IFNR'){ 
      var leads = !itin.leadson;
      var headertoken = Authservice.getheadertoken();
            axios.defaults.headers.common['Authorization'] =    
                  'Bearer '+ headertoken;
            axios.post(setleadson,{_id:itin._id,leadson:leads})
            .then(res=>{
              if (res.data.success) {
                Setitin({...itin,leadson:leads});
              }
          }).catch(err=>{
            console.log(err);
          })
    } else{
        // setOpenSubscriptionModal(true);
    }
  };

  const addActivityToDay = (day, item,label)=>{
    var headertoken = Authservice.getheadertoken();
    axios.defaults.headers.common['Authorization'] =    
          'Bearer '+ headertoken;
    
    if(item){
      axios.post(addevent,{dayid:day._id,activity:item,label:label,order:day.activities.length+1})
        .then(res=>{
        
          if (res.data.success && res.data.activity) {

              var _activities = day.activities;             
              _activities.push(res.data.activity);
              
              var _days = itin.days.map(d => {
                if(d.day._id === day._id){
                  d.day.activities = _activities;
                }
                return d;
              })
              Setitin({...itin,days:_days});

              return true;  
    
          }
          else{
            return false;
          }
        }).catch(err=>{
          return false;
        })
    }
}

const updateActivityState = (dayid, _activities) => {
  var _days = itin.days.map(d => {
    if(d.day._id === dayid){
      d.day.activities = _activities;
    }
    return d;
  })
  Setitin({...itin,days:_days});
}

  const removeFromList = (list, index) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };
  
  const addToList = (list, index, element) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
   
    if(result.type === 'day'){
      if(result.source.index === result.destination.index) return;

      var items = Array.from(itin.days);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      items = items.map((item,index) => {
        return {...item,
          order:index+1
        }
      });
      Setitin({...itin, days: items});
      setOpenDayDragModal(false);

      var headertoken = Authservice.getheadertoken();
      axios.defaults.headers.common['Authorization'] = 'Bearer '+ headertoken;
      axios.post(savedayorder,{
        _id:itin.days[result.source.index].day._id, 
        itinid:itin._id, 
        fromorder:itin.days[result.source.index].order, 
        toorder:itin.days[result.destination.index].order
      })
        .then(res=>{
          if (res.data.success) {

          }
        }).catch(err=>{
          Setitin({...itin, days:itin.days});
        })


      // imageRef.current[result.destination.index].scrollIntoView({block: 'end', behavior: 'smooth'});
      // const span = imageRef.current[result.source.index];
      // span.classList.add("flash");
    } 
    else{
        const {source, destination} = result;

        if(source.droppableId === destination.droppableId && source.index === destination.index) return;

        const Sday = source.droppableId.split('-')[1];
        const Dday = destination.droppableId.split('-')[1];
        const _Days = [...itin.days];

        const sourceList = _Days[Sday].day;
        const [removedElement, newSourceList] = removeFromList(
          sourceList.activities,
          result.source.index
        );
        
        _Days[Sday].day.activities = newSourceList;
        const destinationList = _Days[Dday].day;
        _Days[Dday].day.activities = addToList(
          destinationList.activities,
          result.destination.index,
          removedElement
        );

        _Days[Sday].day.activities = _Days[Sday].day.activities.map((item,index) => {
          return {...item,
            order:index+1
          }
        });
        _Days[Dday].day.activities = _Days[Dday].day.activities.map((item,index) => {
          return {...item,
            order:index+1
          }
        });

        Setitin({...itin, days:_Days});

        var fromDayId, fromAct, toDayId, toAct;
        if(_Days[Dday].day._id === _Days[Sday].day._id){
          fromDayId = null;
          fromAct = null;
          toDayId = _Days[Dday].day._id;
          toAct = _Days[Dday].day.activities;
        } else{
          fromDayId = _Days[Sday].day._id;
          fromAct = _Days[Sday].day.activities;
          toDayId = _Days[Dday].day._id;
          toAct = _Days[Dday].day.activities;
        }

        var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] = 'Bearer '+ headertoken;
        axios.post(saveactivityorder,{fromdayid:fromDayId, fromactivities:fromAct, todayid:toDayId, toactivities:toAct})
          .then(res=>{
            if (res.data.success ) {}
          }).catch(err=>{
            Setitin({...itin, days:itin.days});
          })
      
      }
  }

  if(pdfDownloading){
    return(
      <LoadingOverlay />
    )
  }

  if(UnauthorizedAccess){
    return (  
    <div className=''><br></br><center><p className='mt-5 p'>{UnauthorizedMsg}</p></center></div>
    )
  }
  else if(itin){
  
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Joyride 
          callback={handleJoyrideCallback}
          continuous
          // stepIndex={0}
          // hideCloseButton
          run={showTutorial}
          scrollToFirstStep
          // showProgress
          showSkipButton
          steps={steps}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              overlayColor: "rgba(0, 0, 0, 0.5)",
              primaryColor: "#333",
              textColor: "#333",
              zIndex: 10000,
            },
          }}
          />
      <div className='p-2'>
        <SetThemeModal itin={itin} set={handleThemeChange} show={thememodalShow}
          onHide={() => SetthememodalShow(false)}/>
        <SharerModal SharerUrl={SharerUrl} text={SharerText} show={SharermodalShow}
          onHide={() => SetSharermodalShow(false)}/>
        <PreviewModal type="ITIN" nanoid={itin.nanoid} show={OpenPreviewModal} onHide={() => SetOpenPreviewModal(false)}/>
        <SelectImageModal imagetype='bgimage' show={imageModalShow} handleSearchImageData={setBgImage} onHide={() => SetimageModalShow(false)}/>

        {/* SubscriptionModal modal */}
        {/* <SubscriptionModal show={OpenSubscriptionModal} onHide={() => setOpenSubscriptionModal(false)} updateFunc={updateLeadson}/> */}
        <DayDragModal show={OpenDayDragModal} onHide={() => setOpenDayDragModal(false)} itin={itin} dayIndex={dayIndex}/>
      
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-10 offset-md-1 sticky-top bg-white border border-1 radius-bottom p-3 mb-3 pt-4'>
                  <Button className='stepone' variant="btn fs-6 m-1" onClick={()=>addDay()}><img className='i-sm me-2' src={ico_add}></img><span className='d-none d-md-inline'>Add a day</span> <span className='d-block d-md-none custom-small'>Add a day</span> </Button>

                  {/* Theme button */}
                  {/* <Button className='steptwo'  variant='btn fs-5 m-2'  onClick={() => SetthememodalShow(true)}><img className='i-sm me-1' src={ico_theme}></img><span className='d-none d-md-inline'>Set theme</span><span className='d-block d-md-none custom-small'>Theme</span></Button> */}

                  <a className='stepthree btn fs-6 m-2' onClick={()=>SetOpenPreviewModal(true)} target="_blank" ><img className='i-sm' src={ico_preview}></img><span className='d-none d-md-inline'> Preview</span><span className='d-block d-md-none custom-small'>Preview</span></a>

                  <a className='stepfour btn fs-6 m-2' onClick={() => pdfDownload()} target="_blank" ><img className='i-sm' src={ico_pdf}></img><span className='d-none d-md-inline'> Download</span><span className='d-block d-md-none custom-small'>Download</span></a>
                  
                  <a className='btn fs-6 m-2' onClick={() => SetShowTutorial(true)} target="_blank" ><img className='i-sm' src={ico_tutorial}></img><span className='d-none d-md-inline'> Tutorial</span><span className='d-block d-md-none custom-small'>Tutorial</span></a>

                  <div className="btn form-switch fs-6 stepfive">
                    <input className="form-check-input ms-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" 
                    onChange={onSwitchAction} 
                    checked={itin.leadson? true : false}/>
                    <label className="form-check-label d-none d-md-inline" htmlFor="flexSwitchCheckDefault"> Leads</label>
                    <label className="form-check-label d-block d-md-none custom-small" htmlFor="flexSwitchCheckDefault">Leads</label>
                  </div>

                  <span className='float-md-end'>
                  <Button className='stepsix' onClick={() => OpenSharerModal("https://triptopper.com/i/"+itin.nanoid,itin.title)} variant='btn m-2 fs-6 fw-bold'><span className='d-none d-md-inline'>Share</span><img src={ico_share} className='i-sm ms-2'></img><span className='d-block d-md-none custom-small'>Share</span></Button>

                  <span className="dropdown">
                  
                  <Button id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" variant='btn'><img className='i-sm' src={ico_dots}></img></Button>

                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a onClick={() => handleDeleteConfirmTrue(itin._id)} className="dropdown-item">Delete</a></li>
                    {/* <li><a onClick={() => onClickDownload()} className="dropdown-item">Download Image</a></li> */}
                  </ul>
                </span>
                  </span>
                  
                
            </div>
          <div className='p-0'>
            <div className={'position-relative col-sm-12 col-md-10 offset-md-1 shadow-sm radius-30 '+ (itin.themename?itin.themename:"theme-gray")
            + " " + (itin.bgimage && "theme-dark")}
             style = {itin.bgimage ?
              {
                height: 300,
              backgroundSize: 'cover',
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55) ), url(${itin.bgimage})`,
              backgroundPosition: 'center',
           } : {}}>
              <div className='p-4 pb-5 mt-4'>
                <EditTitle save={titleSave} title={itin.title}></EditTitle>
                <a className=' small nodecor' target='_blank' href={"https://www.google.com/maps/search/?api=1&query="+itin.location.fulladdress+"&query_place_id="+itin.location.gid}>
                    {itin.location.fulladdress}</a>
                <p className='col-3 fw-bold fs-5 mt-2'>{itin.days?itin.days.length:0} {itin.days && itin.days.length>1?"Days":"Day"}</p>
                <p className='col-3 fs-5 m-0 fw-bold'>
                  {(itin.startdate && new Date(itin.startdate).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }))}
                  </p>
                <span className='col-3 fw-bold'><img className='i-sm me-1' src={ico_preview}></img>{(itin.viewscount?itin.viewscount:0)}</span>
                {/* <span className='col-3 col-sm-1.5 p-2 fw-bold'> By <a hrf="somepage" href="somepage" className='s-3'>@dudeormonk</a></span>*/}
                {/*<span className='col-3 p-2 fw-bold'>{itin.activities.length} activities</span>*/}
              </div>

              <div className="position-absolute bottom-0 end-0">
                <Button size='sm' variant='btn border m-2 text-sm'  onClick={() => SetimageModalShow(true)}><img className='i-xs me-1' src={ico_theme}></img><span className='d-none d-md-inline'>{itin.bgimage ? "Change background" : "Set background"}</span><span className='d-block d-md-none custom-small'>{itin.bgimage ? "Change background" : "Set background"}</span></Button>
              </div>
            </div>
          
            <div className='col-sm-12 col-md-10 offset-md-1 border border-light'>

              {/* <div className=''>
                  {
                    itin.days.map((item,index)=>{
                          return(<div 
                            key={item.day._id.toString()} 
                            ref={ref => {
                            imageRef.current[index] = ref
                          }} >
                          <EditDay updateWTS={updateWTS} updatelocation={updateDayLocation}  
                          ref={newdayRef} setOpenDayDragModal={setOpenDayDragModal} setDayIndex={setDayIndex}
                          delete={dayDelete} theme={itin.themename} item={item.day} index={index} addDay={addDay}></EditDay>
                          </div>)
                      })
                    }
              </div> */}

          <Droppable droppableId="days" type='day'>
                  {(provided) => ( <ul style={{listStyle: 'none'}} {...provided.droppableProps} ref={provided.innerRef}>

                              {
                                itin.days.map((item,index)=>{
                                  return(
                                    <Draggable key={item.day._id.toString()} draggableId={item.day._id.toString()} index={index}>
                                      {(provided) => (
                                      <li className='d-flex mt-4' ref={provided.innerRef} {...provided.draggableProps}>
                                        <div className='mt-2' >
                                        <OverlayTrigger placement="bottom" 
                                        overlay={<Tooltip id="tooltip" className='small'>Drag</Tooltip>}>
                                          <img {...provided.dragHandleProps} src={ico_drag} className={'i-md' + (index === 0 ? ' stepeight' : '')}></img>
                                        </OverlayTrigger>
                                            
                                        </div>
                                        <div className='w-100' key={item.day._id.toString()} ref={ref => {
                                          imageRef.current[index] = ref
                                        }} >
                                        <EditDay updateWTS={updateWTS} updatelocation={updateDayLocation}  
                                        ref={newdayRef} addDay={addDay} addActivityToDay={addActivityToDay} 
                                        updateActivityState={updateActivityState}
                                        delete={dayDelete} theme={itin.themename} item={item.day} index={index} date={itin.startdate}></EditDay>
                                        </div>
                                      </li>)}
                                      </Draggable>
                                  )
                              })
                          }
                      </ul>
                  )}
              </Droppable>
              <div>
              <div className='p-4 text-center mt-2 mb-5 stepthirteen'>
                <button className='btn btn-link nodecor rounded-pill' onClick={()=>addDay()}><span className='h4 gray fw-bolder'> + Add a day</span> </button>

              </div>

              </div>
            </div>
            </div>


                {/*  {itin.days.map((item,index)=>{
                    if(item.open){
                      return(<EditDay  ref={newdayRef} save={daySave} delete={dayDelete} item={item} index={index}></EditDay>)
                    }
                    else if(item._id){
                      return (<EditDay edit={dayEdit} save={daySave} delete={dayDelete} item={item} index={index}></EditDay>)
                    }
                  })
                }*/}
          
          </div>
       
        </div>
      </div>
      </DragDropContext>
    );  
  }
  else
  {
    return ( 
    
    <div className=''><br></br><center><p className='display-5 gray'>Loading....</p></center></div>

    )

  }
}
export default Myitin;
