import React,{useState,useEffect} from 'react';
import '../../App.scss';

import {Link} from 'react-router-dom';



function PendingItinNotification(props) {
    if(props.itins && props.itins.length>0)
    {
    return(
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <center>
          <Link to='/myitins'> You have {props.itins.length} Itinerary pending !</Link>
        </center>
        <button type="button" className="btn btn-close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>
    )
    }
 
}

export default PendingItinNotification;


 
