
import { Link } from "react-router-dom";
const BottomCTAI =()=>{
    return (
        <>
            <div className='row mt-5'>
                <div className='col-sm-12 col-md-8 offset-md-2 mt-4 mb-5'>
                    <center>
                        
                        <br></br>
                        <p className='mb-4'>
                            {/* <Link className='nodecor color-black' to="/app/signin">triptopper.com/[your name]</Link> */}
                            <a className='nodecor color-black' href="https://www.triptopper.com/app/signin" target="_blank">[your name].triptopper.com</a>
                            </p>
                        <p className='small'>Share your travel page with just one link</p>
                        <a href="https://www.triptopper.com/" target="_blank" className='btn btn-dark btn-lg fw-bold '> Claim your name</a>

                        </center>
                </div>
            </div>
        </>
  );
}

export default BottomCTAI;