import jsPDF from 'jspdf';
import Authservice from './Authservice';
import { getmyitin } from '../api/itin';
import axios from 'axios';

const margin = 12;
const activityMargin = 10;
const fontStyle = 'helvetica'; //'Times New Roman' 
const textColor = (43, 44, 44);
const linkColor = '#4169E1';
const noteColor = '#808080'

const noteFont = 10;

const addTextToPdf = (doc, text, xPos, yPos, textsplitby) => {
    var pageHeight = 280;
    var textsplit = 160;
    if(textsplitby){
      textsplit = textsplitby;
    }
    if(yPos > pageHeight){
      doc.addPage();
      yPos = 20;
    };

    var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
    var splittedText = doc.splitTextToSize(text, textsplit); //160
    var lines = splittedText.length;
    var blockHeight = lines * lineHeight;
    doc.text(xPos, yPos, splittedText);
    return yPos + blockHeight;
  }

  const addImageToPdf = (doc, image, xPos, yPos, height, width) => {
    var pageHeight = 290 - height;
    if(yPos > pageHeight){
      doc.addPage();
      yPos = 20;
    };

    doc.addImage(image+'?not-from-cache-please', 'JPEG', xPos, yPos, width, height);

    doc.setLineWidth(3);
    doc.setDrawColor('#FFFFFF') //#D3D3D3 //808080
    doc.roundedRect(xPos, yPos, 125, 85, 3, 3);
    return yPos + height + 6;
  }

  const addTextLinkToPdf = (doc, text, xPos, yPos, link) => {
    var pageHeight = 280;
    if(yPos > pageHeight){
      doc.addPage();
      yPos = 20;
    };

    var location_icon = 'https://cdn-icons-png.flaticon.com/512/2838/2838912.png';
    doc.addImage(location_icon, 'JPEG', xPos, yPos-5, 6, 6);
    doc.textWithLink(text,xPos+8,yPos,{
        url: link
      });
    
    var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
    return yPos + lineHeight;
  }

const generatePdf = (itin) => { 
    if(itin){
      var doc = new jsPDF()
      var yPos = 10;
      var xPos = 30;
  
      //title front page
      
      // doc.setFillColor(232, 235, 232);
      // doc.rect(0,0,210,300,'F');
      doc.addImage(itin.bgimage+'?not-from-cache-please','JPEG', 0, 0, 210, 140);
      // doc.setLineWidth(6);
      // doc.setDrawColor('#FFFFFF') 
      // doc.roundedRect(25, 25, 165, 115, 7, 7);

      doc.setFont(fontStyle, 'bold');
      doc.setFontSize(50);
      doc.setTextColor(textColor)
      var y = addTextToPdf(doc, itin.title, 30, 170);
  
      doc.setFont(fontStyle, 'italic');
      doc.setFontSize(22);
      doc.setTextColor('#616161')

      //underline
      const textWidth = doc.getTextWidth(itin.location.fulladdress);
      doc.setDrawColor('#616161')
      doc.setLineWidth(0.1);
      doc.line(38, y+2, 38 + textWidth, y+2);

      y = addTextLinkToPdf(doc, itin.location.fulladdress, 30, y, "https://www.google.com/maps/search/?api=1&query="+itin.location.fulladdress+"&query_place_id="+itin.location.gid);
  
      doc.setFont(fontStyle, 'normal');
      doc.setFontSize(32);
      doc.setTextColor(textColor)
      var y = addTextToPdf(doc, (itin.days.length) + (itin.days.length>1?" Days":" Day"), 30, y+10); 
  
      doc.setLineWidth(0.1);
      doc.setDrawColor(textColor)
      doc.roundedRect(80, y-3, 40, 12, 2, 2);
      doc.setFontSize(16);
      doc.setFont(fontStyle, 'normal');
      doc.textWithLink('View on web',84, y+5, {url: "https://triptopper.com/i/"+itin.nanoid});
      doc.addPage();
  
      itin.days.map((item, index) => {
        var day = item.day;
        //add day
        yPos += 10;
        doc.setFont(fontStyle, 'bold');
        doc.setFontSize(34);
        doc.setTextColor(textColor)
        yPos = addTextToPdf(doc, "Day " + (index+1), xPos, yPos);
  
        doc.setFont(fontStyle, 'normal');
        doc.setFontSize(13);
        doc.setTextColor(linkColor)
        yPos = addTextLinkToPdf(doc, day.location.fulladdress, xPos, yPos, "https://www.google.com/maps/search/?api=1&query="+day.location.fulladdress+"&query_place_id="+day.location.gid);
        yPos += margin;
  
        //add activities
        day.activities.map((acti,index) => {
          if(acti.label === 'image'){
            yPos = addImageToPdf(doc, acti.img, xPos+15, yPos, 85, 125);
            if(acti.note){
              doc.setFont(fontStyle, 'normal');
              doc.setFontSize(noteFont);
              doc.setTextColor(noteColor)
              yPos = addTextToPdf(doc, acti.note, xPos+15, yPos, 130);
            }
            yPos += activityMargin;
          }else if(acti.label === 'heading'){
            doc.setFont(fontStyle, 'bold');
            doc.setFontSize(18);
            doc.setTextColor(textColor)
            yPos = addTextToPdf(doc, acti.heading, xPos+8, yPos);
            if(acti.note){
              doc.setFont(fontStyle, 'normal');
              doc.setFontSize(noteFont);
              doc.setTextColor(noteColor)
              yPos = addTextToPdf(doc, acti.note, xPos+8, yPos-1, 160);
            }
            yPos += activityMargin;
          }else if(acti.label === 'place'){
            doc.setFont(fontStyle, 'normal');
            doc.setFontSize(18);
            doc.setTextColor(linkColor)
            yPos = addTextLinkToPdf(doc, acti.activity.name, xPos+9, yPos, "https://www.google.com/maps/search/?api=1&query="+acti.activity.fulladdress+"&query_place_id="+acti.activity.gid, true);
            doc.setLineWidth(0.1);
            doc.setDrawColor('#D3D3D3') // f8f9fa
            doc.roundedRect(xPos+4, yPos-16, 160, 13, 1, 1);
            yPos += 2;
            if(acti.note){
              doc.setFont(fontStyle, 'normal');
              doc.setFontSize(noteFont);
              doc.setTextColor(noteColor)
              yPos = addTextToPdf(doc, acti.note, xPos+8, yPos, 160);
            }
            yPos += activityMargin;
          }
        })
        doc.setLineWidth(0.1);
        doc.setDrawColor('#D3D3D3');
        doc.line(0, yPos, 300, yPos);
        yPos += margin;
      });
      doc.save(itin.title);
    } else {
      alert('Unable to download pdf. Try again!')
    }
  
  }

  export const DownloadPdf = (id) => {

    if(id){var headertoken = Authservice.getheadertoken();
      axios.defaults.headers.common['Authorization'] =    
            'Bearer '+ headertoken;
      const url = getmyitin+id;
      axios.get(url).then(res=>{
        if (res.data.success && res.data.itin) {
          generatePdf(res.data.itin);
        }
        else{

        }
    }).catch(err=>{
      console.log(err)
    })}
    else{alert('Unable to download pdf. Try again!')}
  }