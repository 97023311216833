import '../App.scss';
 
import React from 'react';
import { Link } from 'react-router-dom';
import logo_sm from '../logo_sm.png';

function Footer() {
  return (
    <footer className="mainfooter" role="contentinfo">
    <div className="container">
      <div className="row p-4">
      <div className="col-md-4 col-sm-12">
          <div className="text-center">
            <p className='mb-5 d-sm-none'></p>
          </div>
        </div>
      <div className="col-md-4 col-sm-12">
          <div className="text-center">
            <h3 className='fw-bold'>About</h3>
            <ul className="list-unstyled">
              {/*<li><Link to="/app/story">Story</Link></li>*/}
              <li><Link to="/app/termsofuse">Terms of use</Link></li>
              <li><Link to="/app/privacypolicy">Privacy policy</Link></li>
            
            </ul>
          </div>
        </div>
      </div>

    <br></br>
    <br></br>
    <div className='row d-none'>
      <div className="col-md-12 col-sm-12">
        <center>
          <h4>Follow Us</h4>
              <ul className="social-network social-circle">
              <li><a href="https://www.instagram.com/thetriptopper" target='_blank' className="" title="Instagram"></a></li>
               <li><a href="https://www.instagram.com/thetriptopper" target='_blank' className="" title="Instagram"></a></li>
              </ul>
        </center>				
      </div>
    </div>
    <br></br>
    <div className="row mb-4">
      <div className="col-md-12 copy">
        <p className="text-center custom-small">&copy; Copyright 2023-2025 | TripTopper.  All rights reserved.</p>
      </div>
    </div>


  
  
    </div>
  </footer>
  );
}

export default Footer;
