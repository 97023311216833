import '../../App.scss';
 
import React from 'react';
import CallToAction from '../CallToAction';


import {Link} from 'react-router-dom';

import image1  from '../../image1.png'
import image2 from '../../image2.png'
import image3 from '../../image3.png'
import ico_traveller from '../../icons/traveler.png';
import ico_reach from '../../icons/reach.png';
import ico_client from '../../icons/handshake.png';
import ico_manage from '../../icons/manage.png';

import AnalyticsEventTrackerCreator from '../AnalyticsEventTracker';
import ReactGA from "react-ga4";
import Faq from '../Faq';
//const TRACKING_ID = "G-9GSNK889ZV"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);
function Index() {
  
  const gaEventTracker = (label)=>{
    ReactGA.event('lan_page_pro',{
      category: 'lan_page_pro',
      action: 'click',
      label: label
    });
  }

  return (
  <>
    <div className='bg-light pb-5'>
      <div className='glass'>
        <div className="container mb-3">
          <br></br>
          <div className='row align-items-center'>

            <div className='animated animatedFadeInUp fadeInUp col-sm-12 text-center'>

              {/*<p className='hero1 mt-5'>Create interactive <span className=''>travel itineraries,</span></p>*/}

              <p className='hero1 mt-5 text-uppercase'><span className=''>Grow</span> your travel business </p>
              
 

            </div>
            <div className='col-sm-12 text-center'>

            </div>

            
          </div>
        </div>
        <div className=' pt-2 pb-5'>
          <div className='container'>
            <div className="row mt-4">
              <div className="col-sm-4 p-2 text-center">
                <div className=' p-2 bg-white-transparent shadow-sm'>
                <img src={ico_reach}></img>
                <p className='h4 fw-bold mt-3'>Increase your reach</p>
                <br></br>
                <p>Create beautiful shareable itineraries</p>
                <p>Display your itineraries on triptopper marketplace</p>

                </div>

              </div>

              <div className="col-sm-4 p-2 text-center">
                <div className=' p-2 bg-white-transparent shadow-sm'>
                <img src={ico_client}></img>

                <p className='h4 fw-bold mt-3'>Convert more clients</p>
                <br></br>
                <p>Get clients queries directly in your inbox</p>
                <p>Don't compete for unneccessary leads</p>

                </div>

              </div>

              <div className="col-sm-4 p-2 text-center">
                <div className='p-2 bg-white-transparent shadow-sm'>
                <img src={ico_manage}></img>

                <p className='h4 fw-bold mt-3'>Manage with ease</p>
                <br></br>
                <p>Modern easy to use CRM</p>
                <p>Travel buisness management at one place</p>

                </div>

              </div>
              
            </div>
          </div>
          <div className='text-center mt-4'>
          <button className='btn btn-primary fw-bold fs-4 rounded-pill pe-5 ps-5 pt-3 pb-3'> Get Started</button>

          </div>
        </div>
        </div>
    </div>
     
    {/* Whats in for you! */}
    <div className=' bg-gray sticky-top pt-5 pb-5'>
      
      <div className='container'>
          <br></br>
          <div className='row'>
              <div className='col-md-8'>

                <br></br>
                <br></br>
                <div className='row'>
                  <div className='col-2'><span className='hero3'>1.</span></div>
                  <div className='col-10'>
                    <p className='hero3'>Create beautiful itinerary</p>
                    <p className='fs-4'>Easy to use tool to create beautiful shareable itineraries faster.</p>
                    <p className='fs-4'> Add themes, tag places directly from Google.</p>
                    <p className='fs-4'>Generate a travel page for yourself.</p>
                    <p className='fs-5'>triptopper.com/[your name]</p>

                    </div>
                </div>
                
              </div>
              <div className='col-md-4 col-sm-3'>
                <br></br>
                <div className=' p-3'>
                  <img alt='' src={image1} className='img-fluid'></img>
                  {/*<CallToAction/>*/}

                </div>

              </div>
          </div>
      

      </div>
    </div>


    <div className=' bg-yellow sticky-top pt-5'>
      <div className='container content-40 sticky-top'>
          <br></br>
          <div className='row align-items-center'>
          <div className='col-md-6 order-md-2'>

            <br></br>
            <br></br>

            <div className='row'>
              <div className='col-2'><span className='hero3'>2.</span></div>
              <div className='col-10'><p className='hero3'>Just one link to broadcast your travel experiences</p>
              <p className='fs-4'> Get your personalised @handle and use it as your link in bio.</p>
              <p className='fs-4'> Freedom to share your travel experience across web. </p>
              <p className='fs-4'> One link to broadcast your travel experience.</p>
              <br></br>
              <p></p>
              </div>
              
            </div>


          </div>
          <div className='col-md-6 order-md-1'>
                <br></br>
                <div className='p-3'>
                  <img alt='' src={image2} className='img-fluid'></img>
                {/*<CallToAction/>*/}

                </div>

              </div>
        
          </div>
      </div>
    </div>


    <div className=' sticky-top container mt-5 pb-5 pt-5 bg-gray'>
          <br></br>
          <div className='row align-items-center'>
              <div className='col-md-9'>

                <br></br>
                
                <div className='row align-items-center'>
                  <div className='col-md-8 offset-md-2 mt-5'><p className='hero2 text-center'>Claim your handle before it's gone!</p>
                  <br></br>
                  <ClainYourHandle></ClainYourHandle>
                  
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <br></br>
                <div className=' p-3'>
                  <img alt='' src={image3} className='img-fluid'></img>
                {/*<CallToAction/>*/}

              </div>
              

          </div>
      </div>
      



  
  


    </div>

    {/** FAQ */}
    <div id='faq' className=' sticky-top container mt-5 pb-5 pt-5 bg-white'>
      <br></br>
      <div className='row align-items-center'>
          <br></br>
          
          <div className='col-md-10 offset-md-1'>                        
            <Faq></Faq>
            <div className="row">
              <div className="col-sm-12 col-md-6 offset-md-3">
                <ClainYourHandle></ClainYourHandle>
              </div>
            </div>
          </div>
      </div>
    </div>
  </>
  );
}

const ClainYourHandle = (props)=>{
  return(
    <div>
    <div className='mb-4 border-bold  p-3 rounded-pill shadow'>
    <label className='fw-bold' htmlFor="test">triptopper.com/</label>
    <span>
      <input className='borderless-input' name="test" id="test" type="text" placeholder="Claim your name"/>
    </span>
    </div>
    <Link className='btn btn-primary button-63' to="/app/Signin"><h4> <span className='m-2 fw-bold'>Get Started</span></h4></Link>
    </div>
  )
}



export default Index;
