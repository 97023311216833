import '../../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef,forwardRef, useReducer} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Autocomplete from 'react-google-autocomplete';
import GoogleSearchAutocomplete from '../GoogleSearchAutocomplete';
import ActivityBlock from './ActivityBlock';
import Activity from './Activity';

import {addevent, deleteevent,updatedaylocation,setwheretostay, saveactivityorder} from '../../api/itin';
import Authservice from '../Authservice';
import ico_sunrise from '../../icons/sunrise.png';
import ico_sunnoon from '../../icons/sunnoon.png';
import ico_sunset from '../../icons/sunset.png';
import ico_delete from '../../icons/delete.png';
import ico_plus from '../../icons/plus.png';
import ico_add from '../../icons/add.png';
import ico_edit from '../../icons/edit.png';
import ico_htr from '../../icons/htr.png';
import ico_hotel from '../../icons/hotel.png';
import ico_heading from '../../icons/header.png';
import Note from './Note';
import ico_pin from '../../icons/i-location.png';
import ico_image from '../../icons/i-photo.png';
import ico_header from '../../icons/i-heading.png';
import { addnote, deletenote } from '../../api/itin';

import ModalSetLocation from '../ModalSetLocation';
import { act } from 'react-dom/test-utils';
import SelectImageModal from './SelectImageModal';
import Divider from '../Divider';


const EditDay = React.forwardRef((props,ref) => {
    const [day,Setday] = useState(props.item);
    const [tdprocessed,Settdprocessed] = useState(true);
    const [showGoogleSearchBar,SetshowGoogleSearchBar] = useState(false);
    const [imageModalShow,SetimageModalShow] = React.useState(false);
    
    const [Activeaction,SetActiveaction] = useState('');


    const [showHowToReach,SetshowHowToReach] = useState(false);
    const [gtype,Setgtype] = useState([]);
    const [gcountry,Setgcountry] = useState("");
    const [gplaceholder,Setgplaceholder] = useState("Enter new location for day");

    const getDateForDay = (date, day) => {
      var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
      return (new Date(new Date(date).getTime() + 24 * 60 * 60 * 1000 * day)).toLocaleDateString("en-US", options)
    }
    
    const ControlView = ()=>{
      const [Newheading,SetNewheading] = useState('');
      const handleNewheadingChange = (e)=>{
        SetNewheading(e.target.value);
      }
    const handleKeyDown_NewHeading = (event) => {
        if (event.key === 'Enter' && Newheading !== '') {

          var obj = {heading:Newheading};
          addactivity(obj,'heading');

        }
      }

      switch(Activeaction){
        case 'place_search':
          return (
            <div className='row align-items-center'>
              <div className='col-sm-11 offset-sm-1 p-0'>
              <div className='pt-3'>
                <p className=' ms-4 pt-1 fs-6 fw-bold'> <img src={ico_pin} className='i-sm me-2'></img>Add a place 
                <button className='btn float-end me-2 fw-bold text-center' onClick={()=>{SetActiveaction('')}}> X </button>
    
                </p>
    
                    <GoogleSearchAutocomplete 
                    cancel={cancelActiveAction} 
                    gtype={gtype}
                    placeholder={gplaceholder}
                    gcountry={gcountry}
                    add={addactivity} 
                    cname='form-control'></GoogleSearchAutocomplete>
              </div>
              </div>
            </div>
              
          )
        case 'heading': return(
          <div className='row align-items-center'>
            <div className='col-sm-11 offset-sm-1 p-0'> 
              <div className='pt-3'>
                <p className=' ms-4 pt-1 fs-6 fw-bold'><img src={ico_heading} className='i-xs me-2'></img>Add a heading
                <button className='btn float-end me-2 fw-bold text-center' onClick={()=>{SetActiveaction('')}}> X </button>
    
                </p>
                <div className='container'>
                  <div className='row'>
                      <div className='col-sm-12'>
                        <input className='form-control h2' placeholder='Add a heading' 
                          onKeyDown={handleKeyDown_NewHeading}
                          onChange={handleNewheadingChange} value={Newheading}></input>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

        default:{
          return(
            <div className='row my-4'>
            <div className='col-2'>
            </div>
            <div className='col-8 p-0 mt-1'>
              <div className='row'>
                <a className={'col-md-3 text-sm gray btn-sm m-1 nodecor'  + (props.index === 0 ? ' stepnine' : '')} onClick={()=>handlePlaceSearchAction('',[],1)}><span><img className='i-sm' src={ico_pin}></img> + Add a place </span> </a>
                <a className={'col-md-3 text-sm gray btn-sm m-1 nodecor' + (props.index === 0 ? ' stepten' : '')} onClick={()=>handleAddHeadingAction()}><span><img className='i-sm' src={ico_header}></img> + Add a heading </span> </a>
                <a className={'col-md-3 text-sm gray btn-sm m-1 nodecor' + (props.index === 0 ? ' stepeleven' : '')} onClick={()=>SetimageModalShow(true)}><span><img className='i-sm' src={ico_image}></img> + Add an image </span> </a>
              </div>
              </div>
            </div>

          )
        }
    }
    }

    const AddLabel = (props)=>{
      const [item,Setitem] = useState(null)
      if(props.action === 'heading'){
        
      }else{}
    }
    

    const cancelActiveAction = ()=>{
      SetActiveaction('');
    }
  
    const handlePlaceSearchAction=(gtype,gcountry)=>{
      var gtypearray = gtype?gtype.split('|'):[];
      Setgtype(gtypearray);
      Setgcountry(gcountry);
      Setgplaceholder('Search..')
      SetActiveaction('place_search');
    }

    const handleAddHeadingAction = ()=>{
      SetActiveaction('heading');
    }
    
    function handleOnDragEnd(result) {
      if (!result.destination) return;

      const items = Array.from(day.activities);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      const neworder  = items.map((item,index) => {
          return {...item,
            // _id:item._id,
            // heading:item.heading,
            // activity: item.activity,
            // label:item.label,
            // note:item.note,
            order:index+1
          }
        });  
        Setday({...day,activities:neworder});
   
         
      var headertoken = Authservice.getheadertoken();
      axios.defaults.headers.common['Authorization'] = 'Bearer '+ headertoken;
      axios.post(saveactivityorder,{dayid:day._id,activities:neworder})
        .then(res=>{
          if (res.data.success ) {
          }
          else{ //Setday({...day,activities:items})
        }
        }).catch(err=>{Setday({...day,activities:items})})
      
        
    }
    //console.log(day);
    const deleteActivity = (id, img)=>
    {
      var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        axios.post(deleteevent,{dayid:day._id,activity_id:id,img: img})
          .then(res=>{
            if (res.data.success ) {
              var tempactivities = day.activities;

              var updatedActivities = tempactivities.filter(activity=>activity._id !==id);
              // Setday({...day,activities:updatedActivities});
              props.updateActivityState(day._id, updatedActivities);

                //SetshowGoogleSearchBar(false);
      
            }
            else{
              return false;
            }
          }).catch(err=>{
            return false;
          })  
    }

    const updateAfterNoteAdded = (activity) => {
      var tempactivities = day.activities;
      var updatedActivities = tempactivities.map(act=>{
        if(act._id === activity._id){
          return activity;
        };
        return act;
      });

      props.updateActivityState(day._id, updatedActivities);
    }

    const handleSearchImageData = (obj) => {
      addactivity(obj, 'image');
    }

    const addactivity = (item, label) => {
      props.addActivityToDay(day, item, label);
      SetActiveaction('');
    }

    const addactivity_bak = (item,label)=>{
        var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        
        if(item){
          axios.post(addevent,{dayid:day._id,activity:item,label:label,order:day.activities.length+1})
            .then(res=>{
            
              if (res.data.success && res.data.activity) {

                  var _places = day.activities;
                
                  //activityresponse.td = td;
                  //activityresponse.ty = ty;
                  _places.push(res.data.activity);
                  //day.activities.map(x => (x.activity.gid === item.gid ? { ...x, _id:res.data._id} : x));
                  
                  Setday({...day,activities:_places});
                  SetActiveaction();
                  return true;  
                  //SetshowGoogleSearchBar(false);
        
              }
              else{
                return false;
              }
            }).catch(err=>{
              return false;
            })
        }
    }
    
    const setdaylocation = (item)=>{
      var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        axios.post(updatedaylocation,{id:day._id,index:props.index+1,itin:day.itin,location:item})
          .then(res=>{
            if (res.data.success ) {
              //Setday({...day,location:res.data.loc});
              props.updatelocation(day._id,res.data.loc);
              
              //SetshowGoogleSearchBar(false);
            }
            else{
              return false;
            }
          }).catch(err=>{
            return false;
          })
      SetshowGoogleSearchBar(false);
    }

    const setwts = (item)=>{
      var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        console.log(item);
        axios.post(setwheretostay,{id:day._id,wts:item})
          .then(res=>{
            if (res.data.success ) {
              props.updateWTS(day._id,item);
            }
            else{
              return false;
            }
          }).catch(err=>{
            return false;
          })
      SetshowGoogleSearchBar(false);
    }
    const cancelGoogleSearch = ()=>{
      SetshowGoogleSearchBar(false);
    }

    function getHtrText()
    {
      return "Take a bus.."
    }


    function onDayDragStart(){
      props.setOpenDayDragModal(true); 
      props.setDayIndex(props.index)

      const cls = document.querySelectorAll('.flash');
        cls.forEach(c => {
          c.classList.remove('flash');
        })
    }

    /*
    const GetHowtoReachView =()=>{
      const [iseditnote,Setiseditnote] = useState(false);

      const editNote = ()=>{   
        Setiseditnote(true);
    }
    
    const onBlurHTR = ()=>{
      console.log("On blur htr called");
      setTimeout(() => {
        SetshowHowToReach(false);
      }, 3000);
    }
    
    const cancelNote =(note)=>{
        Setiseditnote(false);
    }

    const doneNote = (note)=>
    {
     if(note){
      var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        axios.post(addnote,{dayid:props.dayid
            ,note:note})
          .then(res=>{
            if (res.data.success ) {
                var _htr = day.htr;
                _htr.note = note;
                Setday({...day,htr:_htr});
                Setiseditnote(false);
            }
            else{
              return false;
            }
          }).catch(err=>{
            return false;
          })
        }
        else{Setiseditnote(false);}
      
    }
      // onChange={}
      if(showHowToReach){
        return(
          <div className='bg-gray p-3' onBlur={()=>onBlurHTR}>
            <p className='fs-6 fw-bold'> How to reach?
              <button className='btn btn-sm float-end me-1 fw-bold' onClick={()=>{SetshowHowToReach(false)}}> X </button>
            </p>
            <select class="form-select form-select-md mb-3" aria-label=".form-select-lg example">
              <option selected>Mode of transport</option>
              <option value="0">Flight</option>
              <option value="1">Car</option>
              <option value="2">Bike</option>

              <option value="3">Bus</option>
              <option value="4">Train</option>
              <option value="5">Trek</option>
            </select>
            <Note edit={editNote} cancel={cancelNote} done={doneNote} item={day.htr?day.htr.note:null} isedit={iseditnote}></Note>

        </div>
          )
      }else{
        return(
          <a className='small fw-bold' onClick={()=>{SetshowHowToReach(true)}}>{day.htr?getHtrText():'How to reach?'}</a>
        )
      }
    }
    */

    const GetLocationView = ()=>{
      if(!showGoogleSearchBar){
        return(
          <>
        <a onClick={()=>SetshowGoogleSearchBar(!showGoogleSearchBar)} className='fw-bold text-sm nodecor' >
        <img className='i-xs' src={ico_pin}></img> {day.location && day.location.fulladdress?day.location.fulladdress:"Set location"}
        </a>
        </>
        )  
    }else{
        return (
          <div className='bg-gray'>
            <p className=' ms-4 pt-3 fs-6 fw-bold'>Change day location
              <button className='btn float-end me-3 fw-bold text-center' onClick={cancelGoogleSearch}> X </button>
            </p>
            <GoogleSearchAutocomplete cancel={cancelGoogleSearch} 
            gtype={gtype}
            placeholder={gplaceholder}
            gcountry={gcountry}
            add={setdaylocation} 
            cname='form-control'></GoogleSearchAutocomplete>
        </div>
        )
      }
    }

    const GetWheretoStayView = ()=>{
      const [showwts,Setshowwts] = useState(false);
      if(showwts){
        return(
        <div className='bg-gray'>
        <p className=' ms-4 pt-3 fs-6 fw-bold'><img className='i-sm' src={ico_hotel}></img> Where to Stay ?
          <button className='btn float-end me-3 fw-bold text-center' onClick={()=>Setshowwts(false)}> X </button>
        </p>
        <GoogleSearchAutocomplete cancel={cancelGoogleSearch} 
        // gtype={gtype}
        gtype={['lodging']}
        placeholder={gplaceholder}
        gcountry={gcountry}
        add={setwts} 
        cname='form-control'></GoogleSearchAutocomplete>
       </div>
        )
      }else{
          return(
          <a onClick={()=>Setshowwts(true)} className='nodecor text-sm float-md-end fw-bold'><img src={ico_hotel} className='i-xs me-2'></img>{day.wts && day.wts.name?day.wts.name:'Where to stay?'}</a>
          )
         }

      
    }

    const GetView = ()=>{
  
        return(
          <>
            {/* <DragDropContext onDragEnd={handleOnDragEnd}> */}
              <Droppable droppableId={'activity-'+props.index} >
                  {(provided) => (
                      <ul style={{listStyle: 'none'}} {...provided.droppableProps} ref={provided.innerRef}>
                          <div className='col-sm-12 offset-md-1 col-md-11'>

                              {
                                day.activities?.map((item,index)=>{
                                    return (
                                        
                                        <Draggable key={item._id.toString()} draggableId={item._id.toString()} index={index}>
                                            {(provided) => (
                                                <li ref={provided.innerRef} {...provided.draggableProps} >
                                                    <Activity dayid={day._id} key={item._id.toString()} delete={deleteActivity} index={index} item={item} dragHandle={{...provided.dragHandleProps}}
                                                    updateAfterNoteAdded={updateAfterNoteAdded}></Activity>
                                                </li>)
                                            }
                                        </Draggable>
                                    );
                                })
                              }        
                          </div>
                          {/** 
                            <ActivityBlock addActivity={addActivity} day={day} cname=''></ActivityBlock>
                            <ActivityBlock icon={ico_sunnoon} key={3} addActivity={addActivity} deleteActivity={deleteActivity} timeofday={3} day={day} label='Afternoon'></ActivityBlock>
                            <ActivityBlock icon={ico_sunset} key={4} addActivity={addActivity} deleteActivity={deleteActivity} timeofday={4} day={day} label='Evening'></ActivityBlock>
                          */}
                          {provided.placeholder}
                      </ul>
                  )}
              </Droppable>
              <ControlView></ControlView>

            {/* </DragDropContext>            */}
          </>
        )
     
    }
    
    if(tdprocessed)
    {return (
          <div className='container' ref={ref}>

          <SelectImageModal imagetype='activityimage' input={day.location.fulladdress && day.location.fulladdress.split(',')[0]} show={imageModalShow} handleSearchImageData={handleSearchImageData} onHide={() => SetimageModalShow(false)}/>
            <div className=''>
              <div className='row'>
                
                <div className='col-sm-12'>
                  <p 
                  draggable onDragStart={onDayDragStart}
                  className='hero4'> Day - {props.index +1 } {/*<button onClick={()=>props.cancel(props.index)} className='btn btn-close float-end'></button>*/}
                <span className='fs-6 fw-bold text-muted fst-italic '><small>{props.date && getDateForDay(props.date, props.index)}</small></span>

                  <button className='btn btn-sm float-end' onClick={() => {props.delete(props.item._id)}}> <img src={ico_delete} className='i-xs'></img></button>

                  </p>
                </div>
                <div className='col-sm-12 col-md-6 mb-2'>
                  <GetLocationView></GetLocationView>
       
      
                </div>
                
                <div className='col-sm-12 col-md-6 mb-2'>
                    <GetWheretoStayView></GetWheretoStayView>
                 {/*  <GetHowtoReachView></GetHowtoReachView> */}
                </div>
              
              </div>  
              {/*<button className='btn btn-link float-end'>Add place to stay</button>*/}


            
            </div>
            <div className='row'>  {/*<textarea  value={day.details} onChange={handleInputChange} className='form-control h1' rows='6' maxLength={300} /> */}
              <GetView></GetView>
            </div>
           
            <Divider icon={ico_add} onClick={() => props.addDay(day._id)} class={(props.index === 0 && 'steptwelve')}/>

          </div>
                
      ) 
    }
    else
    {
      return(
      <div className='content-50'><br></br><center><p className='display-5 gray'>Loading....</p></center></div>
      )
    }
      
}

)




export default EditDay;
