import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';

import ico_fb from '../icons/facebook.png';
import ico_linkedin from '../icons/linkedin.png';
import ico_whatsapp from '../icons/whatsapp.png';
import ico_twitter from '../icons/twitter.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';


function SharerModal(props) {
   //backdrop="static"
   const [copytext,Setcopytext] = useState("Copy url");
   const copy = async () => {
    await navigator.clipboard.writeText(props.SharerUrl);
    Setcopytext("Copied!")
    setTimeout(function() { //Start the timer
      Setcopytext("Copy url")

  }.bind(this), 1500)
  }
    return (
      <Modal
        {...props}
        keyboard={false}
        >
        <Modal.Header closeButton>
          
                <p className='hero4 mt-3'>Share</p>
                
                
        </Modal.Header>
        <Modal.Body className="show-grid p-3 text-center">

            <a className='btn btn-outline-gray form-control nodecor border' target='_blank' href={"https://www.facebook.com/sharer/sharer.php?u="+props.SharerUrl+"&t="+props.text}>
                <img src={ico_fb} className="i-sm align-middle"></img>
                <span class='p fw-bold ms-2 color-black'> Share on facebook</span>
                
            </a>
            {/*  
              <a className='mt-4 btn btn-outline-gray form-control nodecor border' target='_blank' href={"https://www.linkedin.com/sharing/share-offsite/?url="+props.SharerUrl}>
                  <img src={ico_linkedin} className="i-sm align-middle"></img>
                  <span class='fs-5 ms-2 color-black'> Share on linkedin</span>
                  
              </a>
            */}
            <a className='mt-4 btn btn-outline-gray form-control nodecor border' target='_blank' href={"https://twitter.com/intent/tweet?url="+props.SharerUrl+"&via=Thetriptopper"+"&text="+props.text}>
                <img src={ico_twitter} className="i-sm align-middle"></img>
                <span class='p fw-bold ms-2 color-black'> Share on twitter</span>
                
            </a>
          
            <a className='mt-4 btn btn-outline-gray form-control nodecor border' target='_blank' href={"whatsapp://send?text="+props.SharerUrl}>
                <img src={ico_whatsapp} className="i-sm align-middle"></img>
                <span class='p fw-bold ms-2 color-black'> Share on whastapp</span>
                
            </a>

            <div>
              <button onClick={()=>copy()} className='mt-4 btn btn-sm border p-2  form-control'>  
              <span className='color-black me-3'>{props.SharerUrl}</span>
              <br></br>
              <span className='mt-3 btn btn-primary btn-sm fw-bold'>{copytext}</span></button>
            </div>                
                
                
            
            <br></br>
            <br></br>
            <hr></hr>
            <center>
              <p>Get yourself a triptopper handle</p>
              <Link className='btn btn-primary fw-bold' to="/app/Signin"> Get Started</Link>

            </center>
           
          
         
        
        </Modal.Body>
       
      </Modal>
    );
  }
  export default SharerModal;
