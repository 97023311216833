import '../App.scss';
import React, {useState } from 'react';
import {Link,useNavigate} from 'react-router-dom';
import ico_edit from '../icons/edit.png';
import ico_share from '../icons/share.png';
import ico_copy from '../icons/copy.png';

const ItinView = (props)=>{
    const navigate = useNavigate();
    // const [itins,SetItins] = useState(props.itins);

    if(!props.itins){
        return(<></>)
    }

      return(

        props.itins.map((itin,index)=>{
            return (
              <div className='col-sm-12 col-md-12 mt-2 p-3' key={itin._id.toString()}>
                {/*border rounded '+itin.themename*/}
                <div className={'border rounded'}>
                  <div className=' p-1'>
                  <Link className='btn fw-bold' to={"/app/myitin/"+itin._id}><img src={ico_edit} className='i-sm'></img></Link>
                  <button className='btn'  onClick={() => props.OpenCopyModalClick(itin)}><img src={ico_copy} className='i-sm'></img> </button>

                  <button className='btn float-end'  onClick={() => props.OpenSharerModal("https://triptopper.com/i/"+itin.nanoid,itin.title)}><img src={ico_share} className='i-sm'></img> </button>

                  </div>

                  <div className=''>
                    <div className=' selectable-div' onClick={()=>{navigate("/app/myitin/"+itin._id)}}>
                      <div className='p-3'>
                        <Link className='fs-5 fw-bold nodecor color-black' to={'/app/myitin/'+itin._id}> {itin.title} </Link>
                        <p className='small fw-bold mt-2'> {itin.days.length} - day <span className='ms-4'>{itin.location.fulladdress}</span></p>
                      {/* <span className=''>435 visits</span> <span className=' p-3 m-2'> 127 shares</span>*/}
                      </div>
                    </div>
                  </div>

              </div>
              </div>
            )
        }))
   
    
}
export default ItinView;
