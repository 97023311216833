const Divider = (props) => {
    return(
        <div className='mt-2 centerDivider text-center'>
              <button onClick={props.onClick} className={'btn centerDivider-text ' + (props.class ? props.class : '')}>
                <img className='i-sm me-2' src={props.icon}></img>
                <span className='gray'>{props.text}</span>
              </button>
            </div>
    )
};

export default Divider;