import React,{useState,useEffect,useRef} from 'react';
import '../../App.scss';
import { useParams,Link } from 'react-router-dom';
import Authservice from '../Authservice';
import axios from 'axios';
import {getbynanoid, updateviewscount} from '../../api/itin';
import ViewDay from './ViewDay';
import SignIn from '../SignIn';
import TopBlock from './TopBlock';
import BottomCTAI from '../BottomCTAI';
import * as htmltoimage from 'html-to-image';
import { Button } from 'react-bootstrap';
import Ctr  from '../User/Ctr';
import AddtoBucketList from './AddtoBucketList';
import { toJpeg, toCanvas } from 'html-to-image';
import jsPDF from 'jspdf';
import ReactDOMServer from "react-dom/server";


function Itin(props){
  const downloadref = useRef(null);
  const imageRef = useRef([]);
 
  const downloadImage  = async () => {
    console.log("I am called")
    const dataUrl = await htmltoimage.toPng(downloadref.current);
    //console.log(dataUrl);
    // download image
    const link = document.createElement('a');
    link.download = "tt1.png";
    link.href = dataUrl;
    link.click();
  }   
    const [itin,SetItin] = useState(null)
    const params = useParams();
    const newdayRef = useRef(null);
    const [theme,Settheme] = useState("theme-purple")
    //custom-itin-rounded
    const [themeTop,SetthemeTop] = useState("")
    const [Loading,SetLoading] = useState(true);
    const [NotFound,SetNotFound] = useState(false);

    const id = props && props.id? props.id:params.id;
    useEffect(()=>{
            
            if(id){var headertoken = Authservice.getheadertoken();
            axios.defaults.headers.common['Authorization'] =    
                  'Bearer '+ headertoken;
            const url = getbynanoid+id;
            axios.get(url).then(res=>{
              if (res.data.success && res.data.itin) {
                var _days = res.data.itin.days;
                _days.sort((a,b) => a.order - b.order);
                SetItin({...res.data.itin, days: _days});
                SetLoading(false);

                //update views count
                const authid = Authservice.getCurrentUser()? Authservice.getCurrentUser()._id : null;

                if(authid){
                  updateViewsCount(authid);
                } else{
                  const viewed = JSON.parse(localStorage.getItem("vieweditin"));
                  if(viewed){
                    const exist = viewed.find(value => value.toString() === id.toString());
                    if(!exist) {
                      var newarray = [...viewed, id];
                      localStorage.setItem("vieweditin", JSON.stringify(newarray));
                      updateViewsCount(authid);
                    }
                  } else{
                    var newarray = [id];
                    localStorage.setItem("vieweditin", JSON.stringify(newarray));
                    updateViewsCount(authid);
                  }
                }

              }
              else{
              SetNotFound(true);
              SetLoading(false);
              }
          }).catch(err=>{
            console.log(err);
          })}else{}
        },[])

    const updateViewsCount = (authid) => {
      if(id){
        axios.post(updateviewscount,{_id: id, authid: authid}).then(res=>{
          if (res.data.success) {
          }
          else{
          }
      }).catch(err=>{
        console.log(err);
      })}else{}
    };

    const SidePanelView = ()=>{
      if(itin.leadson){
        return (
          <div className='row mt-4'>

            <div className='col-sm-12 p-2 mb-5 bg-white '>
              {/* <AddtoBucketList></AddtoBucketList> */}
              <Ctr item={itin}></Ctr>
            </div>
          </div>
        
        )
      }else{
        return (<BottomCTAI></BottomCTAI>)
      }

    }
    
  if(NotFound && !Loading){
    return (
      <div className='container'>
        <div className='row' >
          <div className='col-sm-12 mt-4'>
            <br></br>
              <center>
                <p className='hero4'>[404]</p>
                <br></br>
                <p>The page you are looking does not exist!</p>
              </center>
          </div>
        </div>
        <div className='row'>
            <BottomCTAI></BottomCTAI>
        </div>
      </div>
  
    )

  } 
  else if(!NotFound && !Loading)
  {
  return (
    <>

    <div className=''>

      <div className='container'>
        <div className='row align-middle'>
          <div className='col-sm-8 col-md-8' id="domEl">
          </div>
          <div className='col-sm-12 col-md-8'>
          <TopBlock downloadImage={downloadImage} downloadref={downloadref} 
          theme={itin.themename?itin.themename:"theme-gray"} bgimage={itin.bgimage}
          itin={itin} date={itin.startdate}></TopBlock>

            <div className='bg-white shadow border'>
                {
                  itin.days.map((item,index)=>{
                    return(<div key={item.day._id.toString()} ref={ref => {
                      imageRef.current[index] = ref
                    }} >
                      <ViewDay  ref={newdayRef}  item={item.day} index={index} date={itin.startdate}></ViewDay>
                      </div>)
                  })
                }
            </div>
            <br></br>
            <br></br>
          </div>
          <div className='col-md-4 '>
            <div className='sticky-top'>
            <SidePanelView></SidePanelView>

            </div>

          </div>


              {/*  {itin.days.map((item,index)=>{
                  if(item.open){
                    return(<EditDay  ref={newdayRef} save={daySave} delete={dayDelete} item={item} index={index}></EditDay>)
                  }
                  else if(item._id){
                    return (<EditDay edit={dayEdit} save={daySave} delete={dayDelete} item={item} index={index}></EditDay>)
                  }
                })
              }*/}
        
        </div>


        <div>

      </div>
    </div>
    </div>
    </>

  );

  
  }
  else{
    return ( <>
         <div className='content-50'><br></br><center><p className='display-5 gray'>Loading....</p></center></div> 
    </>)
  }
}






export default Itin;
