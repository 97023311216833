import '../../App.scss';
import React,{useState,useRef,useEffect} from 'react';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Authservice from '../Authservice';
import ico_edit from '../../icons/edit.png';

const EditTitle=(props)=> {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [title,Settitle] = useState(props.title);
  const [selectedlocation,Setselectedlocation] = useState(props.location);

  const [isvalidtitle,SetValidTitle] =  useState(location.state && location.state.itin.title.length>10?true:false);
  const titletextboxref = useRef(null);
  const [editTitle,SeteditTitle] = useState(false);

  useEffect(() => {
    
  //if(titletextboxref){ titletextboxref.current.focus()};
    //titletextboxref.current.onkeyup = handleChange;
    //titletextboxref.current.placeholder = title;
  },[]);

  const handleEditTitle = (value)=>{
    
    SeteditTitle(value)
  }

  function handleChange(e) {
    e.preventDefault();
    //Setitin({...itin, title: e.target.value});
    Settitle(e.target.value)

    if(e.target.value.length > 10 && e.target.value.length < 50){
      SetValidTitle(true)
    }
    else{
      SetValidTitle(false)
    }
  }

  function onSaveClicked(){
    if(isvalidtitle)
    {
      props.save(title);
      handleEditTitle(false);
    }
  }

  if(!editTitle)
  {
    return(
      <div>
        
              <p className='fw-bold fs-4'> <button className='btn btn-sm stepseven' onClick={()=>handleEditTitle(true)}><img src={ico_edit} className='i-sm'></img></button> {props.title}</p>
              
      </div>
    )
  }
  else
  {
    return (
      
   
        <div className='row p-2 pb-3 pt-3 mb-4'>
            <div className='col-sm-12'>
                <p className="fs-6 fw-bold mb-4"> Title
                  <button className='btn float-end' onClick={()=>handleEditTitle(false)}> X</button>
                </p>
                <input ref={titletextboxref} onChange={handleChange} value={title} 
                 placeholder="Title of the itinerary" className="p-4 bg-white form-control rounded-pill custom-large-box"/>
                <br></br>
                <center>
                  <button style={{ visibility: isvalidtitle ? "visible" : "hidden" }} className='btn btn-primary fw-bold' onClick={onSaveClicked}>Save</button>
                </center>
            </div>
        </div>
    )
    
  
}
}

export default EditTitle;
