import '../App.scss';
import { Link } from 'react-router-dom';

import React from 'react';


function Termsofuse() {
    return (
        <div className='mt-5 pt-5'>
            <div className='container'>
                <div className='row'>


                    <div className='col-sm-12 text-center d-none mb-5'>
                        <iframe className='text-center' src="https://docs.google.com/document/d/e/2PACX-1vTiecP8rwFk5GevM7V0Vdh0W0SMnG0LbcQZcUTR7tCePM2UpheBxZ9BZX-H4i_teoQgaSs0LsCP6euo/pub?embedded=true"
                            width="98%" height="1200" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                    </div>


                    <div className='col-sm-12 p-2 col-md-10 offset-md-1 mb-5'>
                        <p className='text-center hero3'>Terms of use</p>
                        <p>Effective April 1,2023</p>
                        <p>These Terms of Service (&ldquo;Terms&rdquo;) govern access to and use of the Services made available by B.COMMERCE TECHNOLOGIES PRIVATE LIMITED including but not limited to&nbsp;<a href="https://www.triptopper.com/">https://www.triptopper.com</a>. By using our Services, you agree to be bound by these Terms. If you use our Services on behalf of an entity or organization, you are binding that organization to these Terms, and you represent that you have the authority to do so.</p>

                        <p>Welome to triptopper.com, We are on a mission to liberalize the travel experience and make it accessable on internet.</p>

                        <ol>
                            <li>
                                <p>Please read these terms of use (these &quot;Terms of Use&quot; &amp; &nbsp;&ldquo;Disclaimer&rdquo;) carefully. By accessing or using the&nbsp;https://www.triptopper.com&nbsp;domain and/or cloud application, any subdomain of this website (the &quot;Website&quot;), and other services hosted by or on behalf of Triptopper or its affiliated companies (including without limitation the Website,&nbsp;cloud applications&nbsp;collectively, the &quot;Services&quot;), you agree that you have read, understand and agree to comply with and be bound by these Terms and Conditions, whether or not you are a registered member of the Website or other Services. Please also read our Privacy Policy, and Disclaimer carefully to understand what we do with the personal information that we collect as part of your interaction with the Services. These Terms and Conditions, including without limitation the Terms of Use, Privacy Policy, and Disclaimer, govern your use of the Services and constitute a legal contract between you and Triptopper (&quot;Company&rdquo; or,&quot; &quot;we&quot; or &quot;us&quot;).</p>
                            </li>
                        </ol>

                        <ol start="2">
                            <li>
                                <p>This document is an electronic record in terms of The Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                            </li>
                        </ol>

                        <ol start="3">
                            <li>
                                <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, Privacy Policy and Terms of Use for access or usage of Triptopper Website&nbsp;<a href="https://www.triptopper.com">https://www.triptopper.com</a> and/or cloud application.&nbsp;</p>
                            </li>
                        </ol>

                        <ol start="4">
                            <li>
                                <p>The domain name&nbsp;<a href="https://www.triptopper.com">https://www.triptopper.com</a> &nbsp;(&quot;Website&quot;), is owned and operated by Triptopper, having its registered office at A9-A, Green Park Main, Green Park, New Delhi, DELHI 110016.</p>
                            </li>
                        </ol>

                        <ol start="5">
                            <li>
                                <p>Triptopper (&ldquo;Our&rdquo;, &ldquo;Us&rdquo;, &ldquo;We&rdquo;, &ldquo;Website&rdquo;, &ldquo;Site&rdquo;) Website and/or Cloud Application.&nbsp;</p>
                            </li>
                        </ol>

                        <ol start="6">
                            <li>
                                <p>The expressions &ldquo;You&rdquo;, &ldquo;Your&rdquo; or &ldquo;User&rdquo;, whenever the context so requires, for the purposes of these Terms of Use, shall mean any natural or legal person who may create by registration, membership account on this Website or&nbsp;cloud application&nbsp;or agree to avail Our Services through this Website or&nbsp;cloud application, or otherwise access Our Website or&nbsp;cloud application.</p>
                            </li>
                        </ol>

                        <ol start="7">
                            <li>
                                <p>By accessing and/or using the website and/or&nbsp;cloud application, you signify your agreement to accept these Terms of Use. If you do not agree with any or all of the following terms, please do not access and/or use the website and/or&nbsp;cloud application.&nbsp;</p>
                            </li>
                        </ol>

                        <ol start="8">
                            <li>
                                <p>We reserve the right, at our sole discretion, to change or modify these Terms of Use at any time without prior notice. Such changes and/or modifications shall become effective immediately upon being posted/published on the website and/or&nbsp;cloud application&nbsp;herein.</p>
                            </li>
                        </ol>

                        <ol start="9">
                            <li>
                                <p>Please review the Terms of Use from time-to-time. Your continued use of the website and/or&nbsp;cloud application&nbsp;following the posting of changes and/or modifications will constitute your acceptance of any revised Terms of Use. The company retains the right at any time to deny or suspend access to all or part of the website and/or&nbsp;cloud application&nbsp;to anyone who the company believes has violated any condition of this Terms of Use.</p>
                            </li>
                        </ol>

                        
                        <p className='h3 fw-bold'>Restrictions; Use of Services</p>
                        

                        <ol>
                            <li>
                                <p>The following restrictions apply to your use of the Services: (i) you will not engage in any activity related to the Services that is contrary to applicable law, regulation or the terms of any agreements you have with the Company; (ii) you will not commercially exploit any part of the Services, except as expressly permitted by the Company; and (iii) all information and materials contained in the Services, except where otherwise expressly indicated, are owned and controlled by the Company. You may not use, modify, copy, distribute, frame, reproduce, republish, download, scrape, publicly display, publicly perform, post, transmit, sell or otherwise exploit any intellectual property or Content appearing on or through the Services, or create derivative works based on such Content or of&nbsp;cloud application, in any form or by any means, in whole or in part, without the Company&rsquo;s prior written permission, unless expressly permitted elsewhere under these Terms and Conditions. You may not circumvent, disable or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of any Content or enforce limitations on use of the Services or the Content.</p>
                            </li>
                        </ol>

                        <ol start="2">
                            <li>
                                <p>Any unauthorized use of the Services, including but not limited to misuse or any other use in violation of these Terms and Conditions of any information or Content, is strictly prohibited. If you violate any of your obligations or restrictions set forth in these Terms and Conditions, your permission to use and access the Services and the Content automatically terminates and you must immediately destroy any copies you have made of any portion of the information or content.</p>
                            </li>
                        </ol>

                        <ol start="3">
                            <li>
                                <p>You have to create an account in order to use the Website or&nbsp;cloud application. You are obligated not to gain unauthorized access to the Services, or any account, computer system or network connected to the Services, for example, through hacking, password mining or any other illicit or unauthorized means.</p>
                            </li>
                        </ol>

                        <ol start="4">
                            <li>
                                <p>When accessing or using the Services, you are strictly prohibited from and you are hereby obligated NOT to:</p>
                            </li>
                        </ol>

                        <ol>
                            <li>
                                <p>Write any unsolicited communications or social media reviews or defamatory statements or advertising not expressly authorized by the Company in writing, including without limitation promotional materials, affiliate marketing, junk mail, spam, link referral code, chain letters, or pyramid schemes, or post links to external websites, unless integral to the conversation, as determined by the Company in the Company&rsquo;s sole discretion;</p>
                            </li>
                        </ol>

                        <ol start="2">
                            <li>
                                <p>try to upload any material that contains software viruses, worms, or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</p>
                            </li>
                        </ol>

                        <ol start="3">
                            <li>
                                <p>use the Services to impersonate any person or entity, including but not limited to an employee of the Company or its affiliated companies, or falsely state or otherwise misrepresent yourself, your age or your affiliation with a person or entity;</p>
                            </li>
                        </ol>

                        <ol start="4">
                            <li>
                                <p>instigate or encourage others to commit illegal activities or cause injury or property damage to any person;</p>
                            </li>
                        </ol>

                        <ol start="5">
                            <li>
                                <p>intentionally or unintentionally violate any applicable local, state, national or international law;</p>
                            </li>
                        </ol>

                        <ol start="6">
                            <li>
                                <p>violate the terms of any third party website or service, or the terms of any other agreement with any third party;</p>
                            </li>
                        </ol>

                        <ol start="7">
                            <li>
                                <p>Authorize others to use your account;</p>
                            </li>
                        </ol>

                        <ol start="8">
                            <li>
                                <p>Assign or otherwise transfer your account to any third person or legal entity;</p>
                            </li>
                        </ol>

                        <ol start="9">
                            <li>
                                <p>Use the Service or Website or&nbsp;cloud application&nbsp;for unlawful purposes, including but not limited to sending or storing any unlawful material or for fraudulent purposes;</p>
                            </li>
                        </ol>

                        <ol start="10">
                            <li>
                                <p>Use the Service or Website or&nbsp;cloud application&nbsp;to cause nuisance, annoyance or inconvenience;</p>
                            </li>
                        </ol>

                        <ol start="11">
                            <li>
                                <p>Use the Service or Website or&nbsp;cloud application&nbsp;with an incompatible or unauthorized device;</p>
                            </li>
                        </ol>

                        <ol start="12">
                            <li>
                                <p>Impair the proper operation of the network and/or interfere with or disrupt the integrity or performance of the Website or Service or&nbsp;cloud application;</p>
                            </li>
                        </ol>

                        <ol start="13">
                            <li>
                                <p>Reverse engineer or access the Service or Website or&nbsp;cloud application&nbsp;in order to design or build a competitive product or service, design or build a product using similar ideas, features, functions or graphics of the Website or&nbsp;cloud application&nbsp;or copy any ideas, features, functions or graphics of the Website or&nbsp;cloud application,</p>
                            </li>
                        </ol>

                        <ol start="14">
                            <li>
                                <p>Launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses or worms, or any program which may make multiple server requests per second, or unduly burdens or hinders the operation and/or performance of the Website or Service or&nbsp;cloud application;</p>
                            </li>
                        </ol>

                        <ol start="15">
                            <li>
                                <p>Try to harm the Service or Website or&nbsp;cloud application&nbsp;in any way whatsoever;</p>
                            </li>
                        </ol>

                        <ol start="16">
                            <li>
                                <p>Prior written consent; and</p>
                            </li>
                        </ol>

                        <ol start="17">
                            <li>
                                <p>Disclose information designated as confidential by the Company, without Company&rsquo;s Copy or distribute the Service or Website or&nbsp;cloud application&nbsp;or other Company content without written permission from the Company.</p>
                            </li>
                        </ol>

                        <ol start="5">
                            <li>
                                <p>You are solely responsible for any breach of your obligations under this agreement (including financial obligations) and for the consequences (including any loss or damage which the Company may suffer) of any such breach and the Company shall be at discretion to take necessary legal action against you.</p>
                            </li>
                        </ol>

                        
                                <p className='h3 fw-bold'>Content and Intellectual Property</p>
                         

                        <ol>
                            <li>
                                <p>The content provided through the Services by the Company and its licensors or suppliers, including but not limited to anytext, graphics, software, photos, data, video, audio-visual combinations, interactive features, and other materials You may view on, or access through the Service (&ldquo;Content/ Softwares&rdquo;) is available on the Website and&nbsp;cloud application&nbsp;and all underlying software or technology used in connection with the Services (&quot;Technology&quot;), including but not limited to all intellectual property rights in and to the Content and Technology, is and remains at all times the property of Triptopper. Nothing in these Terms and Conditions shall be interpreted as granting any license of intellectual property rights to you other than as explicitly set forth in these Terms and Conditions. If you would like to use any of our Software and/ or Content, you must first obtain written permission from Us by contacting us at&nbsp;<a href="mailto:contact@lawyer24x.com">contact@triptopper.com</a> . The Company reserves the right to refuse permission for any reason or no reason.</p>
                            </li>
                        </ol>

                        <ol start="2">
                            <li>
                                <p>Any use of the Content other than as expressly authorized herein, without the prior written permission of the Company, is strictly prohibited and shall immediately terminate your right to access and use the Services and all rights and licenses granted to you by these Terms and Conditions. Such unauthorized use may also violate applicable laws including without limitation copyright and trademark laws and applicable communications regulations and statutes.</p>
                            </li>
                        </ol>

                        <ol start="3">
                            <li>
                                <p>The trademarks, service marks, trade names and logos, including without limitation Triptopper&trade; and any third party marks used and displayed through the Services are trademarks of Triptopper or its licensors or affiliated companies or sponsors.</p>
                            </li>
                        </ol>

                        <ol start="4">
                            <li>
                                <p>The animations, music, page headers, custom graphics, button icons, style sheets and scripts that contribute to the &quot;look and feel&quot; of the Services are service marks, trademarks or trade dress of the Company and may not be copied, imitated or used, in whole or in part, in connection with any product or service that is not expressly authorized by the Company in writing, in any manner that is likely to cause confusion among consumers, or in any manner that disparages or discredits the Company, its affiliated companies or its or their sponsors, licensors, partners, customers or suppliers.</p>
                            </li>
                        </ol>

                        <ol start="5">
                            <li>
                                <p>The Company may also uses third-party user-generated content including but not limited to text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music and branding, and the Company has no control over such third-party user-generated content as the Company is merely a fair user for the purposes of this Terms of Use. You may use such third-party user- generated information on the products and services purposely made available on the Website, provided that you use such information only for your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media and make no modifications to any such information. Your use of these third-party user generated content may be subject to the separate policies, terms of use, and fees of these third parties.</p>
                            </li>
                        </ol>
                        <p>&nbsp;</p>
                        <ol start="6">
                            <li>
                                <p>The Company owns, has licensed, or otherwise has rights to use all of the Content that appears in the Service, Website and all the&nbsp;cloud applications. Notwithstanding any provision to the contrary herein, you agree that you have no right or title in or to any Content that appears in the Service, Website and all the&nbsp;cloud applications.</p>
                            </li>
                        </ol>

                        <ol start="7">
                            <li>
                                <p>The Company complies with all applicable Indian intellectual property laws. If you believe that your work has been copied in a way that constitutes intellectual property infringement, or your intellectual property rights have otherwise been violated, please provide the following information to the the Company:</p>
                            </li>
                        </ol>

                        <ol>
                            <li>
                                <p>a description of the copyrighted work or other intellectual property that you claim has been infringed;</p>
                            </li>
                            <li>
                                <p>a description of where the material that you claim is infringing is located on the Website or other Services;</p>
                            </li>
                            <li>
                                <p>your address, telephone number and email address;</p>
                            </li>
                            <li>
                                <p>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law;</p>
                            </li>
                            <li>
                                <p>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest, as applicable; and</p>
                            </li>
                            <li>
                                <p>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on such owner&rsquo;s behalf.</p>
                            </li>
                        </ol>

                        <p>The Company can be reached as follows:</p>
                        <p>By email:&nbsp;<a href="mailto:contact@triptopper.com">contact@triptopper.com</a>&nbsp;</p>

                        <ol start="8">
                            <li>
                                <p>The Company will investigate the matter and, after such investigation and in its sole discretion, the Company may remove any infringing content from the Website.</p>
                            </li>
                        </ol>

                        <ol start="9">
                            <li>
                                <p>Similarly, if you believe that any content on the Website or Services violates any proprietary or other rights of yours (other than your copyrights), please contact us using the above contact information and describe your concerns or complaints.</p>
                            </li>
                        </ol>

                        
                        <p className='h3 fw-bold'>Pricing</p>
                            

                        <p>The prices mentioned at the time of ordering will be the prices charged on the date of delivery of the services requested. &nbsp;</p>

                        
                                <p className='h3 fw-bold'>Cancellation&nbsp;</p>
                          
                        <p>We make every effort to provide the service to you as per the specifications and timelines mentioned against each service purchased by you from Triptopper, however if, due to any reason, we are unable to provide to you the service or product you purchased from us, then the service requested shall be canceled.</p>

                        <p>However, even after your account is terminated, We may retain your Personal Information as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to cancellation of an account, investigate, or prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reasons.</p>

                        <p>If we suspect any fraudulent transaction by you or any transaction which defies the terms conditions of using the Website and/or cloud application, we, in our sole discretion, may cancel such requests. We shall document and keep records of all fraudulent transactions and customers and may, in our sole discretion, deny access to such users or cancel any requests placed by them.</p>

                        <p className='h3 fw-bold'>Suggestions and Feedback</p>
                        

                        <p>Please note that we do accept or consider ideas, suggestions or materials. If you send us suggestions, ideas, notes, drawings, concepts or feedback related to the Services, all such submissions shall be and are hereby deemed to be confidential, and the Company shall not be and hereby is not liable for any use or disclosure of any such submissions. Without limitation of the foregoing, the Company shall be entitled to unrestricted use of any suggestions, ideas, notes, drawings, concepts or feedback related to the Services for any purpose whatsoever, commercial or otherwise, without compensation to you or to any other person or entity.</p>

                        <p className='h3 fw-bold'>Third-Party Sites</p>
                        

                        <ol>
                            <li>
                                <p>These Terms and Conditions only relate to your use of the Services and do not relate to any other website or Internet-based services, including websites to which the Services may link (&quot;Third-Party Sites&quot;). We are not responsible or liable for any content, advertising, products, services, information or other materials on or available on Third-Party Sites. We also are not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, advertising, products, services, information or other materials on Third-Party Sites. We have no control over Third-Party Sites, their content or their terms of use, and therefore we encourage you to review such content and terms of use prior to utilizing any Third-Party Sites or submitting any personal information to them.</p>
                            </li>
                        </ol>

                        <ol start="2">
                            <li>
                                <p>The Company may provide experiences on social media platforms such as Pinterest&reg;, Facebook&reg; or Twitter&reg;, etc. that enable online sharing and collaboration among users who have registered to use them. Users agree not to post any such defamatory statements on such social media platforms. Any content you post is subject to the terms of use and privacy policies of those platforms and related services. The Company has no control over such social media platforms or related services.</p>
                            </li>
                        </ol>

                        <p className='h3 fw-bold'>Termination</p>
                        
                        <ol>
                            <li>
                                <p>The Company reserves the right, in its sole discretion, to refuse, suspend, restrict or terminate your access to the Services, or any portion thereof, without notice and for any reason or no reason. Furthermore, the Company has the right, but not the obligation, to suspend or terminate your access to all or part of the Services:</p>
                            </li>
                        </ol>

                        <ol>
                            <li>
                                <p>At the request of law enforcement or other government agencies;</p>
                            </li>
                            <li>
                                <p>If the Services are discontinued or materially modified;</p>
                            </li>
                            <li>
                                <p>Upon the occurrence of any technical or security issues or problems; or</p>
                            </li>
                            <li>
                                <p>If you engage in any conduct that the Company believes, in its sole discretion, violates any provision of these Terms and Conditions or other incorporated agreements or guidelines or violates the rights of the Company or third parties.</p>
                            </li>
                        </ol>

                        <ol start="2">
                            <li>
                                <p>In addition, the Company may terminate individual user accounts due to inactivity. You agree that upon termination the Company may delete all information related to you. You agree that none of the Released Entities will be liable to you or any third party for any termination or suspension of your account or for blocking your access to the Services.</p>
                            </li>
                        </ol>

                        <p className='h3 fw-bold'>Representation and Warranties</p>
                        

                        <ol>
                            <li>
                                <p>With agreeing on the terms and provisions of this Agreement, Users represent and warrant the Company that the User is lawfully able to enter into an agreement and use the services. If a User is signing this Agreement on behalf of an entity, the individual accepting this Agreement represents and warrants to the Company that User has the authority to bind the entity to this Agreement and agrees on the entity&rsquo;s behalf to be bound by this Agreement.</p>
                            </li>
                        </ol>

                        <p className='h3 fw-bold'>Miscellaneous</p>
                        

                        <ol>
                            <li>
                                <p>If any provision of these Terms and Conditions is found to be invalid, unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions.</p>
                            </li>
                        </ol>

                        <ol start="2">
                            <li>
                                <p>These Terms and Conditions, which includes the Privacy Policy and Disclaimer, represent the entire agreement between you and the Company relating to your right to access and use the Services, and supersedes any and all prior or written or oral agreements between you and the Company with respect to such subject matter.</p>
                            </li>
                        </ol>

                        <ol start="3">
                            <li>
                                <p>Any failure on Our part to exercise any provision or right under these Terms of Use, shall not constitute a waiver by Us of that provision or right.</p>
                            </li>
                        </ol>

                        <ol start="4">
                            <li>
                                <p>No waiver by the Company of any breach or default by you under these Terms and Conditions shall be deemed to be a waiver of any preceding or subsequent breach or default. Any waiver by the Company must be in writing signed by the Company.</p>
                            </li>
                        </ol>

                        <ol start="5">
                            <li>
                                <p>Regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Services or these Terms and Conditions must be filed within six months after such claim or cause of action arose or will be forever barred.</p>
                            </li>
                        </ol>

                        <ol start="6">
                            <li>
                                <p>The User expressly undertakes to provide to the Company only correct and valid information while requesting for any services under this agreement, and not to make any misrepresentation of facts at all. Any default on part of the User would vitiate this agreement and shall dis-entitle the User from availing the services from Us.</p>
                            </li>
                        </ol>

                        <ol start="7">
                            <li>
                                <p>In case the Company discovers or has reasons to believe at any time during or after receiving a request for services from the User that the request for services is either unauthorized or the information provided by the User or any of them is not correct or that any fact has been misrepresented by him, the Company in its sole discretion shall have the unrestricted right to take any steps against the User(s), including cancellation of any pending requests, etc. without any prior intimation to the User. In such an event, we shall not be responsible or liable for any loss or damage that may be caused to the User or any of them as a consequence of such cancellation of requests or any other booking confirmed on the website/cloud application.</p>
                            </li>
                        </ol>

                        <ol start="8">
                            <li>
                                <p>The User unequivocally indemnifies the Company/ Triptopper of any such claim or liability and shall not hold Us responsible for any loss or damage arising out of measures taken by Us for safeguarding its own interest and that of its genuine customers. This would also include Us denying/canceling any bookings on account of suspected fraud transactions.</p>
                            </li>
                        </ol>

                        <ol start="9">
                            <li>
                                <p>No provision of these Terms and Conditions provides any person or entity not a party to these Terms and Conditions with any remedy, claim, liability, reimbursement or cause of action, or creates any other third party beneficiary rights, except as expressly set forth in these Terms and Conditions.</p>
                            </li>
                        </ol>









                    </div>
                </div>
            </div>
        </div>
    )
}

export default Termsofuse;