import '../../App.scss';
import React,{useState,useRef, useEffect} from 'react';
import Autocomplete from 'react-google-autocomplete'
import axios from 'axios';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import Itinedit from './Myitin';
import { MdEditLocation } from 'react-icons/md';

function EditLocation(props) {

  const location = useLocation();
  const navigate = useNavigate();
  
  const [labeltext,Setlabeltext] = useState("Destination")
  const [selectedlocation,Setselectedlocation] = useState(props.selectedlocation)
  const [shownextbutton, setShowNextButton] = useState(false);

  const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyDRQlEEA7ToMtEdj3l-BJSGJUfYQTdwl28';
  const AutoCompletetextboxref = useRef(null);
  
  useEffect(()=>{
    AutoCompletetextboxref.current.focus();
  },[])
  
  const  onPlaceSelected =(selectedcity)=>{
    if(selectedcity!= null && selectedcity.geometry!=null)
    {
      const placeid = selectedcity.place_id;
      const fulladdress = selectedcity.formatted_address;
      const locdata = selectedcity.geometry.location;
      const latitude = locdata.lat();
      const longitude = locdata.lng();

        
      var _loc =  { 
        "type": "Point",
        "coordinates": [longitude,latitude]
                  };
      var _location = {
        loc:_loc,gid:placeid
        ,fulladdress:fulladdress};

     props.setlocation(_location);
    
  
    }
  }

  function handleChange(e)
  {
    e.preventDefault();

    if(e.target.value == ''){
      console.log("Nullify location!!");
      props.setlocation(null);
    }
  }
    
  return (
    
        <div>
              <p className='fw-bold ms-4'>{labeltext} </p>
              <Autocomplete className={props.customclass +" bg-light form-control form-control-lg p-4"} 
                  //ref={(ref) => { this.map = ref; }}
                  ref={AutoCompletetextboxref}
                  defaultValue={selectedlocation?selectedlocation.fulladdress:''}
                  placeholder='Search' 
                  apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: [] },
                  }}
                  onPlaceSelected={onPlaceSelected}
                  onChange={handleChange}/>
              
              <br></br>
              {/*
              <Autocomplete placeholder='place' className='form-control'
                  apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                  style={{ width: "100%" }}
                  onPlaceSelected={(place) => {
                    console.log("*** PLACE SELECTED ***")
                   
                    console.log(pyrmont);
                  }}
                  options={{
                    location:pyrmont,
                    types: ['food','lodging'],
                    componentRestrictions: { country: "in" },
                  }}
                />
                
                */} 
              

    </div>
  )
  
}

export default EditLocation;
