import { useEffect, useState } from "react";
import UsertypeSelector from "./UsertypeSelector";
import Authservice from "./Authservice";
import axios from "axios";
import { getaccountdetails, updateusertype } from "../api/itin";
import { useNavigate } from "react-router";

const Knowyou = () => {
    const navigate = useNavigate();
    const [userdetails, setUserdetails] = useState({});

    useEffect(()=>{
        var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        axios.get(getaccountdetails).then(res=>{
          if (res.data.success) {
            setUserdetails(res.data.userdata);
          }
        }).catch(err=>{
        console.log(err);
        })
    },[]);

    const SetUsertype = (value) => {
        setUserdetails({...userdetails, usertype: value});
    }

    const SaveUserdata = () => {
        if(userdetails.usertype){
            var headertoken = Authservice.getheadertoken();
            axios.defaults.headers.common['Authorization'] =    
                'Bearer '+ headertoken;
            axios.post(updateusertype, {details:userdetails})
            .then(res=>{
            if (res.data.success) {
                const token = res.data.token;
                if(token){
                  Authservice.setfacebooktoken(token);
                  navigate('/');

                  //reload
                  window.location.reload(); 
                }
            }
            }).catch(err=>{
            console.log(err);
            })
        }
    }

    const navigateToHome = () => {
      navigate('/');
    }

    return (
      <div className=''>
        <div className='container'>
          <div className='row p-4'>
            <div className='col-sm-12'>
              <p className='hero2'> Tell us more about you?
            <button className='btn btn-light float-end' onClick={navigateToHome}>X</button></p>

              <hr></hr>
              <div className="row p-4">
                <UsertypeSelector usertype={userdetails.usertype} setUsertype={SetUsertype}/>
              </div>

                <button className='m-4 btn btn-primary' onClick={SaveUserdata}>Save</button>

            </div>
          </div>
        </div>
      </div>
         
  );
};

export default Knowyou;