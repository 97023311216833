import React,{useState,useEffect,useRef} from 'react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';
import Itin from '../User/Itin';
import PublicProfile from '../PublicProfile';
function PreviewModal(props) {
 
  if(props.type ==="ITIN")
  {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
            <span className='fw-bold me-2'>Preview </span>

        </Modal.Header>
        <Modal.Body className="show-grid">
          <div>


          <Itin id={props.nanoid}></Itin>          
          </div>

        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  else if(props.type==='PROF'){

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className='fs-4 fw-bold'>Preview</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          
          <PublicProfile id={props.handle}></PublicProfile>          
        
        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  else{
    return(
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        <p className='fs-4 fw-bold'>Preview</p>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
      
        <p className='text-center'>No preview</p>       
    
    
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Close</Button>
    </Modal.Footer>
  </Modal>)
  }
}
  export default PreviewModal;
