import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { presignedurl } from '../../api/itin';
import Authservice from '../Authservice';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';

const UNSPLASH_ACCESS_KEY=process.env.REACT_APP_UNSPLASH_ACCESS_KEY;

function SelectImageModal(props) {
  
  const SearchImage = () => {
    const [input, setInput] = useState(props.input);
    const [imagesResult, setImagesResult] = useState([]);

    useEffect(() => {
      serachResult();
    },[])

    const onClickSetImage = (data) => {
        var img = data.urls.regular;
        // var img = data.urls.small;
        var imgBy = {
            name: data?.user?.name,
            instagram: data?.user?.instagram_username,
            unsplash: data?.user?.links?.html
        }
        props.handleSearchImageData({
            img,
            imgBy
        });
        setInput('');
        setImagesResult([]);
        props.onHide();
    }

    const serachResult = async () => {
      if(input){
        try {
          var response = await axios.get(`https://api.unsplash.com/search/photos?page=1&query=${input}&client_id=${UNSPLASH_ACCESS_KEY}`)
          setImagesResult(response.data.results);
        } catch (error) {
            console.log(error);
        }
      }
    };

    const onSearch = (event) => {
      if (event.key === 'Enter' && input !== '') {
            serachResult();
        }
    }

  return (<Row>
          <div className='mb-5 mt-2'>
          <input className='form-control' placeholder='search' onKeyDown={onSearch}
          onChange={(e) => setInput(e.target.value)} value={input} />
          </div>
          
          {imagesResult.map((item,index)=>{
                      return(
                        <div key={index.toString()} 
                        className='col-sm-4 col-md-4 text-center mb-5 w-50'>
                          <div>
                            <a onClick={()=> onClickSetImage(item)}>
                            <img
                            className="unsplash-search-img shadow-sm"
                            src={item.urls.regular}
                            alt={item.alt_description} /> </a>
                        <div class="unsplash-text">Photo by <a className='unsplash-link' href={item?.user?.links?.html} target="_blank">{item?.user?.name}</a> on <a className='unsplash-link' href="https://unsplash.com/?utm_source=your_app_name&utm_medium=referral" target="_blank">Unsplash</a>
                        </div>
                          </div>
                      </div>
                      )
                  })}
            </Row>)
  }

  const UploadImage = () => {
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [crop, setCrop] = useState({
      unit: 'px', 
      x: 0,
      y: 0,
      width: 200,
      height: 150,
      // aspect: 4 / 3 
    })
    const [src, setSrc] = useState(null);
    const [image, setImage] = useState(null);
  
    const selectImage = (file) => {
        setSrc(URL.createObjectURL(file));
    };
  
  const  getCroppedImg = () => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width*scaleX,
      crop.height*scaleY,
    );
    
    canvas.toBlob(
        (blob) => {
          uploadBySignedUrl(blob)
        },
        "image/jpeg",
        0.8,
      ); 
  }
  
    const uploadBySignedUrl = async (blob) => {
      try {
        setLoadingIndicator(true);
  
        if(blob){
          var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
        'Bearer '+ headertoken;
        const response = await axios.post(presignedurl,{imagetype: props.imagetype});
  
        if(response.data.success){
          const { url, img } = response.data.data;
  
          const s3response = await fetch(url,{
            method: 'PUT',
            body: blob,
            headers:{
              "Content-Type": "image/jpeg"
            }
          } );
          if(s3response.status === 200 && s3response.url){
            props.handleSearchImageData({img: img})
            setLoadingIndicator(false);
            setImage(null);
            setSrc(null);
            props.onHide()
          }
        }
        }
        setLoadingIndicator(false);
      } catch (error) {
        console.log("presignedurl",error)
        setLoadingIndicator(false);
        setImage(null);
        setSrc(null);
        alert('Failed');
      }
    }; 

    return (<Row>
      <div className='col mb-4'>
        {loadingIndicator ? <h2 className='d-flex justify-content-center mt-2'>Uploading...</h2> : 
        <>
            <div class="">
              <label for="formFile" class="form-label">Upload image from your device</label>
              <input class="form-control" id="formFile" type="file" accept="image/*"
            onChange={(e) => {
                selectImage(e.target.files[0]);
            }}/>
            </div>
         <br />
         <br />
          <div>
            {src && (
                <div className='d-flex justify-content-center'>
                    <ReactCrop 
                    crop={crop} 
                    onChange={setCrop}
                    aspect={4/3}
                    keepSelection={true}
                    // locked={true}
                    // minWidth={200}
                    minHeight={150} 
                    > 
                        <img style={{maxHeight: 400}} src={src} onLoad={(event) => setImage(event.target)} />
                    </ReactCrop>
                    <br />
                    <br />
                </div>
            )}
        </div>
        {<div className='mt-2 d-flex justify-content-center'>
          <Button variant='outline-primary' onClick={getCroppedImg} disabled={!src}>Upload</Button>
        </div>}
      </>}
    </div>
    </Row>)
  }

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <center>Add an image</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
          <Tabs defaultActiveKey="imagesearch" className="mb-3" justify>
            <Tab eventKey="imagesearch" title="Search">
                <SearchImage />
            </Tab>
            <Tab eventKey="imageupload" title="Upload">
                <UploadImage />
            </Tab>
          </Tabs>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  export default SelectImageModal;
