import '../../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef,forwardRef} from 'react';


function Note(props) {
    const cancel = () =>
    {
        props.cancel();
    }
    if(props.isedit)
    {
        return (
            <ShowEditNote item={props.item} done={props.done} ></ShowEditNote>
        )
    }
    else
    {
       return(
        <div>
            <p className='m-1 small'><span>{props.item}</span><button id='' className='btn btn-link btn-sm mb-1 nodecor text-sm' onClick={()=> props.edit()} >{props.item?'Edit':'+ Add a note'}</button>
            </p> 
        </div>
       )
    }
 
   
        
      
}

const ShowEditNote= (props)=>
{
    const [note,Setnote] = useState(props.item?props.item:'');
    const [charactersleft,Setcharactersleft] = useState(props.item?500-props.item.length:500);
    const noteRef =  useRef();
    function handleNoteChange(e){
        if(e.target.value.length <501){
            Setnote(e.target.value);
            var charLeft = 500 - parseInt(e.target.value.length);
            Setcharactersleft(charLeft);
        }
   };
   function handleDone(){
    props.done(note)
   }
    useEffect(()=>{
        noteRef.current.focus();

    },[])
    return (<div className='row p-2' >
    <div className='col-sm-12 p-3 text-center' >

        <textarea ref={noteRef} type="text"  name='details' value={note} 
        placeholder='Add recommendations/instructions/hacks like how to reach, best time to visit, what to pack, what to wear, must try dishes and more.. ' 
        onChange={handleNoteChange} 
        className='form-control custom-small-10px border border-light' 
        rows='5' maxLength={500} />
        <p className='custom-sm-8px text-end'>{charactersleft}</p>

        <button className='btn btn-primary m-1' onClick={()=> handleDone()} > Done</button>
    </div>
</div>);
}

export default Note;
