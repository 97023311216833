import '../../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import GoogleSearchAutocomplete from '../GoogleSearchAutocomplete';

import Activity from './Activity';
import ico_add from '../../icons/add.png';
import ico_ty_1 from '../../icons/restaurant.png';
import ico_ty_2 from '../../icons/pointofinterest.png';
import ico_ty_3 from '../../icons/hotel.png';
import { act } from 'react-dom/test-utils';



function ActivityBlock(props) {
    const [showGoogleSearchBar,SetshowGoogleSearchBar] = useState(false);
    
    const [gtype,Setgtype] = useState([]);
    const [gcountry,Setgcountry] = useState("");
    const [gplaceholder,Setgplaceholder] = useState("Search");
    const [timeofday,Settimeofday] = useState(props.timeofday);
    const [activities,Setactivities] = useState(props.day.activities);
    const [activitytype,Setactivitytype] = useState(null);
    function handleOnDragEnd(result) {
        if (!result.destination) return;
    
        const items = Array.from(activities);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        Setactivities(items);
        const neworder  = items.map((item,index) => {
            return {
              activity: item._id,
              td: item.td,
              ty:item.ty,
              note:item.note,
              order:index+1
          
            }
          });
          
    }

    const cancelGoogleSearch = ()=>{
        SetshowGoogleSearchBar(false);
    }
    
  
    const showgoogleSearch=(gtype,gcountry,activitytype)=>{

        if(showGoogleSearchBar){
            SetshowGoogleSearchBar(false);
        }
        else{
            var gtypearray = gtype?gtype.split('|'):[];
            Setgtype(gtypearray);
            Setgcountry(gcountry);
            if(activitytype){if(activitytype === 1){ Setgplaceholder("Search..")}
            else if(activitytype===2){Setgplaceholder("Search for places to visit or activities")}}
            Setactivitytype(activitytype)
            SetshowGoogleSearchBar(true);
        }
    }
    
    const addActivity = (item)=>{
       const result = props.addActivity(item,timeofday,activitytype);
       SetshowGoogleSearchBar(false)
    }
    
    const deleteActivity = (id,td,ty)=>{
        props.deleteActivity(id,td,ty);
      
       
    }

    const ControlView = ()=>{
        if(showGoogleSearchBar){
            return (
                <div className='pt-3 bg-gray'>
                <p className=' ms-4 pt-1 fs-6 fw-bold'>Add a place 
                <button className='btn float-end me-4 fw-bold text-center' onClick={cancelGoogleSearch}> X </button>
    
                </p>
    
                    <GoogleSearchAutocomplete 
                    cancel={cancelGoogleSearch} 
                    gtype={gtype}
                    placeholder={gplaceholder}
                    gcountry={gcountry}
                    add={addActivity} 
                    cname='form-control'></GoogleSearchAutocomplete>
                </div>
            )
        }else{
            return(
            <a className='btn btn-link btn-sm fw-bold m-2 nodecor' onClick={()=>showgoogleSearch('',[],1)}><span> + Add a place from google </span> </a>
            )
        }
    }


        
    return(
        <div className={' pb-1 '+ props.cname}>
            <div className='p-2'> 
                    <img src={props.icon} className='i-md'></img>
                    <span className='p-2 fw-bold'>{props.label}</span>
                    {/*
                        <a className='float-end me-1 btn btn-primary btn-sm fw-bold ' onClick={()=>showgoogleSearch('tourist_attraction','in',2)}><span> + Activity</span>  </a>
                        <a className='float-end me-4 btn btn-primary btn-sm fw-bold ' onClick={()=>showgoogleSearch('restaurant|bakery','in',1)}> + Eat </a>
                    */}
            </div>
            <div className='col-sm-12 col-md-10 offset-md-1'>

                        {
                                    activities?.map((item,index)=>{
                                        return (
                                            
                                            <Draggable key={item.activity._id.toString()} draggableId={item.activity._id.toString()} index={index}>
                                                {(provided) => (
                                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <Activity dayid={props.day._id} key={item.activity._id.toString()} delete={deleteActivity} index={index} item={item} ></Activity>
                                                    </li>)
                                                }
                                            </Draggable>
                                        );
                                    })
                        }
                                
            </div>
        </div>
        )
    
    
}


      


export default ActivityBlock;
