import '../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Authservice from './Authservice';
import { getQuery, setopened } from '../api/itin';
import ico_email from '../icons/email.png'; 
import ico_call from '../icons/call.png';

function Inbox(props) {
  const [items,Setitems] = useState(null);
  const navigate = useNavigate();

  const onItemClick = (item) => {
    if(item){
      setOpened(item._id, true);
    }
  }

  const ItemsView = ()=>{
    if(items && items.length >0){
      return(
        items.map((item,index)=>{
            return <NewItem item={item} index={index} isopned={item.opnd} />
        })
      )
    }else{
      return (
      <h5 className='text-center'>No items found.</h5>
      )
    }
  }

  // const OpenedItem = (props)=>{

  //   return(
  //     <div className='bg-gray align-items-center'>
  //       <div className='p-2'>
  //         <p className='fs-4 fw-bold'>{props.item.details.name}</p>
  //       </div>
  //     </div>
  //   )
  // }

  const NewItem = (props)=>{ 
    return(
      <div className={`${props.isopned ? 'border-bottom' : 'bg-gray'} align-items-center`}>
        <div className='p-2'>
          <button className={`${props.isopned ? '' : 'fw-bold'} btn fs-4`}
          onClick={() => onItemClick(props.item)}>{props.item.details.name}</button>
        </div>
      </div> 
      
    )
  }

  const AccordionItem = (props)=>{
    const [isopned, setIsopned] = useState(props.item.opnd);

    const onClick = (item) => {
      if(!item.opnd){
        setOpened(item._id, true);
        setIsopned(true);
      }
    }

    function getDate(date) {
      var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

      var d = new Date(date);
      var itemtime =  d.getHours() + ":" + d.getMinutes();
      var itemdate = d.getDate()  + " " + (monthArr[d.getMonth()]) + " " + d.getFullYear();

      var today = new Date();
      var todaysdate = today.getDate()  + " " + (monthArr[today.getMonth()]) + " " + today.getFullYear();

      if(itemdate === todaysdate){
        return itemtime;
      } else{
        return itemdate;
      }
    };

    return(<div className={`${isopned? '' : 'bg-gray'} mb-2 pb-1 border border-top-0 rounded`}>
        <button 
        className= 'btn list-group-item-action'
          onClick={() => onClick(props.item)}
          data-bs-toggle="collapse" 
          data-bs-target={"#collapseOne" + props.item._id}
          aria-expanded="false" 
          aria-controls={"collapseOne" + props.item._id}
          >
          <div className={`${isopned ? '' : 'fw-bold'}`} >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">{props.item.itin.title}</h5>
              <small>{getDate(props.item.createdt)}</small>
            </div>
            <small>{props.item.details.name}</small>
          </div>
        </button>
        
        <div id={"collapseOne" + props.item._id} className="accordion-collapse collapse" aria-labelledby={"headingOne"+ props.item._id}data-bs-parent="#accordionFlushExample">
          <ItemDetailsView item={props.item}/>
        </div>
        
        {/* <Collapse in={show}>
        <div id={"example-collapse-text"+props.item._id}>
          <ItemDetailsView item={props.item}/>
        </div>
        </Collapse> */}
        </div>
    )
  }

  const ItemDetailsView = ({item})=>{
      var itin = item.itin;

      const status = () => {
        if(item.status === 'PEND'){
          return <p className='fs-5 text-warning'>PENDING</p>
        } else{
          return <p className='fs-5'>{item.status} </p>
        }
      }
      return(
                <div className='p-3 pt-0' key={itin._id.toString()}>
                  <div className='m-2 divider'></div>
                    <div className='row'>
                      {status()}
                      <div className="col-sm">
                        <h6 className="fs-5 m-2">Itinerary details</h6>
                        <hr className='m-2'/>
                        <p className='fs-5 scolor-black m-2'>{itin.title}</p>
                        <p className='small m-2'>{itin.days.length} - day</p>
                        <p className='small m-2'>{itin.location.fulladdress}</p>
                        <button className="btn btn-outline-primary me-2" onClick={()=>{navigate("/app/myitin/"+itin._id)}}>Go to Itinerary</button>
                      </div>
                      <div className="col-sm">
                        <h6 className="fs-5 m-2">Customer details</h6>
                        <hr className='m-2'/>
                        <p className='fs-5 scolor-black m-2'>{item.details.name} </p>
                        <p className='fs-6 m-2'><img className='i-sm me-1' src={ico_email}></img>  {item.details.email} </p>
                        <p className='fs-6 m-2'><img className='i-sm me-1' src={ico_call}></img>   {item.details.phone} </p>
                          <button className="btn btn-outline-primary me-2">Call</button>
                          <button className="btn btn-outline-primary me-2">Email</button>
                      </div>
                    </div>
              </div>
      )
  }

  const setOpened = (_id, opnd) => {
    var headertoken = Authservice.getheadertoken();
    axios.defaults.headers.common['Authorization'] =    
          'Bearer '+ headertoken;
    axios.post(setopened, {_id: _id, opnd: opnd})
      .then(res=>{
        if (res.data.success) {
          // var updatedItems = items.map((item) => {
          //   if(item._id === _id){
          //     return {...item, opnd: opnd}
          //   } else{
          //     return item;
          //   }
          // });
          // Setitems(updatedItems)
        }
        else{
          console.log("error setiing opened")
        }
      })
      .catch(err=>{
    })
  }

  useEffect(()=>{
    
    var headertoken = Authservice.getheadertoken();
    axios.defaults.headers.common['Authorization'] =    
          'Bearer '+ headertoken;
    //getprofiledata
    axios.get(getQuery)
      .then(res=>{
        if (res.data.success) {
          Setitems(res.data.items);
        }
        else{
          console.log("error fetching queries")
        }
      })
      .catch(err=>{
    })
    
  },[])
    
  if(items){
    return (
      <>
        <div className='container'>
          <div className='row p-4'>
            {/* <div className='col-sm-12 col-md-10 offset-md-1'> */}
            <div className='col-sm-12'>
              <p className='hero2'> Inbox</p>
              <hr></hr>
              {/* <ul className="list-group list-group-flush"> */}
              <div class="accordion" id="accordionFlushExample">
                {items.map((item,index) => <AccordionItem key={item._id} item={item} index={index}/>)}
              </div>
              {/* </ul> */}
            </div> 
          </div>
        </div>
      </>
    )  
  }else{
    <>
      <p>Loading...</p>
    </>
  }

}





export default Inbox;
