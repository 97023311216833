import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';
import TopBlock from '../User/TopBlock';

function SetThemeModal(props) {
    var themes = [{name:'theme-purple'}
    ,{name:'theme-gray'}
    ,{name:'theme-mountain'}
    ,{name:'theme-yellow'}
    ,{name:'theme-dark'}
    ,{name:'theme-orange'}
    ,{name:'theme-blue-dark'}
    ,{name:'theme-coolsky'}
    ,{name:'theme-shifter'}
  ]
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <center>Set theme</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container><Row>
          {themes.map((item,index)=>{
                        return(
                          <div key={index.toString()} className='col-sm-12 col-md-4 text-center mb-5'>
            <div className={item.name+' theme-box mb-2'}></div>
            <Button variant='outline-dark' onClick={()=>{props.set(item.name);props.onHide()}}>Set theme</Button>
          </div>
                        )
                    })}
          
         
          
            </Row></Container>
          
         
        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  export default SetThemeModal;
