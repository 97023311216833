function Feedback(){

    return(
        <div className="pt-3">
            <div className="container">
                <div className="row">

                    <p className="text-center mt-4">You can always write to us [founder@triptopper.com] but filling this form will most likely to make sure that your valuable feedback is not lost in emails.</p>
                    <div className="col-sm-12">
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfnf7QuKxGgYfNmZeblBgQZCvWdrWmspagmNB1Hmc4TLMU43Q/viewform?embedded=true" width="100%" height="1200" frameborder="0">Loading…</iframe>

                    </div>

                </div>

            </div>
             

        </div>
    )
    
}
export default Feedback;
