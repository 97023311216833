import '../../App.scss';
import React, {useState } from 'react';
import {Link,useNavigate} from 'react-router-dom';


const CreateBlog = (props)=>{
    const navigate = useNavigate();
     

      console.log("Show itins")
      return(

            
        <div className=''>
            <div className="container mb-5 ">
                <div className='row'>
                    <div className="mt-1 ">
                        <p className='hero4 border-bottom'>Create Blog</p>
                    </div>
                </div>
            </div>
        </div>

                
            
      )
   
    
}
export default CreateBlog;
