import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';
import ico_pin from '../../icons/pin.png';
import ico_hotel from '../../icons/hotel.png';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useEffect, useRef } from 'react';

function ImageModal(props) {
  const dayRef = useRef([]);

  useEffect(() => {
    // var myref = dayRef.current[props.dayIndex];
    // if(myref){
    //     myref.scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'});
    //     myref.classList.add("flash");
    // }
    
  },[props]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // animation={false}
      className='fade-scale'
      contentClassName='bg-transparent border-0'>
        <Modal.Header closeButton className='border-0 btn-close-white'>
          
        </Modal.Header>
        <Modal.Body className="p-0">
          <center>
            <img className='itinImg-md shadow-lg' src={props.img} />
          </center>
        </Modal.Body>
      </Modal>
    );
  }
  export default ImageModal;
