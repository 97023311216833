import '../App.scss';
 
import React from 'react';
import {Link} from 'react-router-dom';

function CallToAction() {
  return (
    <div className="container">
      <div className='row align-items-center'>
        <div className='col-sm-12 col-md-10 offset-md-1 p-5 rounded-pill'>
          <center>
            <p className='h3'> Create your first Itinerary now, It's Free</p>
            <br></br>
            <p> Write about your experience, your favourite places.</p>
          
        <div>
        <Link className='btn btn-primary' to="/app/SignIn"><h3> Get Started</h3></Link>

        </div>
        </center>

    </div>
    </div>
    </div>
  );
}

export default CallToAction;
