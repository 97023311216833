import axios from "axios";
import { useState } from "react";
import { createlead } from "../api/itin";

const Leads = () => {
    const [email, setEmail] = useState();
    const [showEmailError, setShowEmailError] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const isMailValid = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setShowEmailError(true);
            return false;
        }
        else {
            setShowEmailError(false);
            return true;
        }
    };

    const onFormSubmit = () => {
        if(isMailValid(email)){
            axios.post(createlead,{email:email})
            .then((res) => {
                if(res.data){
                    setShowMessage(true);
                }
                }).catch(err => { console.log(err)
            });
        }

    }

    return(
        <div className='content-80'>
            <div className='container'>
                <div className='row' >
                    <center>
                        {!showMessage ? 
                        <div className='col-sm-4'>
                            <h1>Tell us about you!</h1>
                            <div class="mb-4 form-group text-start">
                                <label htmlFor="exampleInputEmail1" className="text-muted m-1">Enter email</label>
                                <input type="email" class="form-control" id="inputemail1" aria-describedby="emailHelp" placeholder="name@example.com" 
                                value={email} onChange={(e) => setEmail(e.target.value)}/>
                                {showEmailError && <small class="form-text text-danger">Must be a valid email</small>}
                            </div> 
                            <button type="button" class="mb-4 mt-4 btn btn-primary fw-bold" onClick={onFormSubmit}>Submit</button> 
                        </div> 
                        : <div className='col-sm-4'>
                        <h1>Thank you! Your record has been submitted.</h1>
                        </div>}
                    </center>
                </div>
            </div>
        </div>
    )

};

export default Leads;