import '../../App.scss';
import axios from 'axios';
import Note from './Note';
import React,{useState,useEffect,useRef} from 'react';
import { addnote, deletenote } from '../../api/itin';
import Authservice from '../Authservice';

import ico_delete from '../../icons/delete.png';
import ico_dots from '../../icons/dots.png';
import ico_drag from '../../icons/drag.png';

import ico_ty_3 from '../../icons/hotel.png';
import {GetGoogleTypeIcons,GetIconPrediction} from '../GetGoogleTypeIcons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageModal from './ImageModal';


function Activity(props) {
    const [activity,Setactivity] = useState(props.item);
    const [iseditnote,Setiseditnote] = useState(false);
    const [openImage, SetopenImage] = useState(false);
    /*
    const geticon = ()=>{
      switch(activity.ty){
        case 1:
          return ico_ty_1;
        case 2:
          return ico_ty_2;
        case 3:
          return ico_ty_3;
        default:
          return ico_ty_2;
      }

    }
    */


    const editNote = ()=>{   
        Setiseditnote(true);
    }
    
   
    const cancelNote =(note)=>{
        Setiseditnote(false);
    }

    const doneNote = (note)=>
    {
     
      var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
              'Bearer '+ headertoken;
        axios.post(addnote,{dayid:props.dayid
            ,activityid:activity._id,note:note})
          .then(res=>{
            if (res.data.success ) {
                // Setactivity({...activity,note:note});
                props.updateAfterNoteAdded({...activity,note:note})
                Setiseditnote(false);
            }
            else{
              return false;
            }
          }).catch(err=>{
            return false;
          })
        
        //else{Setiseditnote(false);}
      
    }
    
    switch(activity.label){
      case 'heading':
            return (
              <div className='d-flex mb-3'>
                  <div className='mt-2 me-2'>
                    <OverlayTrigger placement="bottom" 
                        overlay={<Tooltip id="tooltip" className='text-sm'>Drag</Tooltip>}>
                      <img src={ico_drag} className='i-sm' {...props.dragHandle}></img>
                    </OverlayTrigger>
                  </div>
                
                  <div className='w-100 px-2'>
                    <p className='mb-1'>
                      <button id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" className='btn float-end'><img className='i-xs' src={ico_dots}></img></button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a onClick={()=>props.delete(activity._id)} className="dropdown-item">Delete</a></li>
                      </ul>
                      <a className='d-none btn' onClick={()=>props.delete(activity.activity._id,activity.td,activity.ty)}><img src={ico_delete} className='i-sm'></img></a>
                      <h6 className='fw-bold fs-5 mt-2'>{activity.heading}</h6>
                    </p>  {/*<span className='ms-4 text-sm'>{activity.fulladdress}</span>*/}
                    <Note edit={editNote} cancel={cancelNote} done={doneNote} item={activity.note} isedit={iseditnote}></Note>
                  </div>
              </div>
            )

      case 'place':
    
            return (
                <div className='d-flex mb-4'>
                  <div className='mt-2 me-2'>
                   <OverlayTrigger placement="bottom" 
                        overlay={<Tooltip id="tooltip" className='text-sm'>Drag</Tooltip>}>
                      <img src={ico_drag} className='i-sm' {...props.dragHandle}></img>
                    </OverlayTrigger>
                  </div>
                
                <div className='w-100 bg-light border border-light rounded-3 px-2'>
                  <p className='mb-1 mt-1'>
                    <GetIconPrediction name={activity.activity.name} ></GetIconPrediction>
        
                    <GetGoogleTypeIcons item={activity.activity.placetypes} name={activity.activity.name} ></GetGoogleTypeIcons>
                    <button id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" className='btn float-end'><img className='i-xs' src={ico_dots}></img></button>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a onClick={()=>props.delete(activity._id)} className="dropdown-item">Delete</a></li>
                    </ul>
                    <a className='d-none btn' onClick={()=>props.delete(activity.activity._id,activity.td,activity.ty)}><img src={ico_delete} className='i-sm'></img></a>
                    <a className='btn-sm nodecor fw-bold fs-6 color-black' target='_blank' href={"https://www.google.com/maps/search/?api=1&query="+activity.activity.fulladdress+"&query_place_id="+activity.activity.gid}>&nbsp;{activity.activity.name+ '- '+activity.label}</a>
                  </p>  {/*<span className='ms-4 text-sm'>{activity.fulladdress}</span>*/}
                  <Note edit={editNote} cancel={cancelNote} done={doneNote} item={activity.note} isedit={iseditnote}></Note>
                </div>
                </div>
                
            )  

      case 'image':
            return (
              <div className='d-flex'>
                <ImageModal show={openImage} onHide={() => SetopenImage(false)} img={activity.img}/>
                  <div className='mt-2 me-2'>
                    <OverlayTrigger placement="bottom" 
                        overlay={<Tooltip id="tooltip" className='text-sm'>Drag</Tooltip>}>
                      <img src={ico_drag} className='i-sm' {...props.dragHandle}></img>
                    </OverlayTrigger>
                  </div>
                
                  <div className='w-100 px-2'>
                    <p>
                      <button id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" className='btn float-end'><img className='i-xs' src={ico_dots}></img></button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a onClick={()=>props.delete(activity._id, activity.img)} className="dropdown-item">Delete</a></li>
                      </ul>
                      <a className='d-none btn' onClick={()=>props.delete(activity.activity._id,activity.td,activity.ty)}><img src={ico_delete} className='i-sm'></img></a>
                      <div className=''>
                          <div className='itinImg-container shadow-sm w-50 cursor-pointer' >
                            <img className='itinImg' src={activity.img} onClick={() => SetopenImage(true)}/>
                          </div>
                            {activity.imgBy && 
                            <div className='unsplash-text mb-1 w-50'>Photo by <a className='unsplash-link' href={activity.imgBy.unsplash} target="_blank">{activity.imgBy.name}</a> on <a className='unsplash-link' href="https://unsplash.com/?utm_source=your_app_name&utm_medium=referral" target="_blank">Unsplash</a>
                            </div>}
                            <Note edit={editNote} cancel={cancelNote} done={doneNote} item={activity.note} isedit={iseditnote}></Note>
                    </div>
                      
                    </p>  {/*<span className='ms-4 text-sm'>{activity.fulladdress}</span>*/}
                  </div>
              </div>
            )
    }
}

export default Activity;