import { Link } from "react-router-dom";
import {useState,useRef} from 'react';

import ico_share from '../../icons/share.png';
import ico_preview from '../../icons/eye.png';

import SharerModal from '../SharerModal';

function TopBlock(props) {
  const baseurl = "https://triptopper.com/";

  const [SharerUrl,SetSharerUrl] = useState(baseurl);
  const [SharerText,SetSharerText] = useState("Check this out!");
  const [SharermodalShow,SetSharermodalShow] = useState(false);
  const OpenSharerModal= (url,text)=>{
    SetSharerUrl(url);
    SetSharerText(text);
    SetSharermodalShow(true)
}
    return (
        <div ref={props.downloadref} className={'mt-4 shadow-lg radius-30 '+ (props.theme?props.theme:"theme-gray")
            + " " + (props.bgimage && "theme-dark")}
             style = {props.bgimage ?
              {
                height: 300,
                // transform: 'scale(1.2)',
              backgroundSize: 'cover',
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55) ), url(${props.bgimage})`,
              backgroundPosition: 'center',
           } : {}}>
          <SharerModal SharerUrl={SharerUrl} text={SharerText} show={SharermodalShow}
        onHide={() => SetSharermodalShow(false)}/>
        <div className='p-4 mt-4'>
         <div>
         <button className='d-none btn btn-sm btn-primary fw-bold ' onClick={props.downloadImage}>Download</button>

         <button onClick={() => OpenSharerModal("https://triptopper.com/i/"+props.itin.nanoid,props.itin.title)} className='btn border-light fw-bold m-2 float-end'>Share <img src={ico_share} className='i-sm'></img></button>

         </div>

          <p className='fs-3 fw-bold'> {props.itin.title}</p>
          <p className=''>
              <a className='nodecor small fw-bold' target='_blank' href={"https://www.google.com/maps/search/?api=1&query="+props.itin.location.fulladdress+"&query_place_id="+props.itin.location.gid}>
              {props.itin.location.fulladdress}</a></p>
          <span className='col-sm-12 fs-5 fw-bold'>{props.itin.days?props.itin.days.length:0} Day</span>
          <p className='fs-5 m-0 mt-2 fw-bold'>
            {(props.date && new Date(props.itin.startdate).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }))}
            </p>
          <div className='col-sm-12 fw-bold'><img className='i-sm me-1' src={ico_preview}></img>{(props.itin.viewscount? props.itin.viewscount:0)}</div>

          <GethandleInfo handle={props.itin.auth.handle} ></GethandleInfo>
        </div>
        </div>
    );
  }
  const GethandleInfo=(props)=>{
    if(props.handle && props.handle != '')
    {
      return(
      <p className="mt-3"><span className='col-sm-12'>By <a href={"/"+props.handle} className='fw-bold nodecor'>{props.handle}</a></span></p>
      )
    }
    else{

    }

  }
  export default TopBlock;
