import '../../App.scss';
import axios from 'axios';
import React,{useState,useEffect,useRef,forwardRef, useReducer} from 'react';
import Autocomplete from 'react-google-autocomplete';
import GoogleSearchAutocomplete from '../GoogleSearchAutocomplete';
import ViewActivityBlock from '../User/ViewActivityBlock';
import {addevent, deleteevent} from '../../api/itin';
import Authservice from '../Authservice';
import ico_sunrise from '../../icons/sunrise.png';
import ico_sunnoon from '../../icons/sunnoon.png';
import ico_sunset from '../../icons/sunset.png';
import ico_hotel from '../../icons/hotel.png';
import ico_place from '../../icons/i-location.png';
import ViewActivity from './ViewActivity';


const ViewDay = React.forwardRef((props,ref) => {
    const [day,Setday] = useState(props.item);
    //console.log(day);
    const getDateForDay = (date, day) => {
      var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
      return (new Date(new Date(date).getTime() + 24 * 60 * 60 * 1000 * day)).toLocaleDateString("en-US", options)
    }

    const GetLocationDetails = ()=>{
      if(day.location && day.location.fulladdress)
      {
        return(
      <a className='nodecor small fw-bold color-black' target='_blank' 
      href={"https://www.google.com/maps/search/?api=1&query="+day.location.fulladdress+"&query_place_id="+day.location.gid}>
              <img src={ico_place} className='i-sm me-2'></img>{day.location.fulladdress}</a>);
      }
      else{}
    }

    const GetWTS = ()=>{
      if(day.wts && day.wts.name){
        return(
          <a className='nodecor me-2 small color-black fw-bold' target='_blank' 
          href={"https://www.google.com/maps/search/?api=1&query="+day.wts.fulladdress+"&query_place_id="+day.wts.gid}>
              <img src={ico_hotel} className='i-sm me-2'></img>{day.wts.name}</a>);
        
      }
    }



    
   return (
          <div className='border-bottom mt-4' ref={ref}>
            
            <div className=''>  
              <p className='hero3 ms-4 mt-2 color-black'> Day {props.index +1 }
              <span className='fs-6 ms-2 fw-bold text-muted fst-italic '><small>{props.date && getDateForDay(props.date, props.index)}</small></span>
              </p>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='ms-4'>
                  <GetLocationDetails/>

                  </div>
                

                </div>
                <div className='col-sm-12 mb-5'>
                <div className='ms-4'>

                <GetWTS/>
                </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12'>  {/*<textarea  value={day.details} onChange={handleInputChange} className='form-control h1' rows='6' maxLength={300} /> */}
              <div className=''>
                {
                    day.activities?.map((item,index)=>{
                      return(<ViewActivity item={item} key={item._id}></ViewActivity>  )
                      
                    })

                }


                {/*
                                <ViewActivityBlock icon={ico_sunrise} key={2} addActivity={addActivity} deleteActivity={deleteActivity} timeofday={2} day={day} label='Morning' cname=''></ViewActivityBlock>

              <ViewActivityBlock icon={ico_sunrise} key={2} addActivity={addActivity} deleteActivity={deleteActivity} timeofday={2} day={day} label='Morning' cname=''></ViewActivityBlock>
              <ViewActivityBlock icon={ico_sunnoon} key={3} addActivity={addActivity} deleteActivity={deleteActivity} timeofday={3} day={day} label='Afternoon'></ViewActivityBlock>
              <ViewActivityBlock icon={ico_sunset} key={4} addActivity={addActivity} deleteActivity={deleteActivity} timeofday={4} day={day} label='Evening'></ViewActivityBlock>
               */}
              </div>
            
            </div>
           
            <br></br>
          
          </div>
                
    ) 
    
    
      
  }
)

export default ViewDay;
