import '../App.scss';
import logo from '../TT3.png';
import React, { Component, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import GoogleLogin from './GoogleAuth';
import FacebookLogin from './GetFacebookLogin';
import ico_email from '../icons/email.png';
import hero1 from '../hero1.png'
 
function SignIn() {
  const navigate = useNavigate();

  const [InstaBrowser,SetInstaBrowser] = useState(false);
  useEffect(()=>{
    var ua = navigator.userAgent || navigator.vendor || window.opera;  
    var isInstagram = (ua.indexOf('Instagram') > -1)|| (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) ? true : false;
    SetInstaBrowser(isInstagram);

  },[])
  const GetGoogleLoginView = ()=>{
    if(!InstaBrowser){
      return(
        <GoogleLogin/>
      )
    }
    else{
        return (<Link to='/app/inappbrowsersign' className='form-control nodecor text-center fw-bold'>Sign in with Google</Link>)
    }

  }

  const EmailSignIn = () => {
    return (
    <Link to="EmailLogin" className="mt-2 w-100 btn border p-2 p bg-white">
      <div className='d-flex'> 
     <img className='i-sm mx-1 align-self-center' src={ico_email}></img>
     <p className="flex-fill m-0 text-truncate small">Sign in with Email</p>
      </div>
    </Link>)
  }


  return (
  
    <div className='bg-light position-absolute top-0 bottom-0 start-0 end-0'>
        <div className="d-flex h-100">
        <div className='col-6 d-none d-md-block'>
        <Link to="/" className='position-absolute mt-4 ms-5 start-0 top-0'><img src={logo} className='App-logo'></img></Link>
        <img alt="" src={hero1} className="float-start mh-100 width-90"></img>
        </div>
            <div className='h-100 bg-light col-md-6 col-12 d-flex justify-content-center align-items-center'>
            <div className='d-flex flex-column col-md-5 col-10'>
              <p className='hero3 text-dark text-center'>Get Started</p>
              <div className='mt-4'>
                <GetGoogleLoginView></GetGoogleLoginView>
                <FacebookLogin/>
                <div className='text-center mt-3 mb-1 small'>OR</div>
                <EmailSignIn/>
              </div>
              <p className='text-sm gray mt-4 text-center'>By signing up, you agree to our <Link to='/app/termsofuse'>terms of service</Link> and <Link to='/app/privacypolicy'>privacy policy.</Link></p>
              </div>
            </div>
       
          </div>
        </div>

      

     
  );
}



export default SignIn;
