import '../App.scss';
import { Link } from 'react-router-dom';

import React from 'react';


function Privacypolicy() {
    return (
        <div className='mt-5 pt-5'>
            <div className='container'>
                <div className='row'>

                    {/*  
                        <div className='col-sm-12 text-center d-none mb-5'>
                            <iframe className='text-center' src="https://docs.google.com/document/d/e/2PACX-1vTiecP8rwFk5GevM7V0Vdh0W0SMnG0LbcQZcUTR7tCePM2UpheBxZ9BZX-H4i_teoQgaSs0LsCP6euo/pub?embedded=true"
                                width="98%" height="1200" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                        </div>
                    */}
                    <div className='col-sm-12 p-2 col-md-10 offset-md-1 mb-5'>
                        <p className='text-center hero3'>Privacy policy </p>
                        <p>Effective April 1,2023</p>
                        <p>These Terms of Service (&ldquo;Terms&rdquo;) govern access to and use of the Services made available by B.COMMERCE TECHNOLOGIES PRIVATE LIMITED including but not limited to&nbsp;<a href="https://www.triptopper.com/">https://www.triptopper.com</a>. 
                        By using our Services, you agree to be bound by these <Link to="/app/termsofuse">Terms</Link>. If you use our Services on behalf of an entity or organization, you are binding that organization to these Terms, and you represent that you have the authority to do so.</p>

                        <p>Welome to triptopper.com, We are on a mission to liberalize the travel experience and make it accessable on internet.</p>

                        <ol>
                            <li>
                                <p>This Privacy Policy shall be a legal binding agreement between you and&nbsp;Triptopper&nbsp;(the &lsquo;Company&rsquo;).&nbsp;</p>
                            </li>
                        </ol>
                        
                        <ol start="2">
                            <li>
                                <p>This document is an electronic record in terms of The Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                            </li>
                        </ol>
                        
                        <ol start="3">
                            <li>
                                <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, Privacy Policy and Terms of Use for access or usage of&nbsp;Triptopper,&nbsp;Website&nbsp;<a href="https://www.triptopper.com">https://www.triptopper.com</a> .</p>
                            </li>
                        </ol>
                        
                        <ol start="4">
                            <li>
                                <p>The domain name&nbsp;<a href="https://www.triptopper.com">https://www.triptopper.com</a> (&quot;Website&quot;), is owned and operated by&nbsp;Triptopper,&nbsp;having its registered office at A-9 A, Green Park Main, Green Park, New Delhi, Delhi,India 110016</p>
                            </li>
                        </ol>
                        
                        <ol start="5">
                            <li>
                                <p>Triptopper&nbsp;(&ldquo;Our&rdquo;, &ldquo;Us&rdquo;, &ldquo;We&rdquo;, &ldquo;Website&rdquo;, &ldquo;Site&rdquo;)&nbsp;Website and/or Cloud Application.&nbsp;&nbsp;</p>
                            </li>
                        </ol>
                        
                        <ol start="6">
                            <li>
                                <p>The expressions&nbsp;&ldquo;You&rdquo;, &ldquo;Your&rdquo; or &ldquo;User&rdquo;, whenever the context so requires, for the purposes of this Privacy Policy, shall mean any natural or legal person who may create by registration, membership account on this Website or cloud application or agree to avail Our Services through this Website or cloud application, or otherwise access Our Website or cloud application.</p>
                            </li>
                        </ol>
                        
                        <ol start="7">
                            <li>
                                <p>By accessing and/or using the website and/or cloud application, you signify your agreement to accept this privacy policy. If you do not agree with any or all of the following terms of the privacy policy, please do not access and/or use the website and/or cloud application. Providing information to us is your choice. You understand that any data, information, content or information that you submit to the website and/or cloud application will be stored on the company&apos;s servers for the purposes of making the website and/or cloud application available to you. Please do not submit to the website and/or cloud application any data, information, content or information which would include any personal information and sensitive personal data or information that you do not wish to be made available to the company. If you do not agree with any or all of the terms of this privacy policy, please do not provide any information to us. If at any time you wish to discontinue your access to the website and/or cloud application, you are free to do so. Further, if you require you can choose to have the personal information provided by you deleted by writing to&nbsp;<a href="mailto:contact@triptopper.com">contact@triptopper.com</a>&nbsp;</p>
                            </li>
                            <li>
                                <p>We reserve the right, at our sole discretion, to change or modify this privacy policy at any time without prior notice. Such changes and/or modifications shall become effective immediately upon being posted/published on the website and/or cloud application herein.</p>
                            </li>
                        </ol>
                        
                        <ol start="9">
                            <li>
                                <p>Please review the privacy policy from time-to-time. Your continued use of the website and/or cloud application following the posting of changes and/or modifications will constitute your acceptance of any revised privacy policy. The company retains the right at any time to deny or suspend access to all or part of the website and/or cloud application to anyone who the company believes has violated any condition of this privacy policy.</p>
                            </li>
                        </ol>
                        
                        <ol>
                            <li>
                                <p className='h5 fw-bold'>PERSONAL IDENTIFICATION INFORMATION</p>
                            </li>
                        </ol>
                        
                        <ol>
                            <li>
                                <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when users visit our site, fill out a sign-up/ registration form and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, phone number, etc. We will collect personal identification information from users only if they voluntarily submit such information to us. You agree to provide true, accurate, current and complete information about yourself as prompted by the registration form. If You provide information that is untrue, inaccurate, not current or incomplete, or We have reasonable grounds to suspect that You have provided such information, then Company may (in addition to any other rights or remedies available to us) refuse registration, suspend access to all current or future use of the Website or cloud application and the Services (or any portion thereof) permanently.</p>
                            </li>
                        </ol>
                        
                        <ol start="2">
                            <li>
                                <p>Users who are competent to contract under the Indian Contract Act, 1872, are eligible to register themselves as members on the Website or cloud application and can avail Our Newsletter Services. Persons who are competent to contract would mean and include every person who, (i) has completed 18 years of age (as per The Indian Majority Act, 1875); (ii) is of sound mind; and (iii) not disqualified from contracting by any law for the time being in force in India.</p>
                            </li>
                        </ol>
                        
                        <p>You may unsubscribe from the Newsletter by opting-out of it anytime or by reaching out to the Company on&nbsp;<a href="mailto:contact@triptopper.com">contact@triptopper.com</a>&nbsp;</p>
                        
                        <ol start="3">
                            <li>
                                <p>We will not sell, share or rent Your personal information to any third party or use Your email address/mobile number for unsolicited emails and/or SMS. Any emails and/or SMS sent by Us will only be in connection with the provision of agreed services &amp; products and this Privacy Policy. To carry out general marketing we would be sending out emails to users registered on Our Website and/or cloud application (or by any means and in any media, including, but not limited to, on Our Website and/or cloud application or through Our merchants or in advertisements/marketing campaigns / any form of audio or visual media or websites).</p>
                            </li>
                        </ol>
                        
                        <ol start="4">
                            <li>
                                <p>You hereby consent to the collection, storage, use, disclosure, transfer, processing of the personal information for the purposes set out herein. We may disclose your personal information in the circumstances set out below and you hereby consent to the same to be shared with:</p>
                                <ol>
                                    <li>
                                        <p>Any third-party service provider to whom disclosure is necessary to enable us to provide you with the services which you wish to access on or through the website or cloud application;</p>
                                    </li>
                                    <li>
                                        <p>Any person/entity to whom disclosure is necessary for accordance with applicable law;</p>
                                    </li>
                                    <li>
                                        <p>Any government or statutory authority or court of law or judicial forum to whom disclosure is necessary for accordance with applicable law;</p>
                                    </li>
                                    <li>
                                        <p>In circumstances we believe necessary or appropriate to respond to valid claims and legal process, to protect the property and rights of the Company, to protect the safety of the public or any person or user, or to prevent or stop any illegal, unethical or legally actionable activity;</p>
                                    </li>
                                    <li>
                                        <p>Any person/entity to whom disclosure is necessary to enable us to enforce our rights;</p>
                                    </li>
                                    <li>
                                        <p>To our associate companies, business partners, agents or third parties for the purposes of the services or for any other marketing and promotional activity undertaken by or on behalf of the Company;</p>
                                    </li>
                                    <li>
                                        <p>Any entity that legally acquires the Company or its website and/ or cloud application.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        
                        <ol start="5">
                            <li>
                                <p>We may also disclose your transaction based data (not personal identification information like Name, email id, Phone number, age, gender) i.e., only transactions which you made on the cloud application for further research and development and market analysis.</p>
                            </li>
                        </ol>
                        
                        <ol start="6">
                            <li>
                                <p>We understand the importance of data security and we want your browsing experience with us to be as safe as possible. We have implemented reasonable safeguards and precautions to protect your personal information. We do not sell, trade, or rent users&rsquo; personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
                            </li>
                        </ol>
                        
                        <ol start="7">
                            <li>
                                <p>Please understand that, while we strive to protect your personal data against potential risks and exposures, there is no absolute security in the online/ internet sphere. Therefore, you must not disclose any information on the website that is sensitive personal data or information. You understand that the transmission of information over the internet is not completely secure and there are risks associated with it. Although we strive to protect your personal information, we cannot guarantee the security of the same while it is being transmitted to our website or cloud application and any transmission is at your own risk.</p>
                            </li>
                        </ol>
                        
                        <ol start="8">
                            <li>
                                <p>Transactions on the Website or cloud application are secure and protected. Any information entered by the User when transacting on the Website or cloud application is encrypted to protect the User against unintentional disclosure to third parties.&nbsp;</p>
                            </li>
                        </ol>
                        
                        <ol start="9">
                            <li>
                                <p>The Company will never ask you and you must never provide sensitive personal data or information to the Company or to any person/entity representing the Company. Any disclosure of sensitive personal data or information shall be at your sole risk and without any liability to the Company (including its directors, key managerial personnel, officers and employees). You understand, acknowledge and agree that the Company or any other person acting on behalf of the Company shall not in any manner be responsible for the authenticity of the personal information or sensitive personal data or information provided by you to the Company.</p>
                            </li>
                        </ol>
                        
                        <ol start="10">
                            <li>
                                <p>You must not disclose your:</p>
                                <ol>
                                    <li>
                                        <p>Passwords;</p>
                                    </li>
                                    <li>
                                        <p>Physical, physiological and mental health condition;</p>
                                    </li>
                                    <li>
                                        <p>Sexual orientation;</p>
                                    </li>
                                    <li>
                                        <p>Medical records and history;</p>
                                    </li>
                                    <li>
                                        <p>Biometric information;</p>
                                    </li>
                                    <li>
                                        <p>National identification numbers;</p>
                                    </li>
                                    <li>
                                        <p>And such other sensitive personal data or information.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        
                        <ol start="11">
                            <li>
                                <p>Users must note that any information that is freely available or accessible in the public domain shall not be regarded as personal information or sensitive personal data or information for the purposes of this privacy policy and the Company shall not be obliged to take any measures to protect the same since the same is freely available in the public domain</p>
                            </li>
                        </ol>
                        <p>.</p>
                        <ol start="12">
                            <li>
                                <p>Please note that identity theft and the practice currently known as &quot;phishing&quot; are of great concern to the Company. We do not and will not, at any time, request your credit card information/debit card information/financial pin numbers and passwords.</p>
                            </li>
                        </ol>
                        
                        <ol start="13">
                            <li>
                                <p>The law called &ldquo;GDPR&rdquo; gives you certain rights regarding your personal data. Triptopper recognizes and will comply with GDPR and the rights associated with the GDPR, except as limited by the applicable law. You have the following rights:</p>
                            </li>
                            <li>
                                <p>Request access regarding your personal data. You are entitled to receive an explanation about the processing, storage, sharing, storage, security of your personal data. You may obtain information concerning how your personal data is processed.</p>
                            </li>
                            <li>
                                <p>Request correction of the personal data that we keep. You are entitled to request correction of your personal information that is incomplete or inaccurate.</p>
                            </li>
                            <li>
                                <p>Request erasure of your personal data. You are entitled to request the deletion or removal of your personal data when there is no good reason for us to continue processing it (also known as the &ldquo;Right to be forgotten&rdquo;). This right allows you to request the deletion of your personal data in certain limitations under the applicable law.</p>
                            </li>
                            <li>
                                <p>Right to Complain. You have the right to make a complaint at any time to the appropriate supervisory authority for data protection issues. Hence we kindly request that you contact us first and give us the chance to deal with your concerns before you apply to the supervisory authority. We will endeavor to deal with your request as soon as possible.</p>
                            </li>
                            <li>
                                <p>Request restriction of processing of your personal data. You are entitled to request restriction or suspension on the use or process of your personal information.</p>
                            </li>
                            <li>
                                <p>Right to Object. You are entitled to object to the process and use of your personal data in certain circumstances. However, Triptopper reserves the right to not comply with such requests in case (i) Triptopper has compelling legitimate grounds for processing the personal data which override the interests, rights and freedoms of the data owner, or (ii) it is necessary for the establishment, exercise or defense of legal claims.</p>
                            </li>
                            <li>
                                <p>Request the transfer of your personal data to you or to a third party. You are entitled to receive the personal information we have about you and to transfer it to any third party.</p>
                            </li>
                            <li>
                                <p>Right to not become subject of Automated Decision-Making. You are entitled to object and prevent decisions and execution that could have a legal, in all probability, effect on you from being made solely based on automated processes. You are entitled to request not to be subject to a decision solely based on automated processing, including profiling which may produce legal effects about you or similarly significantly affects you. However, Triptopper reserves the right to disregard such request in case (i) it is necessary for entering into, or performance of, a contract between the data owner and a data controller, or (ii) Triptopper is authorized by the relevant country to which the Data Controller is subject to and which lays down suitable measures to safeguard the data owner&rsquo;s rights and freedoms and legitimate interests; or (iii) is based on the data owner&rsquo;s explicit consent.</p>
                            </li>
                            <li>
                                <p>You have the right to withdraw your consent about your processed data. However, this will not affect the lawfulness of any processing carried out before your withdrawal. If you withdraw your consent, we may not be able to provide certain products or services which require the collection, use, process of and other activities related to the Personal Data. Many of these rights can be exercised by signing in into the system and directly updating your account information. These rights can only be implemented if you use the Services and your personal data is processed. Please be aware that some of these rights may be unusable in certain circumstances as stipulated in GDPR or any applicable law. If you have questions or concerns about statements, policy or rights herein please do not hesitate to contact us at &nbsp;<a href="mailto:contact@triptopper.com">contact@triptopper.com</a>&nbsp;</p>
                            </li>
                            <li>
                                <p>You also have certain preference options while using Triptopper Services. These are:</p>
                            </li>
                            <li>
                                <p>You may update your account information and email-communication preferences at any time. You can also stop receiving promotional email communications from us by clicking on the&nbsp;&ldquo;unsubscribe link&rdquo;&nbsp;provided in such communications. We make every effort to promptly process all unsubscribe requests. As noted above, you may not opt out of Triptopper Services-related communications (e.g., account verification, purchase and billing confirmations and reminders, changes/updates to features of the Triptopper Services, technical and security notices). If you have any questions about reviewing or modifying your account information, you can contact us directly at&nbsp;<a href="mailto:contact@triptopper.com">contact@triptopper.com</a>&nbsp;</p>
                            </li>
                            <li>
                                <p>The purpose of this section is to inform you about how your Personal Data are processed in and transferred in and out of the EU.</p>
                            </li>
                            <li>
                                <p>For the purposes of applicable EU data protection and privacy laws, Triptopper is considered as the Data Controller in respect of all EU Personal Information that it collects, uses and otherwise processes for its own purposes as set out in this Privacy Policy. We recommend our customers located in the EU to read this Privacy Policy in full, and particularly this section, before you provide us with any Personal Information or browse our website, and to make sure that you will be in confidence and be comfortable with our privacy implementations. This is due to the fact that, when you accept this part of privacy provision or start using our services, you are accepted as agreed, declared and undertook that you have read and understood all of the articles herein, especially those prepared for European citizens and that you are willing to be bound by all provisions herein.</p>
                            </li>
                            <li>
                                <p>Please note that, for the purposes of EU data protection and privacy laws, &lsquo;personally&rsquo; identifiable information collected will only concern Personal Information which does not belong to any legal entity. All statements in this Privacy Policy relating to Personal Information will therefore apply to Personal Data of Customers under EU laws.</p>
                            </li>
                        </ol>
                        
                        <ol start="2">
                            <li>
                                <p className='h5 fw-bold'>COOKIES&nbsp;</p>
                            </li>
                        </ol>
                        
                        <ol>
                            <li>
                                <p>By default, your web browser will accept cookies, however, this can be altered by you. You have the option of enabling or disabling cookies in your web browser. If you do not want us to install cookies, you may change the settings on your web browser to disable cookies. However please note that if you disable cookies, you may not be able to use all of the features of the website.</p>
                            </li>
                        </ol>
                        
                        <ol start="2">
                            <li>
                                <p>The website and/or third parties may use &quot;cookies&quot;, and other similar tracking technologies (collectively, &quot;tracking technologies&quot;) to collect information automatically as you browse the website and the internet. &nbsp;</p>
                            </li>
                        </ol>
                        
                        <ol start="3">
                            <li>
                                <p>By visiting the website whether as a registered user or otherwise, you acknowledge, understand and hereby agree that you are giving us your consent to track your activities and your use of the website through these tracking technologies.</p>
                            </li>
                        </ol>
                        
                        <ol start="4">
                            <li>
                                <p>If you do not consent to any part of the privacy terms herein or wish to withdraw consent to process your information, then you may stop using the website and ask us to remove your information from our records by writing to us at&nbsp;<a href="mailto:contact@triptopper.com">contact@triptopper.com</a>&nbsp;</p>
                            </li>
                        </ol>
                        
                        <ol start="3">
                            <li>
                                <p className='h5 fw-bold'>DISPUTE RESOLUTION AND JURISDICTION</p>
                            </li>
                        </ol>
                        
                        <ol>
                            <li>
                                <p>In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration by a sole arbitrator to be appointed by the Company, and the award passed by such sole arbitrator will be valid and binding on both Parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. The arbitration shall be conducted in English, and the seat of Arbitration shall be in New Delhi, Delhi, India.</p>
                            </li>
                        </ol>
                        
                        <ol start="2">
                            <li>
                                <p>The Parties expressly agree that the Terms, Policy and any other agreements entered into between the Parties are governed by the laws, rules, and regulations of India, and that the Courts at New Delhi, Delhi, India, shall have exclusive jurisdiction over any disputes arising between the Parties.</p>
                            </li>
                        </ol>
                        <ol start="4">
                            <li>
                                <p className='h5 fw-bold'>DATA DELETION POLICY</p>
                            </li>
                        </ol>
                        
                        <ol>
                            <li>
                                <p>Users own all the data created by them including any data imported via any social login including facebook,google,twitter etc</p>
                                <p> Users can anytime login to their account at triptopper.com and request to delete the account including any data imported via social logins.</p>
                            </li>
                        </ol>
                        
                        
                        
                        <ol start="5">
                            <li>
                                <p className='h5 fw-bold'>GRIEVANCE OFFICER</p>
                            </li>
                        </ol>
                        
                        <ol>
                            <li>
                                <p>In accordance with Information Technology Act 2000 and rules made thereunder, the name and contact details of the Grievance Officer are provided below:&nbsp;</p>
                            </li>
                        </ol>
                        
                        <p>(E-mail:&nbsp;<a href="mailto:contact@triptopper.com">contact@triptopper.com</a> )</p>
                        
                        <p>Thank you for using our Services. We hope you find the Services helpful, informative and convenient to use. If you have any questions regarding these Terms and Conditions, please contact us by email at&nbsp;&nbsp;<a href="mailto:contact@triptopper.com">contact@triptopper.com</a> .</p>
                        
                        
                        
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Privacypolicy;