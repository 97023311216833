import '../../App.scss';
import {Link} from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Authservice from '.././Authservice';
import { deleteitin, getpendingitin } from '../../api/itin';

function Myitins() {
  var headertoken = Authservice.getheadertoken();
  axios.defaults.headers.common['Authorization'] =    
  'Bearer '+ headertoken;
  
  const [itins,SetItins] = useState(null);

  const handleDeleteConfirmTrue = (itinid) =>{
    const confirmBox = window.confirm("Do you really want to delete this itinerary?")
    if(confirmBox === true) {
      console.log(itinid);
      axios.post(deleteitin,{_id:itinid}).then(res=>{
        if (res.data.success) {
          //alert("Itin deleted")
          const newState = itins;
          const index = newState.findIndex(a => a._id === res.data._id);
          console.log(index);
          if (index > -1) {
            SetItins([...newState.slice(0,index),...newState.slice(index+1,newState.length)]);
          }
        }
        else{alert("Itin delete fail")}
      }).catch(err=>{
      alert("Error deleting itin.")
      console.log(err);
      })
    }
  }
  
  useEffect(()=>{
      axios.get(getpendingitin).then(res=>{
        if (res.data.success && res.data.itins) {
         SetItins(res.data.itins);
        }
        else{
          console.log("No pending itins found");
        }
    }).catch(err=>{
      console.log(err);
    })
  },[])
if(itins)
{
  return (
    <div className=''>
    <div className='container'>
      <div className='row'>
        <div className='col-sm-12 p-4'>
            <p className='h2 mb-2'> My Itineraries</p>
            <div className=''>
            {itins.map((itin,index)=>{
              return (
                  <div className='mt-4 p-4 border rounded-3' key={itin._id.toString()}>
                  <button className='float-end btn btn-default'  onClick={() => handleDeleteConfirmTrue(itin._id)}>Delete</button>
                  <Link className='h5 nodecor' to={'/app/myitin/'+itin._id}> {itin.title} </Link>
                  <p><span className='small space'> {itin.days.length} - day</span>
                     <a className="small nodecor ms-4" target="_blank" href={"https://www.google.com/maps/search/?api=1&query="+itin.location.fulladdress+"&query_place_id="+itin.location.gid}> {itin.location.fulladdress}</a></p>
                      </div>)
          })}
            </div>
          
        </div>
      
      </div>
 </div>
  </div>
     
  );
        }
        else
        {

  return ( 
   
  <div className='content-50'><br></br><center><p className='display-5 gray'>Loading....</p></center></div>

  )


        }
}

export default Myitins;
