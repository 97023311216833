import React, {useEffect, useState } from 'react';
import axios from "axios";
import { createorder, paymentsuccess, setplan } from "../api/itin";
import Authservice from "./Authservice";

const Plan = ({updateFunc})=>{

    const [currentPlan, setcurrentPlan] = useState();

    useEffect(() => {
      var currentUser = Authservice.getCurrentUser();
      if(currentUser.plan){
        setcurrentPlan(currentUser.plan);
      }else{
        setcurrentPlan('BSC');
      }
    },[]);

    const reloadPage = () => {
      window.location.reload(); 
    }

    const onClickUpgradePlan = (plan) => {
        if(plan === 'BSC'){
            updatePlan(plan);
        } else if(plan === 'ITR' || plan === 'ADV'){ 
            displayRazorpay(plan);
        } else{
            
        }
    }

    const updatePlan = (upgradedplan) => {
        var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
            'Bearer '+ headertoken;
        axios.post(setplan,{plan:upgradedplan})
            .then((response) => {
                const token = response.data.token;
                if(token){
                    setcurrentPlan(upgradedplan);
                    Authservice.setfacebooktoken(token);
                    if(updateFunc){
                      updateFunc(true);
                    }
                    //refresh the page
                    reloadPage();
                }
                }).catch(err => { console.log(err)
            });
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
            resolve(true);
            };
            script.onerror = () => {
            resolve(false);
            };
            document.body.appendChild(script);
        });
        }
    
    async function displayRazorpay(upgradedplan) {
      const res = await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js'
      );
  
        if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
        }
        var headertoken = Authservice.getheadertoken();
        axios.defaults.headers.common['Authorization'] =    
            'Bearer '+ headertoken;
        const result = await axios.post(createorder,{plan: upgradedplan});

        if (!result) {
        alert('Server error. Are you online?');
        return;
        }
  
        const { amount, id: order_id, currency } = result.data.order;
  
        const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, 
        amount: amount.toString(),
        currency: currency,
        name: 'Triptopper',
        description: 'Test Transaction',
        //   image: { logo },
        order_id: order_id,
        handler: async function (response) {
            const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            };

          var headertoken = Authservice.getheadertoken();
          axios.defaults.headers.common['Authorization'] =    
          'Bearer '+ headertoken;
          const result = await axios.post(paymentsuccess, data);
  
          if(result.data.success){
            updatePlan(upgradedplan);
          }else{
            alert('failed')
          }
        },
        prefill: {
          name: 'GAURAV SHARMA',
          email: 'example@example.com',
          contact: '8126670341',
        },
        notes: {
          address: 'Example Corporate Office',
        },
        theme: {
          color: '#61dafb',
        },
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }

      return(
        <div className="row">
        <div className="col d-flex justify-content-center">
              <div className="card m-3">
              <div className="card-body">
                <h4 className="card-title">Basic (0 INR)</h4>
                <hr></hr>
                <ul>
                  <p className='mb-1'>Benefits:</p>
                  <li className='mb-1'>Job and applicant insights</li>
                  <li className='mb-1'>This feature is included in this plan</li>
                  <li className='mb-1'>Top Applicant Job recommendations</li>
                </ul>
              </div>
                <div className="m-3">
                  <input type="radio" className="btn-check" name="options-outlined" id="button1" autoComplete="off"
                  value="BSC" 
                  onChange={(e) => onClickUpgradePlan(e.target.value)} checked={currentPlan === 'BSC'}/>
                  <label className="btn btn-outline-success w-100" htmlFor="button1">{currentPlan === 'BSC' ? 'Current Plan' : 'Upgrade'}</label>
                </div>
            </div>
        </div>
        <div className="col d-flex justify-content-center" >
              <div className="card m-3">
              <div className="card-body">
                <h4 className="card-title">Intermediate (499 INR)</h4>
                <hr></hr>
                <ul>
                  <p className='mb-1'>Benefits:</p>
                  <li className='mb-1'>Job and applicant insights</li>
                  <li className='mb-1'>This feature is included in this plan</li>
                  <li className='mb-1'>Top Applicant Job recommendations</li>
                  <li className='mb-1'>This feature is included in this plan</li>
                </ul>
              </div>
                <div className="m-3">
                  <input type="radio" className="btn-check" name="options-outlined" id="button2" autoComplete="off"
                  value="ITR" 
                  onChange={(e) => onClickUpgradePlan(e.target.value)} checked={currentPlan === 'ITR'}/>
                  <label className="btn btn-outline-success w-100" htmlFor="button2">{currentPlan === 'ITR' ? 'Current Plan' : 'Upgrade'}</label>
                </div>
            </div>
        </div>
        <div className="col d-flex justify-content-center">
              <div className="card m-3">
              <div className="card-body">
                <h4 className="card-title">Advance (999 INR)</h4>
                <hr></hr>
                <ul>
                  <p className='mb-1'>Benefits:</p>
                  <li className='mb-1'>Job and applicant insights</li>
                  <li className='mb-1'>This feature is included in this plan</li>
                  <li className='mb-1'>Top Applicant Job recommendations</li>
                  <li className='mb-1'>This feature is included in this plan</li>
                  <li className='mb-1'>Company insights</li>
                </ul>
                </div>
                <div className="m-3">
                  <input type="radio" className="btn-check" name="options-outlined" id="button3" autoComplete="off"
                  value="ADV" 
                  onChange={(e) => onClickUpgradePlan(e.target.value)} checked={currentPlan === 'ADV'}/>
                  <label className="btn btn-outline-success w-100" htmlFor="button3">{currentPlan === 'ADV' ? 'Current Plan' : 'Upgrade'}</label>
              </div>
            </div>
        </div>
      </div>            
      )
}

export default Plan;
