import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';
import ico_pin from '../../icons/pin.png';
import ico_hotel from '../../icons/hotel.png';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useEffect, useRef } from 'react';

function DayDragModal(props) {
  const dayRef = useRef([]);

  useEffect(() => {
    // var myref = dayRef.current[props.dayIndex];
    // if(myref){
    //     myref.scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'});
    //     myref.classList.add("flash");
    // }
    
  },[props]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        style={{
          // backgroundColor:  '#fff',
          // opacity: 0.6
      }}
      className='fade-scale'
      contentClassName='bg-transparent border-0'
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <center>Day reorder</center>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="">
                <Droppable droppableId="days" type='day'>
                  {(provided) => ( <div className='container p-4 mt-4' style={{minHeight: 400}} {...provided.droppableProps} ref={provided.innerRef}>

                              {
                                props.itin.days.map((item,index)=>{
                                  return(
                                    <Draggable key={item.day._id.toString()} draggableId={item.day._id.toString()} index={index}>
                                      {(provided) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div className='row day-drag-card p-3 shadow-lg' 
                                        ref={ref => {
                                            dayRef.current[index] = ref
                                          }}>
                                        <div className='col-sm-12'>
                                            <p className='hero3'>Day - {index+1} </p>
                                        </div>

                                        <div className='col-sm-12 col-md-6 mt-2 mb-2'>
                                            <p className='fw-bold nodecor text-primary' >
                                            <img className='i-sm' src={ico_pin}></img> {item.day.location && item.day.location.fulladdress?item.day.location.fulladdress:"No location"}</p>
                                        </div>
                                        
                                        <div className='col-sm-12 col-md-6 mt-2 mb-2'>
                                            <p className='nodecor float-md-end fw-bold text-primary'><img src={ico_hotel} className='i-sm me-2'></img>{item.day.wts && item.day.wts.name?item.day.wts.name:'Where to stay?'}</p>
                                        </div>
                                    </div>
                                        
                                      </div>)}
                                      </Draggable>
                                  )
                              })
                          }
                          {provided.placeholder}
                      </div>
                  )}
              </Droppable>
          
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }
  export default DayDragModal;
