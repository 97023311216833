import { useEffect, useState } from "react";
import GoogleSearchAutocomplete from "./GoogleSearchAutocomplete";
import Authservice from "./Authservice";
import axios from "axios";
import { addhotel, getmyhotels } from "../api/itin";
import HoteltypeSelector from "./HoteltypeSelector";
import icon_delete from '../icons/delete.png'


const Hotels = () => {
    const [ShowAddform, SetShowAddform] = useState(false);
    const [Myhotels, SetMyhotels] = useState();

    useEffect(() => {
        var headertoken = Authservice.getheadertoken();
            axios.defaults.headers.common['Authorization'] =    
                        'Bearer '+ headertoken;

            axios.get(getmyhotels)
            .then((response) => {
                if(response.data.success){
                    SetMyhotels(response.data.hotels);
                }
                })
            .catch(err => {
                    console.log(err)
            });

    },[]);

    const AddHotel = () => {
    const [locationname, SetLocationname] = useState();
    const [showlocationinput, SetShowlocationInput] = useState(true);

    const [selectedlocation, SetSelectedlocation] = useState();
    const [selectedtype, SetSelectedtype] = useState();
    const [description, SetDescription] = useState();

    const [showDescError, setShowDescError] = useState(false);

    const addHotelLocation = (item) => {
        SetShowlocationInput(false);
        SetLocationname(item.name);
        SetSelectedlocation(item);
    }

    const onDescriptionChange = (text) => {
        if(text.length > 150){
            SetDescription(text);
            setShowDescError(false);
        } else{
            SetDescription(text);
            setShowDescError(true);
        }
    }

    const deleteLocation = () => {
        SetShowlocationInput(true)
        SetLocationname();
        SetSelectedlocation();
    }
    
    const onClickSave = () => {
        if(locationname && selectedlocation && selectedtype && description){
            var headertoken = Authservice.getheadertoken();
            axios.defaults.headers.common['Authorization'] =    
                        'Bearer '+ headertoken;

            axios.post(addhotel,{name: locationname,location:selectedlocation, type:selectedtype, desc:description})
            .then((response) => {
                if(response.data.success){
                    console.log(response.data.hotel);
                    SetMyhotels([...Myhotels, response.data.hotel]);
                    SetShowAddform(false);
                }
                })
            .catch(err => {
                    console.log(err)
            });
        }
    };

    return(<>
        {/* Hotel name */}
        <div className='row p-3'>
        <div className='col-sm-3 '>
            <p className='float-md-end fs-5'>Hotel name</p>
        </div>
        
            {showlocationinput 
               ?
            <div className='col-sm-6 p-0'>
                <GoogleSearchAutocomplete 
                    gtype={['lodging']}
                    // gcountry={gcountry}
                    stopAutoFocus={true}
                    add={addHotelLocation} 
                    cname='form-control'>
                    </GoogleSearchAutocomplete>  
            </div>
                : 
            <>
            <div className='col-sm-6'>
                <div className="d-flex d-row">
                    <input class="form-control" value={locationname} readOnly />
                    <img className='i-sm m-3' src={icon_delete} onClick={deleteLocation}></img>
                </div>
                <small>{selectedlocation?.fulladdress}</small>
            </div>
            </>}
        </div>

        {/* Hotel type */}
        <div className='row p-3'>
            <div className='col-sm-3 '>
                <p className='float-md-end fs-5'>Hotel type</p>
            </div>
            <div className='col-sm-6'>
                <HoteltypeSelector selectedtype={selectedtype} SetSelectedtype={SetSelectedtype}/>
            </div>
        </div>

        {/** Description */}
        <div className='row p-3'>
                <div className='col-sm-3'>
                  <p className='float-md-end fs-5'>Description</p>
                </div>
                <div className='col-sm-6'>
                  <textarea className='form-control' rows={6} value={description} onChange={(e) => onDescriptionChange(e.target.value)}></textarea>
                  {showDescError && <small className="text-danger">Description should be minimium of 150 character in length</small >}
                </div>
              </div>
                
        <center>
            <button className='btn btn-light me-4'type="button" onClick={() => SetShowAddform(false)} >Cancel</button>

            <button className='btn btn-primary' type="button" onClick={onClickSave} 
            disabled={!(selectedlocation && selectedtype && description?.length > 150)}>Save</button>
        </center>
        </>)
    }

    const HotelsList = () => {

        if(Myhotels && Myhotels.length > 0){
            return(<>
                {Myhotels.map((item,index) => ( 
                <div className= 'list-group mt-3 p-2 border border-top-0 rounded' key={item._id.toString()}>
                <div className="d-flex justify-content-between">
                    <h5 className="m-1">{item.name}</h5>
                    <small className="m-1">{item.type}</small>
                    </div>
                    <p className="m-1">{item.location.fulladdress}</p>
                    <small className="m-1 text-wrap">{item.desc}</small>
                </div>))}
            </>)
        } else {
            return <div className=''><br></br><center><p className='display-5 gray'>No hotels added. Add now!</p></center></div>
        }
        
    }

    return (
        <div className=''>
          <div className='container'>
            <div className='row p-4'>
              <div className='col-sm-12'>
                <p className='hero2'> Hotels 
                <button className='btn btn-light float-end' onClick={() => SetShowAddform(true)}> Add+ </button></p>
                <hr></hr>
                {ShowAddform && <AddHotel/>}
                {Myhotels ?
                    <HotelsList />
                : <div className=''><br></br><center><p className='display-5 gray'>Loading....</p></center></div>}

              </div>
            </div>
          </div>
        </div>
           
    );
};

export default Hotels;