const UsertypeSelector = ({usertype, setUsertype}) => {

    return(<>
    <p className="text-muted m-1">Who are you?</p>
    <div className="ms-2 d-flex flex-wrap btn-group btn-group-toggle" data-toggle="buttons">
        <div className="row mt-2 me-4">
        <input type="radio" className="btn-check" name="options-outlined" id="button1" autoComplete="off"
        value="TVLR" 
        onChange={(e) => setUsertype(e.target.value)} checked={usertype === 'TVLR'}/>
        <label className="btn btn-outline-warning btn-outline-black shadow-none" htmlFor="button1">Traveller</label>
        </div>
        <div className="row mt-2 me-4">
        <input type="radio" className="btn-check" name="options-outlined" id="button2" autoComplete="off"
        value="AGNT" 
        onChange={(e) => setUsertype(e.target.value)} checked={usertype === 'AGNT'}/>
        <label className="btn btn-outline-warning btn-outline-black shadow-none" htmlFor="button2">Travel Agent</label>
        </div>
        <div className="row mt-2">
        <input type="radio" className="btn-check" name="options-outlined" id="button3" autoComplete="off"
        value="IFNR" 
        onChange={(e) => setUsertype(e.target.value)} checked={usertype === 'IFNR'}/>
        <label className="btn btn-outline-warning btn-outline-black shadow-none" htmlFor="button3">Travel Influencer</label>
        </div>
    </div>
    </>)
}

export default UsertypeSelector;