import Plan from "./Plan";

function Subscription(){

    return(<div className=''>
            <div className='container'>
                <div className='row p-4'>
                    <div className='col-sm-12'>
                    <p className='hero2'> Subscription</p>
                    <hr></hr>
                    <Plan />
                </div>
            </div>
        </div>
    </div>
    
    )
};

export default Subscription;