const HoteltypeSelector = ({selectedtype, SetSelectedtype}) => {

    return (
        <div className="d-flex flex-wrap btn-group btn-group-toggle" data-toggle="buttons">
                <div className="m-1">
                <input type="radio" className="btn-check" name="options-outlined" id="button1" autoComplete="off"
                value="Hostel" 
                onChange={(e) => SetSelectedtype(e.target.value)} checked={selectedtype === 'Hostel'}/>
                <label className="btn btn-outline-secondary" htmlFor="button1">Hostel</label>
                </div>
                <div className="m-1">
                <input type="radio" className="btn-check" name="options-outlined" id="button2" autoComplete="off"
                value="Villa" 
                onChange={(e) => SetSelectedtype(e.target.value)} checked={selectedtype === 'Villa'}/>
                <label className="btn btn-outline-secondary" htmlFor="button2">Villa</label>
                </div>
                <div className="m-1">
                <input type="radio" className="btn-check" name="options-outlined" id="button3" autoComplete="off"
                value="Two" 
                onChange={(e) => SetSelectedtype(e.target.value)} checked={selectedtype === 'Two'}/>
                <label className="btn btn-outline-secondary" htmlFor="button3">Two star</label>
                </div>
                <div className="m-1">
                <input type="radio" className="btn-check" name="options-outlined" id="button4" autoComplete="off"
                value="Three" 
                onChange={(e) => SetSelectedtype(e.target.value)} checked={selectedtype === 'Three'}/>
                <label className="btn btn-outline-secondary" htmlFor="button4">Three star</label>
                </div><div className="m-1">
                <input type="radio" className="btn-check" name="options-outlined" id="button5" autoComplete="off"
                value="Four" 
                onChange={(e) => SetSelectedtype(e.target.value)} checked={selectedtype === 'Four'}/>
                <label className="btn btn-outline-secondary" htmlFor="button5">Four star</label>
                </div><div className="m-1">
                <input type="radio" className="btn-check" name="options-outlined" id="button6" autoComplete="off"
                value="Five" 
                onChange={(e) => SetSelectedtype(e.target.value)} checked={selectedtype === 'Five'}/>
                <label className="btn btn-outline-secondary" htmlFor="button6">Five star</label>
                </div>
            </div>
    )
}

export default HoteltypeSelector;