import axios from "axios";
import jwtdecode from 'jwt-decode'
import { googleauthentication } from "../api/itin";

class AuthService {
  
  googlelogin(googletoken) {
    return axios.post(googleauthentication, googletoken).then(res=>{
      if (res.data.success && res.data.token) {
          //const data = jwtdecode(res.data.token);
           console.log(res.data)
          localStorage.setItem("triptopper", res.data.token);
          axios.defaults.headers.common['Authorization'] =    
          'Bearer '+res.data.token
          
          return res;
      } else { return false; }
    }).catch(err=>{ console.log(err); return false;})
  }
  setfacebooktoken(token)
  {
    localStorage.setItem("triptopper",token);

  }

  logout() {
    localStorage.removeItem("triptopper");
    delete axios.defaults.headers.common['Authorization'];
  }

  getheadertoken(){
    const headertoken = localStorage.getItem('triptopper')
    return headertoken;

  }
 
  getCurrentUser() {
    const localdata = localStorage.getItem('triptopper')

    try{
    
    if(localdata){  return jwtdecode(localdata)}
    
    }catch(err){
      return localdata;
    }
  }
}
export default new AuthService();