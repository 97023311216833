import '../../App.scss';
 
import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import SelectMobileNumber from '../SelectMobileNumber';
import ico_bookmark from '../../icons/bookmark.png'
import ico_bookmarked from '../../icons/bookmarked.png'


function AddtoBucketList(props) {
  const [added,Setadded] = useState(false);
  if(added){
    return (
      <>
        <button onClick={()=>Setadded(false)} className='btn btn-lg bg-yellow form-control  rounded-pill border mb-4 fw-bold'>
        <img src={ico_bookmarked} className='i-sm me-3'></img>
          In bucket list</button>
      </>
  );
  }
  else{
    return (
        <>
          <button onClick={()=>Setadded(true)} className='btn btn-lg form-control border-bold rounded-pill border mb-4 fw-bold'>
            <img src={ico_bookmark} className='i-sm me-3'></img>
            Add to bucket list</button>
        </>
    );
  }
}


export default AddtoBucketList;
