import '../../App.scss';
import axios from 'axios';
import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import SelectMobileNumber from '../SelectMobileNumber';
import GetFacebookLogin from '../GetFacebookLogin';
import Authservice from '../Authservice';
import { addQuery } from '../../api/itin';
function Ctr(props) {

const [itin,Setitin] = useState(props.item)
const [submitted,SetSubmitted] = useState(false);
const [name,Setname] = useState("");
const [email,Setemail] = useState("");
const [countrycd,SetCountrycd] = useState(null);
const [phonenu,Setphonenu] = useState("");

const [nameerror,Setnameerror] = useState("");
const [emailerror,Setemailerror] = useState("");
const [phoneerror,Setphoneerror] = useState("");
const [errorClass,SeterrorClass] = useState('');

const handlenameChange = (e)=>{
  if(e.target.value !== ''){
    Setnameerror('');
  }
  Setname(e.target.value);
}

const handleemailChange = (e)=>{
  if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value))
  {
    Setemailerror("");
  }

  Setemail(e.target.value);
}

const handlePhonenuChange = (phonenu) =>{
  if(/^[0-9\b]+$/.test(phonenu) && phonenu.length == 10)
  {
    Setphoneerror('')

  }
  Setphonenu(phonenu);
}

const handleSubmitClick = ()=>{
  var isValid = true;
  //check name
  if(name !== ''){Setnameerror('');}else{
    isValid = false;
    Setnameerror('What is your name?')
  }
  //check email
  const Emailregex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if(Emailregex.test(email)){
    Setemailerror('')
  }else{
    isValid= false;
    Setemailerror('Please enter a valid email')
  }

  //checkphonenu
  const Phoneregex = /^[0-9\b]+$/;
  if(Phoneregex.test(phonenu)){
    if(phonenu.length !== 10){
      isValid= false;
      Setphoneerror('Phone number should contain 10 digit')
    }else{
      Setphoneerror('')
    }
  }else{
    isValid= false;
    Setphoneerror('Please enter a valid phone number')
  }
  console.log(countrycd);
  if(countrycd === null){
    isValid= false;
    Setphoneerror("Please select country");
  }
  // Proceed with submit
  if(isValid){
      var headertoken = Authservice.getheadertoken();
      axios.defaults.headers.common['Authorization'] =    
            'Bearer '+ headertoken;
      axios.post(addQuery,{itin:itin._id,name:name
        ,email:email,phone:phonenu,cd:countrycd}).then(res=>{
        if (res.data.success) {
          SetSubmitted(true);
        }
        else{ }
      }).catch(err=>{
        console.log(err);
      })
  }
}


if(submitted){
    return(
      <>
        <h5 className='text-center mt-5'>Thank you! <br></br> Your requested is submitted .</h5>
      </>
    )
  }else{
    return(
      <div className='card p-4 bg-light shadow'>
      <center>
          <h4>Get info</h4>
          <input type='text'  className='form-control mt-4' onChange={handlenameChange} value={name}  placeholder='Name'></input>
          <p className='validation-error'>{nameerror}</p>
          <input type='email' className='form-control mt-4' onChange={handleemailChange} value={email} placeholder='Email'></input>
          <p className='validation-error'>{emailerror}</p>
          <SelectMobileNumber errorClass={errorClass} countrycd={countrycd} 
              SetCountrycd={SetCountrycd} value={phonenu} 
              set={handlePhonenuChange}></SelectMobileNumber>
          <p className='validation-error'>{phoneerror}</p>
          <button className='mt-4 btn btn-primary form-control' onClick={handleSubmitClick}>Submit</button>
      </center>  
      {/* <hr></hr> */}
      <center>
        {/* <br></br>
        <p>Submit via social login</p> 
        <GetFacebookLogin></GetFacebookLogin>   */}



      </center>
    </div>
    )}
  


}

export default Ctr;
